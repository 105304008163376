export const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="25" fill="none" viewBox="0 0 24 25" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.47 9.97a.75.75 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06 0l5-5a.75.75 0 1 0-1.06-1.06L12 14.44 7.53 9.97a.75.75 0 0 0-1.06 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 2.75a.75.75 0 0 0-.75.75v12a.75.75 0 0 0 1.5 0v-12a.75.75 0 0 0-.75-.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.75 19.5A1.25 1.25 0 0 0 5 20.75h14a1.25 1.25 0 0 0 1.25-1.25v-4a.75.75 0 0 1 1.5 0v4A2.75 2.75 0 0 1 19 22.25H5a2.75 2.75 0 0 1-2.75-2.75v-4a.75.75 0 0 1 1.5 0v4Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
