export const RevisePostIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.991 3.625a.717.717 0 0 0-.507.21l-7.787 7.787-.338 1.352 1.353-.338 7.786-7.787a.717.717 0 0 0-.507-1.224Zm-1.39-.674a1.967 1.967 0 1 1 2.781 2.782l-7.909 7.91a.624.624 0 0 1-.29.164l-2.531.633a.625.625 0 0 1-.758-.758l.633-2.531a.625.625 0 0 1 .164-.29l7.91-7.91Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.025 3.41-2.411-2.2a.625.625 0 1 0-.843.923l.914.834a8.543 8.543 0 1 0 9.853 8.444.625.625 0 1 0-1.25 0 7.293 7.293 0 1 1-8.73-7.153l-.657.72a.625.625 0 0 0 .923.843l2.2-2.41Z"
        fill="currentColor"
      />
    </svg>
  );
};
