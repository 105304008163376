export const ChevronFilledThickDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="13" fill="none" viewBox="0 0 12 13" {...props}>
      <path
        fill="currentColor"
        d="M3.084 5.442 5.8 7.924c.11.101.29.101.4 0l2.716-2.482c.179-.163.053-.442-.2-.442H3.284c-.253 0-.379.279-.2.442Z"
      />
    </svg>
  );
};
