const { colors } = require("./Colors");
const { spacing } = require("./Spacing");
const { fontSize } = require("./FontSize");
const { fontWeight } = require("./FontWeight");
const { screens } = require("./Screens");
const { zIndex } = require("./ZIndex");
const { borderRadius } = require("./BorderRadius");

module.exports = {
  colors,
  spacing,
  fontSize,
  fontWeight,
  screens,
  borderColor: colors,
  zIndex,
  borderRadius,
};
