export const LoadingSaveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#LoadingSave_svg__a)">
        <path d="M0 0h20v20H0z" />
        <path
          fill="currentColor"
          d="M10.002 4.38a.58.58 0 0 0 .581-.582v-1.55a.58.58 0 1 0-1.163 0v1.55c0 .322.26.582.582.582Zm0 13.955a.58.58 0 0 0 .581-.582v-1.55a.58.58 0 1 0-1.163 0v1.55c0 .322.26.582.582.582Zm2.432-.95a.583.583 0 0 0 .76.318.582.582 0 0 0 .314-.76l-.593-1.434a.582.582 0 0 0-1.074.446l.594 1.43Zm2.632-1.495a.58.58 0 0 0 .41.171.584.584 0 0 0 .411-.17.578.578 0 0 0 0-.822l-1.097-1.097a.581.581 0 0 0-.822.822l1.098 1.097ZM2.618 7.57l1.434.592a.583.583 0 0 0 .446-1.078l-1.434-.592a.583.583 0 0 0-.446 1.077Zm13.339 4.268a.582.582 0 0 0-.446 1.074l1.435.593a.583.583 0 0 0 .446-1.078l-1.435-.589ZM4.38 9.999a.58.58 0 0 0-.582-.582h-1.55a.58.58 0 1 0 0 1.163h1.55A.58.58 0 0 0 4.38 10Zm11.242 0c0 .321.26.581.581.581h1.55a.58.58 0 1 0 0-1.163h-1.55a.58.58 0 0 0-.581.582ZM3.064 13.505l1.434-.593a.582.582 0 0 0-.446-1.074l-1.434.593a.583.583 0 0 0 .225 1.12.665.665 0 0 0 .22-.046ZM15.198 7.85a.583.583 0 0 0 .76.318l1.434-.593a.582.582 0 0 0-.446-1.074l-1.434.593a.574.574 0 0 0-.314.756ZM4.107 15.892a.58.58 0 0 0 .41.17.585.585 0 0 0 .412-.17l1.097-1.096a.581.581 0 0 0-.822-.822L4.107 15.07a.578.578 0 0 0 0 .821Zm10.274-9.696a.585.585 0 0 0 .41-.17l1.098-1.097a.581.581 0 0 0-.822-.822L13.97 5.204a.578.578 0 0 0 0 .822c.116.112.264.17.411.17ZM6.804 17.698a.583.583 0 0 0 .76-.318l.593-1.434a.582.582 0 0 0-1.074-.446l-.593 1.434a.583.583 0 0 0 .314.764Zm5.352-12.892a.583.583 0 0 0 .76-.318l.593-1.434a.582.582 0 0 0-1.074-.446l-.593 1.434a.58.58 0 0 0 .314.764Z"
        />
      </g>
      <defs>
        <clipPath id="LoadingSave_svg__a">
          <path d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
