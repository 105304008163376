export const UnfoldMoreIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="6" height="13" fill="none" viewBox="0 0 6 13" {...props}>
      <path
        fill="currentColor"
        d="M2.888 12.394 0 9.506l.544-.543 2.343 2.343 2.344-2.343.544.543-2.888 2.888ZM.544 3.43 0 2.888 2.888 0l2.887 2.888-.544.543-2.343-2.344L.544 3.431Z"
      />
    </svg>
  );
};
