export const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="19" fill="none" viewBox="0 0 18 19" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.188 9.5c0-.31.251-.563.562-.563h10.5a.562.562 0 1 1 0 1.126H3.75a.563.563 0 0 1-.563-.563Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.602 3.852c.22-.22.576-.22.796 0l5.25 5.25c.22.22.22.576 0 .796l-5.25 5.25a.562.562 0 1 1-.796-.796L13.454 9.5 8.602 4.648a.563.563 0 0 1 0-.796Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
