export const ReadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 9v8a2 2 0 0 1-2 2H6.5l-3.693 2.872A.5.5 0 0 1 2 21.478V5a2 2 0 0 1 2-2h10.416a4.969 4.969 0 0 0-.391 1.5H4a.5.5 0 0 0-.5.5v14.433L5.985 17.5H20a.5.5 0 0 0 .5-.5V9.771a4.985 4.985 0 0 0 1.5-.77Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.148 1.114c-.649.22-.543.683-.762 1.034L19.78 7.91a.95.95 0 0 1-1.544.094l-2.07-2.552a.75.75 0 0 1 1.165-.945l1.585 1.954 3.197-5.11a.75.75 0 0 1 1.034-.238Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
