export const FocusPostIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.25 4.5c0-.69.56-1.25 1.25-1.25h4.122a.75.75 0 1 1 0 1.5H4.75v3.872a.75.75 0 1 1-1.5 0V4.5zM14.628 4a.75.75 0 0 1 .75-.75H19.5c.69 0 1.25.56 1.25 1.25v5.011a.75.75 0 0 1-1.5 0V4.75h-3.872a.75.75 0 0 1-.75-.75zM20 14.628a.75.75 0 0 1 .75.75V19.5c0 .69-.56 1.25-1.25 1.25h-4.122a.75.75 0 0 1 0-1.5h3.872v-3.872a.75.75 0 0 1 .75-.75zm-16 .8a.75.75 0 0 1 .75.75v3.072h3.872a.75.75 0 0 1 0 1.5H4.5c-.69 0-1.25-.56-1.25-1.25v-3.322a.75.75 0 0 1 .75-.75z"
      />
    </svg>
  );
};
