export const ChevronBigLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" fill="none" viewBox="0 0 28 28" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.119 6.381a.875.875 0 0 1 0 1.238L11.737 14l6.382 6.381a.875.875 0 1 1-1.238 1.238l-7-7a.875.875 0 0 1 0-1.238l7-7a.875.875 0 0 1 1.238 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
