import React, { useId } from "react";

export const RefineActiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();
  const id4 = useId();
  const id5 = useId();
  const id6 = useId();
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        stroke={`url(#${id1})`}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M11.71 6.945a.95.95 0 1 1 1.345 1.345l-4.54 4.539-1.849.504.505-1.849 4.539-4.539Z"
      />
      <path
        fill={`url(#${id2})`}
        d="M18.334 10a8.336 8.336 0 0 1-6.81 8.195c-.368.067-.69-.226-.69-.599 0-.32.24-.586.554-.648a7.086 7.086 0 0 0 0-13.895.674.674 0 0 1-.554-.649c0-.373.322-.666.69-.598A8.336 8.336 0 0 1 18.333 10Z"
      />
      <path
        fill={`url(#${id3})`}
        d="M2.404 9.167c.32 0 .586-.24.649-.554a7.044 7.044 0 0 1 1.054-2.544.673.673 0 0 0-.067-.85c-.263-.264-.7-.243-.91.064a8.288 8.288 0 0 0-1.324 3.195c-.068.366.225.689.598.689Z"
      />
      <path
        fill={`url(#${id4})`}
        d="M9.167 2.404c0 .32-.24.586-.554.649a7.043 7.043 0 0 0-2.544 1.054.673.673 0 0 1-.85-.067c-.264-.263-.243-.7.064-.91a8.288 8.288 0 0 1 3.195-1.324c.366-.068.689.225.689.598Z"
      />
      <path
        fill={`url(#${id5})`}
        d="M5.085 15.867a.673.673 0 0 1 .86-.058c.788.55 1.692.945 2.668 1.139a.673.673 0 0 1 .554.648c0 .373-.323.666-.69.599A8.292 8.292 0 0 1 5.14 16.77c-.299-.215-.315-.643-.055-.903Z"
      />
      <path
        fill={`url(#${id6})`}
        d="M3.95 14.645a.674.674 0 0 0 .075-.839 7.041 7.041 0 0 1-.972-2.418.673.673 0 0 0-.649-.554c-.373 0-.666.322-.598.689A8.284 8.284 0 0 0 3.03 14.57c.208.316.651.342.92.074Z"
      />
      <defs>
        <linearGradient id={`${id1}`} x1="6.802" x2="13.66" y1="10" y2="11.948" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={`${id2}`} x1="2.132" x2="19.124" y1="10" y2="14.865" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={`${id3}`} x1="2.132" x2="19.124" y1="10" y2="14.865" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={`${id4}`} x1="2.132" x2="19.124" y1="10" y2="14.865" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={`${id5}`} x1="2.132" x2="19.124" y1="10" y2="14.865" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={`${id6}`} x1="2.132" x2="19.124" y1="10" y2="14.865" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
