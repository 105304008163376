export const HourglassFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        d="M4.5 1.5V6h.008l-.008.008L7.5 9l-3 3 .008.008H4.5V16.5h9v-4.492h-.008L13.5 12l-3-3 3-2.992L13.492 6h.008V1.5h-9ZM12 12.375V15H6v-2.625l3-3 3 3Zm-3-3.75-3-3V3h6v2.625l-3 3Z"
      />
      <path fill="currentColor" d="M11.625 5.625 9 8.25 6.375 5.625h5.25ZM6 12.75h6V15H6v-2.25Z" />
    </svg>
  );
};
