export const UnmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        d="M1.275 2.109a.625.625 0 0 1 .884 0L16.041 15.99a.625.625 0 0 1-.884.884L1.275 2.993a.625.625 0 0 1 0-.884Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.283 3.333H2.5a.833.833 0 0 0-.833.834v11.666c0 .46.373.834.833.834h11.205a.833.833 0 0 0 .707-.392l.697-1.116-.907-.907-.728 1.165H2.917V4.583h1.616l-1.25-1.25Zm11.599 9.831L16.859 10l-3.385-5.417H6.3l-1.25-1.25h8.654c.287 0 .554.148.707.392l3.645 5.833a.833.833 0 0 1 0 .884l-2.268 3.63-.907-.908Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
