export const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.25 3a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 .75.75v1.752a7.743 7.743 0 0 1 2.15 1.244l1.52-.878a.75.75 0 0 1 1.024.275l2 3.464a.75.75 0 0 1-.275 1.025l-1.518.876a7.803 7.803 0 0 1 0 2.484l1.518.877a.75.75 0 0 1 .275 1.024l-2 3.464a.75.75 0 0 1-1.025.275l-1.52-.877a7.74 7.74 0 0 1-2.15 1.243V21a.75.75 0 0 1-.75.75h-4a.75.75 0 0 1-.75-.75v-1.752a7.742 7.742 0 0 1-2.15-1.243l-1.518.877a.75.75 0 0 1-1.025-.275l-2-3.464a.75.75 0 0 1 .275-1.025l1.518-.876a7.8 7.8 0 0 1 0-2.484l-1.518-.876a.75.75 0 0 1-.275-1.025l2-3.464a.75.75 0 0 1 1.025-.274l1.519.877a7.742 7.742 0 0 1 2.15-1.244V3Zm1.5.75v1.54a.75.75 0 0 1-.536.719 6.242 6.242 0 0 0-2.508 1.45.75.75 0 0 1-.89.105L5.48 6.793 4.23 8.958l1.334.77a.75.75 0 0 1 .355.822 6.27 6.27 0 0 0 0 2.9.75.75 0 0 1-.355.823l-1.334.77 1.25 2.165 1.336-.771a.75.75 0 0 1 .89.104 6.242 6.242 0 0 0 2.508 1.45.75.75 0 0 1 .536.72v1.539h2.5v-1.54a.75.75 0 0 1 .536-.719 6.242 6.242 0 0 0 2.508-1.45.75.75 0 0 1 .89-.104l1.336.77 1.25-2.164-1.334-.77a.75.75 0 0 1-.355-.823 6.268 6.268 0 0 0 0-2.9.75.75 0 0 1 .355-.822l1.334-.77-1.25-2.165-1.335.77a.75.75 0 0 1-.89-.104 6.243 6.243 0 0 0-2.509-1.45.75.75 0 0 1-.536-.72V3.75h-2.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 1.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
