export const SpaceProfileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <mask id="SpaceProfileIcon_svg__a" fill="#fff">
        <path d="M18.5 3.5a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2v-13a2 2 0 0 1 2-2h13Z" />
      </mask>
      <path
        fill="currentColor"
        d="M18.5.5A3.5 3.5 0 0 1 22 4v14.5a3.5 3.5 0 0 1-3.5 3.5v-3a.5.5 0 0 0 .5-.5v-12h-.5v-6ZM5.5 22A3.5 3.5 0 0 1 2 18.5V4A3.5 3.5 0 0 1 5.5.5v6H5v12a.5.5 0 0 0 .5.5v3ZM2 4A3.5 3.5 0 0 1 5.5.5h13A3.5 3.5 0 0 1 22 4l-3 2.5H5L2 4Zm20 14.5a3.5 3.5 0 0 1-3.5 3.5h-13A3.5 3.5 0 0 1 2 18.5h3a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5h3Z"
        mask="url(#SpaceProfileIcon_svg__a)"
      />
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" d="M7.75 11.25h8.5m-8.5 4h5.5" />
    </svg>
  );
};
