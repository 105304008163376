export const NotificationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.75 18a2.25 2.25 0 0 0 4.5 0h1.5a3.75 3.75 0 1 1-7.5 0h1.5Zm8.05-3.1a1.5 1.5 0 0 1-.3-.9V9a5.5 5.5 0 1 0-11 0v5a1.5 1.5 0 0 1-.3.9L5 16.5h14l-1.2-1.6ZM19 9v5l1.8 2.4A1 1 0 0 1 20 18H4a1 1 0 0 1-.8-1.6L5 14V9a7 7 0 0 1 14 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
