export const MentionUserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.546 18.5H20a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5H4a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h5.454L12 20.197l2.546-1.697ZM15 20h5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h5l2.723 1.815a.5.5 0 0 0 .554 0L15 20Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.5 13.75c-.512 0-.978.164-1.3.421-.318.255-.45.56-.45.829v.25h8.5V15c0-.27-.132-.574-.45-.829l.936-1.17c.62.495 1.014 1.209 1.014 1.999v1a.75.75 0 0 1-.75.75H7a.75.75 0 0 1-.75-.75v-1c0-.79.394-1.504 1.014-2 .616-.492 1.422-.75 2.236-.75h5c.815 0 1.62.258 2.236.75l-.937 1.171c-.321-.257-.787-.421-1.299-.421h-5ZM12 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM15 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
