export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.847 3.07a.25.25 0 0 1 .306 0l8 6.222a.25.25 0 0 1 .097.197V20c0 .69-.56 1.25-1.25 1.25h-3.25V12a.75.75 0 0 0-.75-.75H9a.75.75 0 0 0-.75.75v9.25H5c-.69 0-1.25-.56-1.25-1.25V9.49a.25.25 0 0 1 .097-.198l8-6.222ZM8.995 22.75H19A2.75 2.75 0 0 0 21.75 20V9.49c0-.541-.25-1.05-.676-1.382l-8-6.223a1.75 1.75 0 0 0-2.148 0l-8 6.223a1.75 1.75 0 0 0-.676 1.381V20A2.75 2.75 0 0 0 5 22.75h3.995Zm5.255-1.5h-4.5v-8.5h4.5v8.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
