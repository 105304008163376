import React from "react";

import { ToastContainer } from "react-toastify";

export const SEMBLY_TOAST_CLASS = "sembly_toast";

const SemblyToastContainer = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      className={SEMBLY_TOAST_CLASS}
      bodyClassName={SEMBLY_TOAST_CLASS}
      toastClassName={SEMBLY_TOAST_CLASS}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      limit={2}
    />
  );
};

export default SemblyToastContainer;
