export const BadgeAmbassadorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#BadgeAmbassador_svg__a)">
        <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
        <path
          fill="#fff"
          d="m16.707 9.143-.008.253-.04 1.441-1.101 3.505-.595 2.67-.315 1.408-.461 5.376A11.989 11.989 0 0 1 11.98 24a11.942 11.942 0 0 1-4.831-1.014c.48-2.183.953-4.674.989-6.152.01-.43-.07-.853-.212-1.261-.261-.747-.843-2.886-.74-5.154.013-.255.032-.512.064-.768a8.69 8.69 0 0 1 .214-1.163l1.143.122.407.043.41.044.251.027.388.04.387.042.952.101.09.004.562.024.38.018h.007l.369.017.57.025.747.033h.06l.066.005.34.015.089.004.307.014 1.137.051.581.025Z"
        />
        <path
          fill="#ADABFF"
          d="M14.952 9.247a2.322 2.322 0 0 1-.27-.195l-.066-.058-.022.054-.341-.015a2.272 2.272 0 0 0-.057-.023l-.009.02h-.06l-.747-.034a3.986 3.986 0 0 1-.5-.4h-.06v.04c0 .113-.004.224-.011.334l-.37-.017-.007-.009v.01l-.38-.019A3.186 3.186 0 0 1 12 9.31l.261.012.123.004.378.017 1.143.051.257.012.226.009.153.007.765.033.032-.036.097-.105s-.19-.004-.485-.066h.002Zm2.079-.295a.38.38 0 0 0-.307-.186h-.018l-.433-.02-1.171-.052-.271-.012-.111-.005-.385-.016h-.03l-.48-.023-.316-.013-.629-.029h-.06l-.375-.019-.28-.012-.097-.005-.627-.027-.157-.016-.909-.098-.797-.084-.276-.03-.791-.083-1.006-.107a.43.43 0 0 0-.16.021.374.374 0 0 0-.244.258c-.03.115-.057.231-.082.346a9.884 9.884 0 0 0-.152.956c-.328 2.984.657 5.862.705 6 .135.391.2.771.19 1.13-.034 1.427-.491 3.851-.962 6 .115.056.23.109.349.16.117.052.234.102.354.15.486-2.214.972-4.764 1.01-6.293a3.939 3.939 0 0 0-.233-1.393 15.04 15.04 0 0 1-.732-4.491c0-.208.006-.42.018-.633a9.363 9.363 0 0 1 .188-1.43l.98.104.857.091.144.015.759.081.891.095.366.016.25.011.261.012.123.004.379.017.099.004 1.043.047.257.012.226.009.154.007.28.012.484.021.49.023.526.022-.006.18-.032 1.097-1.093 3.485-.91 4.076a.305.305 0 0 0-.008.05l-.47 5.475c.13-.02.257-.04.383-.066a6.83 6.83 0 0 0 .383-.078l.45-5.241.766-3.435.13-.588 1.101-3.504a.392.392 0 0 0 .017-.102l.048-1.695a.379.379 0 0 0-.051-.201Z"
        />
        <path
          fill="#fff"
          d="M9.182 11.372c.8-.197 1.165-1.502.817-2.914-.348-1.413-1.278-2.398-2.078-2.2-.8.196-1.165 1.501-.817 2.914.348 1.412 1.279 2.397 2.078 2.2Z"
        />
        <path
          fill="#ADABFF"
          d="M10.494 9.187a5.32 5.32 0 0 0-.118-.768l-.013-.05c-.182-.746-.524-1.41-.961-1.872a2.26 2.26 0 0 0-.465-.389 1.658 1.658 0 0 0-.34-.167 1.313 1.313 0 0 0-.765-.047c-1.016.25-1.496 1.73-1.092 3.367a4.57 4.57 0 0 0 .447 1.158c.109.197.23.378.361.54.05.062.1.12.153.175.396.419.837.64 1.269.64.1 0 .203-.012.302-.036.4-.1.717-.389.928-.804.057-.108.105-.227.146-.351a2.94 2.94 0 0 0 .098-.389c.041-.216.062-.447.062-.69 0-.104-.005-.209-.012-.317Zm-.775.822a2.029 2.029 0 0 1-.119.437c-.118.309-.3.511-.508.563-.245.066-.554-.082-.846-.39a3.15 3.15 0 0 1-.612-1.015 3.919 3.919 0 0 1-.165-.521 4.664 4.664 0 0 1-.068-.326 3.83 3.83 0 0 1-.055-.621c-.003-.826.286-1.42.666-1.513a.427.427 0 0 1 .115-.014c.096 0 .198.027.303.077a1.63 1.63 0 0 1 .428.325c.108.115.21.25.304.399a3.865 3.865 0 0 1 .474 1.139 4.029 4.029 0 0 1 .12.849 3.29 3.29 0 0 1-.037.611Z"
        />
        <path
          fill="#fff"
          d="M11.32 10.778c.933-.23 1.373-1.702.983-3.286-.39-1.585-1.465-2.683-2.398-2.453-.934.23-1.375 1.702-.984 3.286.39 1.585 1.464 2.683 2.398 2.453Z"
        />
        <path
          fill="#ADABFF"
          d="M12.82 8.595v-.057a5.296 5.296 0 0 0-.484-2.082 4.042 4.042 0 0 0-.674-1.036 2.89 2.89 0 0 0-.616-.518c-.397-.237-.822-.327-1.231-.226-.567.139-.989.609-1.218 1.265a3.39 3.39 0 0 0-.166.744 4.833 4.833 0 0 0 .125 1.731c.017.066.033.131.051.194.039.135.081.264.128.39.214.58.516 1.075.865 1.446a2.757 2.757 0 0 0 .6.488c.279.162.573.25.866.25.115 0 .229-.013.343-.04.518-.128.913-.528 1.153-1.095a3.25 3.25 0 0 0 .212-.768 5 5 0 0 0 .036-.311c.008-.11.012-.22.012-.335v-.04h-.002Zm-.778.465a3.081 3.081 0 0 1-.096.474c-.138.468-.387.8-.717.88-.249.059-.522-.025-.784-.22a2.034 2.034 0 0 1-.334-.308 3.48 3.48 0 0 1-.52-.795 4.264 4.264 0 0 1-.29-.786l-.017-.069a4.249 4.249 0 0 1-.107-1.43c.017-.16.044-.311.081-.452.132-.504.389-.864.735-.95a.635.635 0 0 1 .16-.019c.215 0 .452.096.683.277a2.142 2.142 0 0 1 .339.332c.133.158.26.344.373.555.16.295.294.642.39 1.033.068.27.107.542.123.803.004.059.005.118.007.175a4.384 4.384 0 0 1-.026.5Z"
        />
        <path
          fill="#fff"
          d="M14.43 8.194c-.02.164-.051.32-.095.467l-.001.005a2.05 2.05 0 0 1-.138.345l-.009.019-.022.04c-.051.092-.113.18-.186.257a.986.986 0 0 1-.072.066.703.703 0 0 1-.291.151c-.24.057-.502-.019-.753-.198a2.348 2.348 0 0 1-.421-.393l-.008-.008a3.399 3.399 0 0 1-.372-.56 4.401 4.401 0 0 1-.513-1.836 3.952 3.952 0 0 1 .019-.659 3.05 3.05 0 0 1 .095-.47c.136-.47.385-.804.716-.887a.736.736 0 0 1 .162-.02c.195 0 .401.082.602.224.108.077.213.171.315.28a3.251 3.251 0 0 1 .306.393c.124.188.237.398.333.625a4.444 4.444 0 0 1 .226.678l.01.04c.12.501.152.997.097 1.441Z"
        />
        <path
          fill="#ADABFF"
          d="M15.063 6.576c-.003-.015-.008-.028-.01-.043a5.567 5.567 0 0 0-.214-.674 4.235 4.235 0 0 0-.803-1.33 3.014 3.014 0 0 0-.275-.264 2.559 2.559 0 0 0-.304-.221c-.402-.247-.836-.34-1.258-.24-.517.128-.913.53-1.153 1.098a3.293 3.293 0 0 0-.21.76 4.83 4.83 0 0 0 .105 1.883 5.189 5.189 0 0 0 .55 1.366 3.863 3.863 0 0 0 .455.623c.098.106.198.204.302.29.102.085.207.162.315.225.286.172.586.265.887.265a1.421 1.421 0 0 0 .774-.226c.201-.127.376-.304.521-.526a2.323 2.323 0 0 0 .174-.322l.005-.009a1.8 1.8 0 0 0 .066-.165 2.68 2.68 0 0 0 .06-.179l.002-.007c0-.003 0-.008.004-.01.016-.057.033-.116.046-.176.152-.633.138-1.377-.039-2.118Zm-.447 2.418-.022.054c-.194.448-.498.764-.89.86-.934.23-2.009-.867-2.399-2.453-.39-1.584.05-3.056.985-3.285.934-.231 2.008.867 2.398 2.451.22.895.177 1.753-.072 2.373Z"
        />
        <path
          fill="#ADABFF"
          d="M14.688 6.621c-.39-1.584-1.464-2.682-2.399-2.451-.934.23-1.373 1.701-.983 3.285.39 1.585 1.464 2.684 2.398 2.453.391-.096.696-.412.89-.86l.022-.054c.249-.62.293-1.478.072-2.373Zm-.501 2.409-.022.04c-.051.092-.113.18-.186.257a.986.986 0 0 1-.072.066.703.703 0 0 1-.291.151c-.24.057-.502-.019-.753-.198a2.348 2.348 0 0 1-.421-.393l-.008-.008a3.399 3.399 0 0 1-.372-.56 4.401 4.401 0 0 1-.513-1.836 3.952 3.952 0 0 1 .019-.659 3.05 3.05 0 0 1 .095-.47c.136-.47.385-.804.716-.887a.736.736 0 0 1 .162-.02c.195 0 .401.082.602.224.108.077.213.171.315.28a3.251 3.251 0 0 1 .306.393c.124.188.237.398.333.625a4.444 4.444 0 0 1 .226.678l.01.04c.12.501.152.997.097 1.441-.02.164-.051.32-.095.467l-.001.005a2.05 2.05 0 0 1-.138.345l-.009.019Z"
        />
        <path
          fill="#fff"
          d="M15.91 9.434c.934-.23 1.374-1.701.984-3.286-.391-1.585-1.465-2.682-2.399-2.452-.933.23-1.374 1.701-.983 3.286.39 1.584 1.464 2.682 2.398 2.452Z"
        />
        <path
          fill="#ADABFF"
          d="M17.259 6.058a4.84 4.84 0 0 0-.157-.518 4.074 4.074 0 0 0-.142-.355 4.09 4.09 0 0 0-.793-1.205c-.55-.563-1.176-.791-1.761-.648-.39.096-.713.349-.95.712a2.388 2.388 0 0 0-.18.33 2.88 2.88 0 0 0-.133.363 4.109 4.109 0 0 0-.134 1.491c.01.121.024.246.044.372a4.826 4.826 0 0 0 .095.472c.098.404.241.785.418 1.128a4.318 4.318 0 0 0 .486.743c.024.028.048.057.075.085a.36.36 0 0 0 .037.042l.075.081a2.591 2.591 0 0 0 .303.27 2.006 2.006 0 0 0 .52.302c.135.054.273.09.409.108h.002A1.5 1.5 0 0 0 16 9.8a1.499 1.499 0 0 0 .7-.404 1.97 1.97 0 0 0 .331-.444c.248-.443.383-1.019.383-1.662 0-.392-.05-.807-.155-1.232Zm-.986 2.688a.999.999 0 0 1-.215.213.712.712 0 0 1-.238.111c-.264.065-.52-.037-.726-.174l-.038-.027a2.156 2.156 0 0 1-.225-.187c-.02-.02-.04-.038-.056-.056l-.034-.036a2.97 2.97 0 0 1-.311-.396 4.198 4.198 0 0 1-.555-1.302c-.003-.015-.008-.03-.01-.045a5.011 5.011 0 0 1-.074-.369 4.824 4.824 0 0 1-.042-.373 3.77 3.77 0 0 1 .015-.695c.023-.18.059-.346.105-.496.045-.144.102-.273.167-.385.146-.252.337-.416.55-.468a.7.7 0 0 1 .165-.021c.366 0 .7.28.88.465a3.21 3.21 0 0 1 .619.925c.052.111.099.226.14.346.044.117.081.237.113.36.01.033.018.068.027.102.271 1.104.105 2.033-.257 2.508Z"
        />
        <path
          fill="#fff"
          d="m15.056 16.65 3.274-4.401c.309-.415.482-.917.492-1.434.047-2.452-.192-3.808-.474-4.56a1.135 1.135 0 0 0-1.456-.659c-1.793.677-3.236.933-4.043 1.03a.534.534 0 0 0-.408.781c.996 1.857 2.995 1.906 2.995 1.906l-1.258 1.373s-2.886 2.15-1.502 5.331l2.383.633h-.003Z"
        />
        <path
          fill="#ADABFF"
          d="M15.056 17.025a.374.374 0 0 1-.3-.598l3.274-4.402a2.1 2.1 0 0 0 .418-1.218c.049-2.533-.218-3.802-.45-4.42a.747.747 0 0 0-.396-.418.743.743 0 0 0-.577-.022c-1.783.674-3.231.944-4.131 1.052a.156.156 0 0 0-.123.086.155.155 0 0 0 0 .146c.876 1.634 2.602 1.707 2.676 1.709l.818.027-1.811 1.974c-.159.127-2.68 2.064-1.434 4.927a.376.376 0 0 1-.688.299c-1.487-3.414 1.59-5.759 1.621-5.781l.74-.811c-.745-.19-1.881-.684-2.582-1.99a.908.908 0 0 1 .693-1.332c.86-.103 2.242-.361 3.955-1.008a1.498 1.498 0 0 1 1.152.038 1.5 1.5 0 0 1 .79.838c.37.989.538 2.569.497 4.698a2.83 2.83 0 0 1-.567 1.652l-3.274 4.401a.374.374 0 0 1-.301.151v.002Z"
        />
        <path
          fill="#C1B1FF"
          d="m4.264 4.719.66 1.207 1.21.662-1.21.661-.66 1.208-.661-1.208-1.209-.661 1.209-.662.661-1.207Zm14.417 11.144.662 1.209 1.207.66-1.207.661-.662 1.209-.662-1.209-1.207-.662 1.207-.66.662-1.209Z"
        />
      </g>
      <defs>
        <clipPath id="BadgeAmbassador_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
