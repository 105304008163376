export const FindGroupIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="currentColor" viewBox="0 0 18 18" {...props}>
      <path
        fillRule="evenodd"
        d="M9.75 6.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm1.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm3 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-7.899.528c-.441.177-.881.413-1.222.727-.275.255-.512.587-.596.995-.021.102-.033.21-.033.321V13.5h-.643v-1.414c0-.666.305-1.194.677-1.575a5.967 5.967 0 0 0-2.778.504A1.707 1.707 0 0 0 .75 12.58v.92h16.607v-.92a1.71 1.71 0 0 0-1.006-1.566 5.967 5.967 0 0 0-2.777-.503c.371.382.676.909.676 1.575V13.5h-.75v-1.18a1.59 1.59 0 0 0-.032-.321c-.084-.408-.32-.74-.596-.995-.34-.314-.781-.55-1.222-.727-.881-.352-1.927-.528-2.65-.528-.722 0-1.768.176-2.649.528ZM11.721 12a3.01 3.01 0 0 0-.628-.33c-.7-.28-1.556-.42-2.093-.42-.536 0-1.392.14-2.092.42a3.008 3.008 0 0 0-.628.33h5.441ZM2.357 8.25a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
