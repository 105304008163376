export const CarouselLeftArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="6" height="8" fill="none" viewBox="0 0 6 8" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.979.26a.864.864 0 0 1 0 1.257L2.39 4l2.588 2.483a.864.864 0 0 1 0 1.257.955.955 0 0 1-1.31 0L.588 4.786C-.196 4-.197 4 .59 3.214L3.67.26a.955.955 0 0 1 1.31 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
