export const RecommendedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m11.333 22 2.925-6.44 6.409-2.893-6.41-2.894-2.924-6.44-2.862 6.44L2 12.667l6.471 2.893L11.333 22Zm7.334-13.333L19.334 6l2.667-.667-2.667-.666L18.667 2l-.666 2.667-2.667.666L18.001 6l.666 2.667Z"
      />
    </svg>
  );
};
