export const BadgeGamerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#C1B1FF"
        d="M19.188 19.598a1.56 1.56 0 0 1-.714-.325 278.347 278.347 0 0 0-3.39-2.642 3.519 3.519 0 0 0-2.137-.719h-1.892c-.775 0-1.528.252-2.136.719-.756.577-1.927 1.483-3.391 2.642a1.56 1.56 0 0 1-.714.325c-.567.09-1.373-.12-1.277-2.1 0 0 .119-6.233 2.396-8.902 0 0 1.227-1.817 3.477-.24a2.64 2.64 0 0 0 1.52.49h2.146c.547 0 1.076-.178 1.519-.49 2.248-1.576 3.477.24 3.477.24 2.277 2.67 2.396 8.901 2.396 8.901.097 1.98-.712 2.19-1.277 2.1h-.003Z"
      />
      <path
        fill="#fff"
        d="M19.188 19.185a1.531 1.531 0 0 1-.714-.334 271.218 271.218 0 0 0-3.39-2.721 3.446 3.446 0 0 0-2.137-.74h-1.892c-.775 0-1.528.26-2.136.74a270.45 270.45 0 0 0-3.391 2.72 1.546 1.546 0 0 1-.714.335c-.567.093-1.373-.124-1.277-2.163 0 0 .119-6.417 2.396-9.167 0 0 1.227-1.872 3.477-.247a2.59 2.59 0 0 0 1.52.504h2.146c.547 0 1.076-.182 1.519-.504 2.248-1.625 3.477.247 3.477.247 2.277 2.75 2.396 9.167 2.396 9.167.097 2.039-.712 2.256-1.277 2.163h-.003Z"
      />
      <path
        fill="#2A0FCE"
        d="M7.965 13.373a1.523 1.523 0 1 0 0-3.046 1.523 1.523 0 0 0 0 3.046Zm2.814-1.184a.339.339 0 1 0 0-.678.339.339 0 0 0 0 .678Zm2.517 0a.339.339 0 1 0 0-.678.339.339 0 0 0 0 .678Zm-1.259-.798a.462.462 0 0 1 0 .921.462.462 0 0 1 0-.921Zm0-.25a.71.71 0 1 0 .001 1.42.71.71 0 0 0 0-1.42Z"
      />
      <path
        fill="#ADABFF"
        d="M16.272 11.187a.663.663 0 1 0 0-1.326.663.663 0 0 0 0 1.326Zm0 2.652a.663.663 0 1 0 0-1.326.663.663 0 0 0 0 1.326Zm-1.328-1.326a.663.663 0 1 0 0-1.326.663.663 0 0 0 0 1.326Zm2.654 0a.663.663 0 1 0 0-1.326.663.663 0 0 0 0 1.326Z"
      />
      <path
        fill="#fff"
        d="m12.037 1.386.815 1.49 1.49.814-1.49.816-.815 1.49-.814-1.49-1.49-.816 1.49-.815.814-1.489Z"
      />
    </svg>
  );
};
