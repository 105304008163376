export const CountdownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="m10.067 12.913.666 1.16a6.55 6.55 0 0 1-2.066.56v-1.346c.493-.06.96-.187 1.4-.374ZM2.713 8.667H1.367c.073.733.266 1.426.56 2.066l1.16-.666a5.165 5.165 0 0 1-.374-1.4Zm7.354-5.58.666-1.16a6.499 6.499 0 0 0-2.066-.56v1.346c.493.06.96.187 1.4.374Zm3.22 4.246h1.346a6.501 6.501 0 0 0-.56-2.066l-1.16.666c.187.44.314.907.374 1.4Zm-7.354 5.58-.666 1.16a6.55 6.55 0 0 0 2.066.56v-1.346a5.168 5.168 0 0 1-1.4-.374Zm1.4-10.2V1.367a6.499 6.499 0 0 0-2.066.56l.666 1.16c.44-.187.907-.314 1.4-.374ZM12.24 4.78l1.16-.673a6.79 6.79 0 0 0-1.513-1.514l-.674 1.16c.394.3.734.64 1.027 1.027ZM3.087 5.933l-1.16-.666a6.499 6.499 0 0 0-.56 2.066h1.346c.06-.493.187-.96.374-1.4Zm10.2 2.734c-.06.493-.187.96-.374 1.4l1.16.666a6.55 6.55 0 0 0 .56-2.066h-1.346ZM11.22 12.24l.673 1.16a6.79 6.79 0 0 0 1.514-1.513l-1.16-.674c-.3.394-.64.734-1.027 1.027ZM4.78 3.76l-.667-1.167A6.682 6.682 0 0 0 2.6 4.113l1.16.674A5.134 5.134 0 0 1 4.78 3.76Zm-1.02 7.46-1.16.667A6.79 6.79 0 0 0 4.113 13.4l.674-1.16a5.132 5.132 0 0 1-1.027-1.02Zm4.907-6.553H7.333v3.606l2.86 2.86.94-.94-2.466-2.466v-3.06Z"
      />
    </svg>
  );
};
