export const PioneerRocketIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="240" height="240" fill="none" viewBox="0 0 240 240" {...props}>
      <g clipPath="url(#PioneerRocketIcon_svg__a)">
        <path fill="#fff" d="M120 240c66.274 0 120-53.726 120-120S186.274 0 120 0 0 53.726 0 120s53.726 120 120 120Z" />
        <path
          fill="#DBDBDC"
          d="M242.259 140.46c-3.87 22.515-14.01 42.885-28.575 59.25a118.315 118.315 0 0 1-8.715 8.85c-6.69-4.14-11.565-10.935-13.17-18.915a13.328 13.328 0 0 1-17.445-8.745 12.88 12.88 0 0 1-.54-3.75c0-7.35 5.97-13.32 13.335-13.32 3.57 0 6.81 1.41 9.195 3.69 4.785-6.945 12.54-11.7 21.435-12.435 1.875-8.79 9.69-15.39 19.05-15.39 1.89 0 3.705.27 5.43.765Zm-94.939 76.9c7.356 0 13.32-5.963 13.32-13.32 0-7.356-5.964-13.32-13.32-13.32S134 196.684 134 204.04c0 7.357 5.964 13.32 13.32 13.32Z"
        />
        <path
          fill="#DBDBDC"
          d="M126.32 229.36c7.356 0 13.32-5.964 13.32-13.32s-5.964-13.32-13.32-13.32S113 208.684 113 216.04s5.964 13.32 13.32 13.32Zm-38.625-13.945c0 7.38-2.76 14.1-7.29 19.215a120.054 120.054 0 0 1-47.49-28.035c-17.295-16.62-29.64-38.37-34.545-62.805a19.385 19.385 0 0 1 11.94-4.095c9.975 0 18.18 7.485 19.32 17.145.9-.18 1.83-.27 2.79-.27 6.855 0 12.6 4.755 14.115 11.16a19.088 19.088 0 0 1 5.355-.75c10.755 0 19.47 8.715 19.47 19.47 0 .945-.06 1.875-.195 2.79a28.869 28.869 0 0 1 7.425 5.085 28.876 28.876 0 0 1 9.105 21.09Z"
        />
        <path
          fill="#DBDBDC"
          d="M-15.185 192.46c3.87 22.515 14.01 42.885 28.575 59.25 2.745 3.09 5.655 6.06 8.715 8.85 6.69-4.14 11.565-10.935 13.17-18.915A13.328 13.328 0 0 0 52.72 232.9c.36-1.185.54-2.445.54-3.75 0-7.35-5.97-13.32-13.335-13.32-3.57 0-6.81 1.41-9.195 3.69-4.785-6.945-12.54-11.7-21.435-12.435-1.875-8.79-9.69-15.39-19.05-15.39-1.89 0-3.705.27-5.43.765ZM186 214.539a33.842 33.842 0 0 0 8.488 22.467c20.974-6.542 39.836-17.889 55.292-32.779 20.136-19.433 34.509-44.863 40.22-73.433a22.51 22.51 0 0 0-13.902-4.788c-11.613 0-21.166 8.751-22.494 20.046a16.481 16.481 0 0 0-3.248-.316c-7.981 0-14.67 5.56-16.434 13.049a22.125 22.125 0 0 0-6.235-.877c-12.522 0-22.668 10.19-22.668 22.765 0 1.105.07 2.192.227 3.262a33.622 33.622 0 0 0-8.645 5.945A33.83 33.83 0 0 0 186 214.539Z"
        />
        <path
          fill="#DBDBDC"
          d="M77.695 225.414c0 7.38-2.76 14.1-7.29 19.215a120.054 120.054 0 0 1-47.49-28.035c-17.295-16.62-29.64-38.37-34.545-62.805a19.386 19.386 0 0 1 11.94-4.095c9.975 0 18.18 7.485 19.32 17.145.9-.18 1.83-.27 2.79-.27 6.855 0 12.6 4.755 14.115 11.16a19.088 19.088 0 0 1 5.355-.75c10.755 0 19.47 8.715 19.47 19.47 0 .945-.06 1.875-.195 2.79a28.869 28.869 0 0 1 7.425 5.085 28.876 28.876 0 0 1 9.105 21.09Z"
        />
        <path
          fill="#F3F3F4"
          d="M-3.766 182.608c-6.392 3.69-10.831 9.44-12.996 15.921a120.054 120.054 0 0 0 48.024 27.11c23.04 6.668 48.05 6.484 71.663-1.486.572-4.14-.173-8.49-2.423-12.387-4.988-8.639-15.572-12.002-24.508-8.16a14.231 14.231 0 0 0-1.162-2.551c-3.427-5.936-10.417-8.534-16.722-6.644a19.092 19.092 0 0 0-2.028-5.012c-5.377-9.314-17.282-12.504-26.596-7.127a19.25 19.25 0 0 0-2.32 1.564 28.858 28.858 0 0 0-8.115-3.888 28.878 28.878 0 0 0-22.817 2.66Z"
        />
        <path
          fill="#F3F3F4"
          d="M-3.766 212.608c-6.392 3.69-10.831 9.44-12.996 15.921a120.054 120.054 0 0 0 48.024 27.11c23.04 6.668 48.05 6.484 71.663-1.486a19.382 19.382 0 0 0-2.423-12.388c-4.988-8.638-15.572-12.002-24.508-8.159a14.2 14.2 0 0 0-1.162-2.551c-3.427-5.937-10.417-8.535-16.722-6.644a19.084 19.084 0 0 0-2.028-5.013c-5.377-9.314-17.282-12.504-26.596-7.126-.819.472-1.594.989-2.32 1.564a28.858 28.858 0 0 0-8.115-3.888 28.878 28.878 0 0 0-22.817 2.66Z"
        />
        <path
          fill="#F3F3F4"
          d="M209.685 203.71a118.315 118.315 0 0 1-8.715 8.85C179.625 232.075 151.2 244 120 244c-12.39 0-24.345-1.875-35.595-5.37a120.054 120.054 0 0 1-47.49-28.035c1.2-9.42 9.225-16.695 18.975-16.695 7.215 0 13.5 3.99 16.77 9.9a35.423 35.423 0 0 1 9.93-5.475 35.246 35.246 0 0 1 11.85-2.04c10.53 0 19.995 4.605 26.475 11.91 2.46 2.79 4.5 5.97 6 9.45a35.02 35.02 0 0 1 16.335-4.575 34.857 34.857 0 0 1 1.785-6.375c4.215-10.935 13.68-19.23 25.32-21.795 2.475-.555 5.04-.84 7.665-.84 13.875 0 25.89 7.995 31.665 19.65Z"
        />
        <path
          fill="#F3F3F4"
          d="M349.685 213.711a118.315 118.315 0 0 1-8.715 8.85c-21.345 19.515-49.77 31.44-80.97 31.44-12.39 0-24.345-1.875-35.595-5.37a120.054 120.054 0 0 1-47.49-28.035c1.2-9.42 9.225-16.695 18.975-16.695 7.215 0 13.5 3.99 16.77 9.9a35.423 35.423 0 0 1 9.93-5.475 35.246 35.246 0 0 1 11.85-2.04c10.53 0 19.995 4.605 26.475 11.91 2.46 2.79 4.5 5.97 6 9.45a35.02 35.02 0 0 1 16.335-4.575 34.857 34.857 0 0 1 1.785-6.375c4.215-10.935 13.68-19.23 25.32-21.795 2.475-.555 5.04-.84 7.665-.84 13.875 0 25.89 7.995 31.665 19.65Z"
        />
        <path
          fill="#F3F3F4"
          d="M299.685 233.711a118.315 118.315 0 0 1-8.715 8.85c-21.345 19.515-49.77 31.44-80.97 31.44-12.39 0-24.345-1.875-35.595-5.37a120.054 120.054 0 0 1-47.49-28.035c1.2-9.42 9.225-16.695 18.975-16.695 7.215 0 13.5 3.99 16.77 9.9a35.423 35.423 0 0 1 9.93-5.475 35.246 35.246 0 0 1 11.85-2.04c10.53 0 19.995 4.605 26.475 11.91 2.46 2.79 4.5 5.97 6 9.45a35.02 35.02 0 0 1 16.335-4.575 34.857 34.857 0 0 1 1.785-6.375c4.215-10.935 13.68-19.23 25.32-21.795 2.475-.555 5.04-.84 7.665-.84 13.875 0 25.89 7.995 31.665 19.65ZM98.77 229.65a118.315 118.315 0 0 1-8.715 8.85c-21.345 19.515-49.77 31.44-80.97 31.44-12.39 0-24.345-1.875-35.595-5.37A120.054 120.054 0 0 1-74 236.535c1.2-9.42 9.225-16.695 18.975-16.695 7.215 0 13.5 3.99 16.77 9.9a35.423 35.423 0 0 1 9.93-5.475 35.246 35.246 0 0 1 11.85-2.04c10.53 0 19.995 4.605 26.475 11.91 2.46 2.79 4.5 5.97 6 9.45a35.02 35.02 0 0 1 16.335-4.575 34.857 34.857 0 0 1 1.785-6.375c4.215-10.935 13.68-19.23 25.32-21.795 2.475-.555 5.04-.84 7.665-.84 13.875 0 25.89 7.995 31.665 19.65Z"
        />
        <path
          fill="#DBDBDC"
          d="M-22.732 212.466c9.565 20.746 24.632 37.797 42.936 49.835 3.451 2.274 7.03 4.39 10.709 6.293 5.39-5.731 8.34-13.556 7.826-21.679a13.327 13.327 0 0 0 14.587-12.963 12.889 12.889 0 0 0-.45-3.762c-1.902-7.099-9.213-11.321-16.327-9.414a13.269 13.269 0 0 0-7.927 5.944c-6.42-5.47-15.14-8.056-23.923-6.464-4.086-8.005-13.343-12.357-22.384-9.935a19.607 19.607 0 0 0-5.047 2.145Z"
        />
        <path
          fill="#F3F3F4"
          d="M188.3 240.534a128.84 128.84 0 0 0 13.154 3.178c30.974 5.785 64.135.474 93.006-17.468 11.466-7.124 21.45-15.734 29.851-25.438 13.453-15.537 22.863-33.833 27.824-53.251-6.528-8.027-18.137-10.145-27.159-4.538-6.677 4.149-10.198 11.455-9.826 18.805a38.582 38.582 0 0 0-12.337.644 38.403 38.403 0 0 0-12.139 4.926c-9.744 6.055-15.854 15.76-17.65 26.246a38.798 38.798 0 0 0-.118 12.195 38.143 38.143 0 0 0-17.747 5.16 37.917 37.917 0 0 0-5.318-4.873c-10.188-7.695-23.717-9.928-35.963-5.608a38.125 38.125 0 0 0-7.576 3.63c-12.839 7.979-19.36 22.286-18.002 36.392Z"
        />
        <path
          fill="#DBDBDC"
          d="M230.962 251.298c-17.731 14.406-38.771 23.07-60.507 25.808-4.1.52-8.246.841-12.386.928-2.312-7.52-1.511-15.844 2.531-22.909a13.329 13.329 0 0 1-7.38-18.065 12.92 12.92 0 0 1 2.061-3.179c4.837-5.534 13.261-6.1 18.807-1.253a13.272 13.272 0 0 1 4.495 8.829c8.173-2.08 17.141-.557 24.322 4.743 7.197-5.385 17.424-5.211 24.472.948a19.63 19.63 0 0 1 3.585 4.15Z"
        />
        <path
          fill="#C5F4FF"
          d="M158.19 119.79c3.33-37.32-11.25-68.13-11.25-68.13A150.353 150.353 0 0 1 120 54.105c-7.5 0-14.985-.57-22.41-1.695-1.515-.225-3.03-.48-4.53-.75-.03.06-.045.105-.075.165-1.065 2.49-2.04 4.95-2.94 7.38-.24.675-.48 1.335-.705 1.995-3.18 9.345-7.35 24.765-7.95 42.885v.15c-.495 25.005 6.3 43.95 10.485 53.19.285.615.54 1.185.795 1.695.72 1.47 1.485 2.94 2.295 4.395.03.045.06.09.075.135h49.905c1.8-3.21 3.375-6.465 4.77-9.72 9.99 6.84 14.97 40.23 14.97 40.23 19.305-49.08-1.365-70.08-6.495-74.37Zm-38.19-9.6c-6.945 0-12.57-5.625-12.57-12.57s5.625-12.57 12.57-12.57 12.57 5.625 12.57 12.57-5.625 12.57-12.57 12.57Z"
        />
        <path
          fill="#C5F4FF"
          d="M91.875 157.425a71.997 71.997 0 0 1-1.59-3.495c-9.99 6.84-14.97 40.23-14.97 40.23-19.305-49.08 1.365-70.08 6.495-74.37-.48-5.34-.585-10.545-.42-15.555-.495 25.005 6.3 43.95 10.485 53.19Z"
        />
        <path
          fill="#33B9F4"
          d="M146.94 51.66A150.353 150.353 0 0 1 120 54.105c-7.5 0-14.985-.57-22.41-1.695-1.515-.225-3.03-.48-4.53-.75 0 0 0-.03.03-.075 3.78-9.465 7.56-16.56 11.04-21.885 0-.03.03-.06.06-.09 8.49-12.975 15.21-15.3 15.765-15.48.015 0 .03 0 .045-.015 0 0 13.47 3.765 26.94 37.545Z"
        />
        <path
          fill="#66CBF7"
          d="M120 110.139c6.942 0 12.57-5.627 12.57-12.57 0-6.942-5.628-12.57-12.57-12.57-6.943 0-12.57 5.628-12.57 12.57 0 6.943 5.627 12.57 12.57 12.57Z"
        />
        <path
          fill="#99DCF9"
          d="M120 14.115s-12.615 13.62-22.11 37.545c-.105.255-.195.495-.3.75-1.515-.225-3.03-.48-4.53-.75 0 0 0-.03.03-.075.345-1.035 4.095-11.715 11.04-21.885 0-.03.03-.06.06-.09 4.14-6.075 9.405-11.94 15.765-15.48.015 0 .03 0 .045-.015Z"
        />
        <path
          fill="#66CBF7"
          d="m135.345 173.355 4.11 10.575H100.2l4.11-10.59-6.166-9.675h43.441l-6.165 9.675s-.045 0-.075.015Z"
        />
        <path
          fill="#33B9F4"
          d="M118.38 135.72c-1.77 12.435-3.735 34.29-.15 55.98.555 3.33 3.105 3.27 3.57-.105 1.62-11.595 3.42-32.52.165-55.83-.48-3.435-3.105-3.48-3.585-.045Z"
        />
        <path
          fill="#66CBF7"
          d="M97.56 52.41c-10.26 26.31-20.805 53.85 1.68 111.255h-4.17s-31.335-48.93-2.01-112.005l4.5.75Z"
        />
        <mask id="PioneerRocketIcon_svg__b" width="26" height="26" x="107" y="85" maskUnits="userSpaceOnUse">
          <path
            fill="#66CBF7"
            d="M120 110.189c6.942 0 12.57-5.628 12.57-12.57s-5.628-12.57-12.57-12.57c-6.943 0-12.57 5.628-12.57 12.57s5.627 12.57 12.57 12.57Z"
          />
        </mask>
        <g mask="url(#PioneerRocketIcon_svg__b)">
          <path
            fill="#CCD1EA"
            d="M115.661 114.562a3.457 3.457 0 0 0-2.808-4.001 3.456 3.456 0 0 0-4.004 2.803l-.531 3.018a3.456 3.456 0 0 0 2.808 4 3.456 3.456 0 0 0 4.004-2.802l.531-3.018Z"
          />
          <path
            fill="#CCD1EA"
            d="M127.003 116.94a35.694 35.694 0 0 1-15.032-2.446 5.148 5.148 0 0 1-2.642-7.224l3.536-6.587a5.751 5.751 0 0 1 6.064-2.945l6.876 1.21a5.752 5.752 0 0 1 4.746 5.328l.479 8.155a4.264 4.264 0 0 1-4.027 4.509Z"
          />
          <path
            fill="#000"
            d="M132.772 95.061a7.89 7.89 0 0 1-.093.447c-1.046 4.38-6.015 7.121-11.203 6.19-5.381-.966-9.082-5.466-8.272-10.069l.003-.018c.07-.391.725-.392.846-.001.995 3.21 4.076 5.872 8.083 6.577 4.007.705 7.811-.746 9.841-3.424.251-.33.863-.098.795.298Z"
            opacity=".1"
          />
          <path
            fill="#FAF9F9"
            d="M113.104 100.285s.544 2.976 3.116 4.077c.491.442.014 1.82.014 1.82s-3.54-1.936-3.877-4.556c-.076.059.747-1.342.747-1.342v.001Zm17.319 3.046s-2.397 2.815-5.19 2.972c.005.065.235 1.504.235 1.504s3.988-.612 5.199-2.96c.051.081-.244-1.516-.244-1.516Zm-15.397 4.395s-3.684.961-6.266 2.673c-.064.016.245 1.273.45 1.512-.054.047 5.524-3.359 6.184-2.95l-.368-1.235Zm10.276 1.514s3.708 2.553 5.551 5.043c.055.037-.665 1.113-.939 1.268.034.063-4.047-5.042-4.807-4.883l.195-1.428Z"
          />
          <path
            fill="#E0E4EB"
            d="m123.701 104.864-5.272-.927a2.657 2.657 0 0 0-3.079 2.155l-.378 2.145a2.657 2.657 0 0 0 2.159 3.076l5.272.928a2.659 2.659 0 0 0 3.08-2.156l.377-2.144a2.659 2.659 0 0 0-2.159-3.077Z"
          />
          <path
            fill="#fff"
            stroke="#00A8F1"
            strokeMiterlimit="10"
            strokeWidth="4"
            d="M120.153 109.798a1.514 1.514 0 0 0 1.754-1.228 1.515 1.515 0 0 0-2.983-.524 1.513 1.513 0 0 0 1.229 1.752Z"
          />
          <path fill="#00A8F1" d="M120.353 108.663a.36.36 0 1 0 .124-.71.36.36 0 0 0-.124.71Z" />
        </g>
        <path
          fill="#CCD1EA"
          d="M134.58 95.944c2.544-5.633-.207-12.37-6.145-15.046-5.938-2.677-12.813-.28-15.357 5.352-2.543 5.633.208 12.369 6.146 15.046 5.937 2.677 12.813.28 15.356-5.352Z"
        />
        <path
          fill="#00A8F1"
          d="M132.882 94.12c2.057-4.554-.168-10-4.969-12.165-4.801-2.165-10.361-.227-12.417 4.327-2.057 4.555.168 10.002 4.969 12.166 4.801 2.165 10.361.227 12.417-4.327Z"
        />
        <path
          fill="#CCD1EA"
          d="M115.143 84.537a.857.857 0 0 1 .43 1.133l-2.16 4.783a.858.858 0 0 1-1.134.428 2.784 2.784 0 0 1-1.395-3.683l.573-1.269a2.789 2.789 0 0 1 3.687-1.392h-.001Zm19.547 8.813a2.785 2.785 0 0 1 1.395 3.683l-.573 1.268a2.788 2.788 0 0 1-3.687 1.392.858.858 0 0 1-.429-1.133l2.16-4.782a.858.858 0 0 1 1.134-.428Z"
        />
        <path
          fill="#fff"
          d="M119.311 94.794a4.574 4.574 0 0 1-.258-.126c-2.506-1.316-3.405-4.761-2.035-7.768 1.422-3.12 4.736-4.669 7.414-3.462l.01.005c.228.103.127.502-.13.514-2.109.106-4.208 1.564-5.258 3.889-1.049 2.325-.755 4.861.562 6.511.162.204-.075.54-.305.436Zm11.235-4.198a1.822 1.822 0 0 0 2.111-1.477 1.822 1.822 0 0 0-3.59-.632 1.82 1.82 0 0 0 1.479 2.109Zm1.917.912a.428.428 0 1 0 .149-.843.428.428 0 0 0-.149.843Z"
        />
        <path
          fill="#CCD1EA"
          d="M130.79 108.611a3.166 3.166 0 0 0 4.461.33 3.155 3.155 0 0 0 .854-3.604l-.001-.001a7.598 7.598 0 0 0-.307-.567l-.003-.006c-1.623-2.726-4.25-3.46-5.878-3.641-.43-.048-.704.434-.456.789.794 1.145.747 2.941.651 3.894-.05.209-.078.423-.086.638l-.001.006h.001a3.145 3.145 0 0 0 .765 2.162Z"
        />
        <path
          fill="#00A8F1"
          d="M135.489 104.23s-2.068-.752-5.347.778c-.003.063-.046.714-.046.714s5.386-1.489 6.097-.179c-.025-.035-.097-.446-.704-1.313Z"
        />
        <path
          fill="#CCD1EA"
          d="M107.549 91.713a4.498 4.498 0 0 0-5.247 3.604 4.512 4.512 0 0 0 2.262 4.775l.001-.001c.284.135.566.254.846.359l.009.004c4.232 1.591 7.803.071 9.771-1.177.52-.33.395-1.11-.196-1.286-1.902-.567-3.45-2.61-4.193-3.757a4.533 4.533 0 0 0-.472-.786l-.005-.008h-.001a4.482 4.482 0 0 0-2.775-1.727Z"
        />
        <path
          fill="#00A8F1"
          d="M106.142 100.701s2.966-1.003 5.243-5.627c-.052-.072-.583-.836-.583-.836s-4.655 6.454-6.612 5.628c.059.016.505.41 1.952.835Z"
        />
      </g>
      <defs>
        <clipPath id="PioneerRocketIcon_svg__a">
          <path fill="#fff" d="M0 0h240v240H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
