export const AnswerAcceptedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M6.667 8a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Zm2 0a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Zm2 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.099 1.41a.5.5 0 0 1 .158.689L11.854 5.94a.633.633 0 0 1-1.029.063l-1.38-1.7a.5.5 0 0 1 .777-.63l1.056 1.302 2.132-3.407a.5.5 0 0 1 .689-.159Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.172 2H5.333a4 4 0 0 0-4 4v4a4 4 0 0 0 4 4h9.334V6c0-.438-.07-.86-.2-1.253-.173.447-.45.843-.802 1.157l.002.096v7H5.333a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h3.408c.082-.363.23-.7.431-1Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
