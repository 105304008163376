export const VisibilityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 12.5c3.927-9.996 18.073-9.996 22 0-3.927 9.996-18.073 9.996-22 0Zm2.18 0c3.534-7.33 14.106-7.33 17.64 0-3.534 7.33-14.106 7.33-17.64 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 15a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 2a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
