export const ReactionEndorseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M6.604 12.246h9.65a4.593 4.593 0 0 1 4.588 4.589v1.018a6.655 6.655 0 0 1-6.646 6.647h-.955a6.655 6.655 0 0 1-6.647-6.646v-5.607h.01Z"
        fill="#6D28FF"
      />
      <path
        d="M20.233 16.866v.431a6.688 6.688 0 0 1-6.688 6.699h-.273a6.69 6.69 0 0 1-6.699-6.699v-5.061h9.04a4.629 4.629 0 0 1 4.62 4.63Z"
        fill="#FFD600"
      />
      <path
        d="M8.628 10.64h-.21c-.76 0-1.375.616-1.375 1.375v2.94c0 .76.616 1.376 1.375 1.376h.21c.76 0 1.376-.616 1.376-1.375v-2.94c0-.76-.616-1.376-1.376-1.376Z"
        fill="#6D28FF"
      />
      <path
        d="M11.518 9.254h-.22c-.823 0-1.491.668-1.491 1.491v2.72c0 .823.668 1.49 1.491 1.49h.22a1.49 1.49 0 0 0 1.491-1.49v-2.72a1.49 1.49 0 0 0-1.49-1.491Z"
        fill="#6D28FF"
      />
      <path
        d="M11.267 9.254h-.179c-.707 0-1.281.573-1.281 1.281v2.572c0 .708.573 1.282 1.281 1.282h.179a1.28 1.28 0 0 0 1.28-1.281v-2.573a1.28 1.28 0 0 0-1.28-1.281Z"
        fill="#FFD600"
      />
      <path
        d="M15.096 8.634h-.22a1.47 1.47 0 0 0-1.47 1.47v2.972c0 .812.658 1.47 1.47 1.47h.22a1.47 1.47 0 0 0 1.47-1.47v-2.972a1.47 1.47 0 0 0-1.47-1.47Z"
        fill="#6D28FF"
      />
      <path
        d="M14.469 8.634h-.178c-.708 0-1.282.574-1.282 1.281v2.573c0 .707.574 1.281 1.281 1.281h.179c.708 0 1.281-.574 1.281-1.281V9.915a1.28 1.28 0 0 0-1.281-1.28Z"
        fill="#FFD600"
      />
      <path
        d="M18.349 3.5h.178c.704 0 1.281.577 1.281 1.281v9.608h-2.74V4.78c0-.704.577-1.281 1.28-1.281Z"
        fill="#6D28FF"
      />
      <path
        d="M17.68 3.5h.178c.703 0 1.28.577 1.28 1.281v9.608h-2.74V4.78c0-.704.578-1.281 1.281-1.281Zm-9.532 7.14h-.21c-.76 0-1.375.616-1.375 1.375V14.4c0 .76.615 1.376 1.375 1.376h.21c.76 0 1.376-.616 1.376-1.376v-2.383c0-.76-.616-1.376-1.376-1.376Z"
        fill="#FFD600"
      />
      <path
        d="M20.531 17.339s.144-3.207-2.51-4.498a2.448 2.448 0 0 0-1.053-.227h-4.234s-1.016 4.06 4.366 2.996"
        fill="#FFD600"
      />
      <path
        d="M20.531 17.339s.144-3.207-2.51-4.498a2.448 2.448 0 0 0-1.053-.227h-4.234s-1.016 4.06 4.366 2.996"
        stroke="#6D28FF"
        strokeWidth=".7"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
