export const UnfoldLessMediumIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.602 2.602c.22-.22.576-.22.796 0L9 5.205l2.602-2.603a.562.562 0 1 1 .796.796l-3 3a.563.563 0 0 1-.796 0l-3-3a.563.563 0 0 1 0-.796Zm0 11.25 3-3c.22-.22.576-.22.796 0l3 3a.562.562 0 1 1-.796.796L9 12.046l-2.602 2.602a.562.562 0 1 1-.796-.796Z"
        fill="currentColor"
      />
    </svg>
  );
};
