export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.647 6.238a1 1 0 0 1 .115 1.41l-7.638 9a1 1 0 0 1-1.516.009L5.246 12.8a1 1 0 1 1 1.508-1.314l2.598 2.98 6.886-8.113a1 1 0 0 1 1.41-.115Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
