export const RemoveConnectionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.132 14.526c.582-.775 1.425-1.276 2.368-1.276h5c.943 0 1.786.5 2.368 1.276.579.772.882 1.783.882 2.807V19a.75.75 0 0 1-1.5 0v-1.667c0-.744-.223-1.428-.582-1.907-.356-.474-.785-.676-1.168-.676h-5c-.383 0-.812.202-1.168.676-.359.479-.582 1.163-.582 1.907V19a.75.75 0 0 1-1.5 0v-1.667c0-1.024.303-2.035.882-2.807ZM8 5.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM4.25 8a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Zm16.652 1.098a.75.75 0 0 1 0 1.061l-4.243 4.243a.75.75 0 1 1-1.06-1.061l4.242-4.243a.75.75 0 0 1 1.06 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.902 14.402a.75.75 0 0 1-1.061 0l-4.243-4.243a.75.75 0 0 1 1.061-1.06l4.243 4.242a.75.75 0 0 1 0 1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
