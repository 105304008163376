export const StarOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.39 9.294 12 5.004l-2.39 4.29-4.783 1.083 3.267 3.821-.485 5.008L12 17.184l4.39 2.022-.484-5.008 3.267-3.821-4.783-1.083zm5.455 1.235zm-11.22-2.55L3.824 9.066c-.782.177-1.09 1.17-.558 1.793l3.274 3.83-.495 5.108c-.08.832.727 1.446 1.46 1.109L12 18.835l4.496 2.07c.732.338 1.54-.276 1.46-1.108l-.496-5.109 3.274-3.829c.533-.623.224-1.616-.558-1.793l-4.801-1.087-2.473-4.437a1.022 1.022 0 0 0-1.804 0L8.625 7.979z"
      />
    </svg>
  );
};
