export const TagIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11997 15.0834L15.8334 15.0834C16.5238 15.0834 17.0834 14.5238 17.0834 13.8334L17.0834 7.16675C17.0834 6.47639 16.5238 5.91675 15.8334 5.91675L7.11997 5.91675C6.71628 5.91675 6.33744 6.11171 6.1028 6.4402L3.72185 9.77353C3.4114 10.2082 3.4114 10.792 3.72185 11.2266L6.1028 14.56C6.33744 14.8885 6.71628 15.0834 7.11997 15.0834ZM5.08563 15.2865C5.55491 15.9435 6.31259 16.3334 7.11997 16.3334L15.8334 16.3334C17.2141 16.3334 18.3334 15.2141 18.3334 13.8334V7.16675C18.3334 5.78604 17.2141 4.66675 15.8334 4.66675H7.11997C6.31259 4.66675 5.55491 5.05666 5.08563 5.71365L2.70468 9.04699C2.08379 9.91623 2.08379 11.0839 2.70468 11.9532L5.08563 15.2865ZM13.3334 10.5001C13.3334 10.9603 13.7065 11.3334 14.1667 11.3334C14.6269 11.3334 15 10.9603 15 10.5001C15 10.0398 14.6269 9.66675 14.1667 9.66675C13.7065 9.66675 13.3334 10.0398 13.3334 10.5001ZM7.50004 11.3334C7.0398 11.3334 6.66671 10.9603 6.66671 10.5001C6.66671 10.0398 7.0398 9.66675 7.50004 9.66675C7.96028 9.66675 8.33337 10.0398 8.33337 10.5001C8.33337 10.9603 7.96028 11.3334 7.50004 11.3334ZM10 10.5001C10 10.9603 10.3731 11.3334 10.8334 11.3334C11.2936 11.3334 11.6667 10.9603 11.6667 10.5001C11.6667 10.0398 11.2936 9.66675 10.8334 9.66675C10.3731 9.66675 10 10.0398 10 10.5001Z"
        fill="currentColor"
      />
    </svg>
  );
};
