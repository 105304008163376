export const BadgeAdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#Badge Icons__a)">
        <path d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z" fill="#2A0FCE" />
        <path
          d="m12.53 6.857-.005.19-.03 1.081-.826 2.628-.683 3.059-.346 4.032A8.995 8.995 0 0 1 8.985 18a8.956 8.956 0 0 1-3.623-.76c.36-1.637.714-3.506.741-4.614a2.668 2.668 0 0 0-.158-.946c-.196-.56-.633-2.165-.555-3.866.009-.191.024-.383.047-.576a6.519 6.519 0 0 1 .161-.872l.857.091.305.033.308.033.188.02.292.03.29.032.713.075.068.004.422.018.284.013h.006l.277.012.427.02.56.024h.045l.05.004.255.011.066.003.231.01 1.288.058Z"
          fill="#fff"
        />
        <path
          d="M11.214 6.936a1.744 1.744 0 0 1-.203-.146l-.049-.045-.017.041-.255-.011-.043-.017-.007.014h-.045l-.56-.025a2.99 2.99 0 0 1-.375-.3h-.045v.03c0 .085-.003.168-.009.25l-.276-.012-.006-.007v.007l-.285-.013a2.38 2.38 0 0 1-.039.28l.196.009.092.003 1.14.05.193.01.17.006.115.006.573.025.024-.027.073-.079s-.143-.003-.363-.05h.001Zm1.56-.222a.285.285 0 0 0-.231-.14h-.014l-.324-.014-.879-.04-.203-.009-.083-.003-.288-.012h-.023l-.36-.017-.237-.01-.472-.022h-.045l-.28-.014-.211-.01-.073-.003-.47-.02-.118-.012-.681-.073-.599-.063-.207-.023-.593-.063-.755-.08a.322.322 0 0 0-.119.016.28.28 0 0 0-.183.193 7.256 7.256 0 0 0-.062.26 7.32 7.32 0 0 0-.114.717c-.246 2.238.493 4.396.529 4.5.101.294.15.578.143.847-.026 1.071-.37 2.89-.722 4.5.086.043.173.082.262.12.088.04.175.077.265.113.365-1.66.73-3.573.757-4.72a2.954 2.954 0 0 0-.174-1.044 11.286 11.286 0 0 1-.549-3.369 8.45 8.45 0 0 1 .064-1.016c.023-.177.052-.354.09-.53l.736.077.642.069.108.01.57.062.668.07.274.013.188.008.196.009.092.003.284.012.074.004.783.035.192.009.17.006.115.006.21.01.364.015.366.017.395.017-.004.135-.024.822-.82 2.613-.683 3.058-.005.037-.353 4.107a5.2 5.2 0 0 0 .287-.05 4.82 4.82 0 0 0 .287-.059l.338-3.93.574-2.577.098-.44.826-2.629a.292.292 0 0 0 .012-.076l.037-1.271a.285.285 0 0 0-.039-.151Z"
          fill="#ADABFF"
        />
        <path
          d="M6.887 8.529c.6-.148.874-1.126.612-2.186-.26-1.059-.959-1.798-1.558-1.65-.6.148-.874 1.126-.613 2.186.261 1.059.959 1.798 1.559 1.65Z"
          fill="#fff"
        />
        <path
          d="M7.87 6.89a3.994 3.994 0 0 0-.089-.576l-.008-.038c-.138-.559-.394-1.057-.721-1.404a1.695 1.695 0 0 0-.349-.291 1.244 1.244 0 0 0-.256-.125.984.984 0 0 0-.573-.036c-.762.188-1.122 1.298-.82 2.526a3.471 3.471 0 0 0 .336.868 2.885 2.885 0 0 0 .386.537c.297.314.628.48.952.48a.963.963 0 0 0 .226-.028c.3-.074.537-.291.696-.603.043-.08.079-.17.11-.263.03-.091.054-.19.073-.292a2.812 2.812 0 0 0 .038-.754Zm-.581.617c-.02.122-.05.232-.089.327-.089.232-.225.384-.381.422-.184.05-.415-.06-.635-.292a2.366 2.366 0 0 1-.459-.76 2.94 2.94 0 0 1-.124-.392 2.874 2.874 0 0 1-.092-.71c-.002-.62.215-1.065.5-1.135a.32.32 0 0 1 .086-.01c.072 0 .149.02.228.057a1.05 1.05 0 0 1 .32.244c.081.087.158.188.229.3a2.898 2.898 0 0 1 .384.985 3.023 3.023 0 0 1 .061.505c.005.165-.006.32-.028.46Z"
          fill="#ADABFF"
        />
        <path
          d="M8.49 8.083c.7-.173 1.03-1.276.737-2.464-.293-1.189-1.098-2.012-1.799-1.84-.7.173-1.03 1.277-.737 2.465.293 1.188 1.098 2.012 1.798 1.84Z"
          fill="#fff"
        />
        <path
          d="M9.615 6.446v-.043a3.972 3.972 0 0 0-.363-1.561 3.031 3.031 0 0 0-.505-.777 2.167 2.167 0 0 0-.462-.389c-.299-.177-.617-.245-.924-.17-.425.105-.741.457-.914.95a2.54 2.54 0 0 0-.125.558 3.624 3.624 0 0 0 .095 1.298 4.532 4.532 0 0 0 .134.438c.16.435.387.807.649 1.084a2.067 2.067 0 0 0 .45.366c.21.122.43.188.65.188.086 0 .171-.01.257-.03.388-.096.685-.396.865-.822a2.438 2.438 0 0 0 .159-.576c.01-.075.02-.153.027-.233.005-.082.009-.165.009-.25v-.03h-.002Zm-.583.349a2.307 2.307 0 0 1-.072.355c-.104.351-.29.6-.538.66-.187.045-.392-.018-.589-.165a1.525 1.525 0 0 1-.25-.23 2.61 2.61 0 0 1-.39-.596 3.197 3.197 0 0 1-.217-.59l-.013-.052a3.187 3.187 0 0 1-.08-1.073c.012-.12.032-.233.06-.339.1-.378.292-.648.552-.712a.477.477 0 0 1 .12-.014c.161 0 .339.072.512.208a1.607 1.607 0 0 1 .254.248c.1.119.195.258.28.417.12.221.22.481.293.775.05.202.08.406.092.602l.005.131a3.286 3.286 0 0 1-.02.375Z"
          fill="#ADABFF"
        />
        <path
          d="M10.822 6.146c-.014.122-.038.24-.07.35l-.002.003c0 .005-.003.01-.004.016-.023.07-.054.156-.099.243l-.007.014-.017.03a.745.745 0 0 1-.194.242.527.527 0 0 1-.217.114c-.18.043-.376-.014-.565-.148a1.76 1.76 0 0 1-.316-.295l-.006-.007a2.549 2.549 0 0 1-.279-.42 3.3 3.3 0 0 1-.385-1.377 2.964 2.964 0 0 1 .015-.493 2.28 2.28 0 0 1 .07-.353c.103-.353.29-.603.539-.665a.552.552 0 0 1 .121-.015c.146 0 .3.06.451.168a1.848 1.848 0 0 1 .323.31 2.878 2.878 0 0 1 .393.664 3.296 3.296 0 0 1 .17.509l.006.029c.09.375.115.748.073 1.08Z"
          fill="#fff"
        />
        <path
          d="m11.297 4.932-.008-.033a4.175 4.175 0 0 0-.16-.505 3.174 3.174 0 0 0-.602-.998 1.925 1.925 0 0 0-.434-.363c-.302-.186-.627-.255-.943-.18-.388.096-.685.397-.865.823a2.47 2.47 0 0 0-.158.57 3.63 3.63 0 0 0 .079 1.413 3.891 3.891 0 0 0 .413 1.025 2.897 2.897 0 0 0 .34.466c.074.08.149.153.227.218.076.064.155.121.236.168.215.13.44.2.666.2a1.065 1.065 0 0 0 .58-.17c.151-.095.282-.228.39-.394a1.764 1.764 0 0 0 .13-.242l.005-.007a1.778 1.778 0 0 0 .094-.257l.002-.006c0-.002 0-.006.003-.008.012-.043.024-.087.034-.132.114-.474.104-1.032-.029-1.588Zm-.335 1.813-.017.041c-.145.336-.373.573-.667.645-.7.173-1.506-.65-1.799-1.84-.292-1.188.037-2.291.738-2.464.701-.173 1.506.65 1.799 1.839.165.671.133 1.315-.054 1.78Z"
          fill="#ADABFF"
        />
        <path
          d="M11.016 4.966c-.293-1.188-1.098-2.012-1.799-1.839-.7.173-1.03 1.276-.738 2.464.293 1.19 1.098 2.013 1.799 1.84.294-.072.522-.309.667-.645l.017-.04c.187-.465.22-1.109.054-1.78Zm-.376 1.806-.017.03a.745.745 0 0 1-.194.242.527.527 0 0 1-.217.114c-.18.043-.376-.014-.565-.148a1.76 1.76 0 0 1-.316-.295l-.006-.007a2.549 2.549 0 0 1-.279-.42 3.3 3.3 0 0 1-.385-1.377 2.964 2.964 0 0 1 .015-.493 2.28 2.28 0 0 1 .07-.353c.103-.353.29-.603.539-.665a.552.552 0 0 1 .121-.015c.146 0 .3.06.451.168a1.848 1.848 0 0 1 .323.31 2.878 2.878 0 0 1 .393.664 3.296 3.296 0 0 1 .17.509l.006.029c.09.375.115.748.073 1.08-.014.123-.038.24-.07.35l-.002.004c0 .005-.003.01-.004.016-.023.07-.054.156-.099.243l-.007.014Z"
          fill="#ADABFF"
        />
        <path
          d="M11.932 7.075c.7-.172 1.031-1.276.738-2.464-.293-1.188-1.098-2.012-1.799-1.84-.7.174-1.03 1.277-.737 2.465.293 1.189 1.098 2.012 1.798 1.84Z"
          fill="#fff"
        />
        <path
          d="m12.944 4.544-.03-.117a3.606 3.606 0 0 0-.088-.271 3.048 3.048 0 0 0-.106-.267 3.07 3.07 0 0 0-.595-.904c-.413-.422-.882-.593-1.32-.486-.293.072-.535.262-.713.534-.05.076-.094.159-.135.247a2.16 2.16 0 0 0-.1.273c-.096.327-.134.71-.1 1.118a3.539 3.539 0 0 0 .086.556 3.864 3.864 0 0 0 .332.924 3.242 3.242 0 0 0 .364.556c.018.022.036.043.056.064l.028.032.057.06a1.934 1.934 0 0 0 .227.203c.05.04.1.074.152.106a1.427 1.427 0 0 0 .544.201h.001A1.126 1.126 0 0 0 12 7.35a1.14 1.14 0 0 0 .526-.302 1.48 1.48 0 0 0 .248-.333c.186-.332.287-.764.287-1.247 0-.293-.037-.605-.116-.923Zm-.739 2.016a.749.749 0 0 1-.162.16.533.533 0 0 1-.178.083c-.198.048-.39-.028-.544-.13l-.03-.02a1.614 1.614 0 0 1-.168-.142l-.042-.041-.026-.027a2.223 2.223 0 0 1-.232-.297 3.15 3.15 0 0 1-.417-.977l-.008-.033a3.557 3.557 0 0 1-.087-.557 2.828 2.828 0 0 1 .012-.521 2.17 2.17 0 0 1 .079-.373c.034-.108.076-.204.125-.289.11-.189.253-.311.413-.35a.526.526 0 0 1 .123-.016c.275 0 .526.21.66.348a2.41 2.41 0 0 1 .464.694c.04.084.074.17.106.26a2.726 2.726 0 0 1 .104.347c.204.828.08 1.524-.192 1.88Z"
          fill="#ADABFF"
        />
        <path
          d="m11.292 12.488 2.456-3.301c.231-.312.36-.688.369-1.076.034-1.84-.145-2.856-.356-3.42a.851.851 0 0 0-1.092-.494c-1.345.508-2.427.7-3.032.772a.4.4 0 0 0-.306.586c.747 1.393 2.246 1.43 2.246 1.43l-.944 1.03S8.47 9.626 9.508 12.012l1.787.475h-.002Z"
          fill="#fff"
        />
        <path
          d="M11.292 12.769a.28.28 0 0 1-.225-.449l2.456-3.3c.199-.269.307-.584.313-.914.036-1.9-.164-2.852-.337-3.316a.56.56 0 0 0-.297-.314.557.557 0 0 0-.433-.015c-1.338.505-2.424.707-3.098.788a.117.117 0 0 0-.093.064.116.116 0 0 0 0 .11c.657 1.226 1.952 1.28 2.007 1.282l.613.02-1.357 1.48c-.12.096-2.01 1.549-1.076 3.696a.282.282 0 0 1-.516.224c-1.115-2.56 1.192-4.319 1.216-4.336l.554-.608c-.558-.142-1.41-.513-1.936-1.492a.681.681 0 0 1 .52-.999c.645-.078 1.682-.271 2.967-.756.28-.107.587-.096.864.028.275.125.486.349.591.63.278.74.404 1.925.374 3.523a2.123 2.123 0 0 1-.425 1.238l-2.456 3.301a.28.28 0 0 1-.226.114Z"
          fill="#ADABFF"
        />
        <path
          d="m3.198 3.54.495.905.907.496-.907.496-.495.906-.496-.906-.906-.496.906-.496.496-.906Zm10.812 8.357.497.907.905.495-.905.496-.496.906-.496-.906-.906-.496.906-.495.496-.907Z"
          fill="#C1B1FF"
        />
      </g>
      <defs>
        <clipPath id="Badge Icons__a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
