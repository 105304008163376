export const WatchSpaceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="#757575"
        d="M5.2 6.4H4v8.4c0 .66.54 1.2 1.2 1.2h8.4v-1.2H5.2V6.4ZM14.8 4H7.6c-.66 0-1.2.54-1.2 1.2v7.2c0 .66.54 1.2 1.2 1.2h7.2c.66 0 1.2-.54 1.2-1.2V5.2c0-.66-.54-1.2-1.2-1.2Zm0 8.4H7.6V5.2h7.2v7.2Zm-4.2-1.2h1.2V9.4h1.8V8.2h-1.8V6.4h-1.2v1.8H8.8v1.2h1.8v1.8Z"
      />
    </svg>
  );
};
