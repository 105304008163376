export const TrayIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 3.5h16a.5.5 0 0 1 .5.5v10H15l-1 2.5h-4L9 14H3.5V4a.5.5 0 0 1 .5-.5Zm-.5 12V20a.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5v-4.5h-4.485l-1 2.5h-6.03l-1-2.5H3.5ZM2 14V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-6Zm16-6H6V6.5h12V8ZM6 11h12V9.5H6V11Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
