export const AnswerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M5.48 5A1.48 1.48 0 0 0 4 6.48v8.725c0 .817.663 1.48 1.48 1.48h1.69v1.944a.37.37 0 0 0 .58.305l4.705-2.25h5.863a1.48 1.48 0 0 0 1.48-1.48V6.48A1.48 1.48 0 0 0 18.319 5H5.479Z"
      />
    </svg>
  );
};
