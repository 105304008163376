import { useId } from "react";

export const ReactionPerspectiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M14 24.5c5.799 0 10.5-4.701 10.5-10.5S19.799 3.5 14 3.5 3.5 8.201 3.5 14 8.201 24.5 14 24.5Z"
        fill="#FFD600"
      />
      <mask id={id1} style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="3" y="3" width="22" height="22">
        <path
          d="M14 24.5c5.799 0 10.5-4.701 10.5-10.5S19.799 3.5 14 3.5 3.5 8.201 3.5 14 8.201 24.5 14 24.5Z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#${id1})`} fill="#6D28FF">
        <path d="M13.336 2.873c1.268-.159.341 2.463 1.024 3.548.683 1.086 1.318 2.464.696 3.574-.525.926-1.708 1.243-2.476 1.987a2.808 2.808 0 0 0-.732 2.671c.159.622.549 1.244.39 1.866-.219.866-1.329 1.146-2.207 1-1-.17-1.927-.72-2.573-1.512-.415-.525-.744-1.171-1.341-1.452-.598-.28-1.293-.121-1.94-.195-.646-.073-1.378-.634-1.17-1.256 0 0 .207-9 10.354-10.231h-.025ZM25.04 13.882c0-.402-.805-.098-1.207-.183-.402-.085-.854-.098-1.17.159-.757.621 0 2.048-.671 2.744-.183.182-.427.268-.647.402-.927.537-1.317 1.854-.78 2.78.122.208.28.39.402.586.159.268.268.573.464.805.195.231.548.402.829.256 0 0 2.792-1.903 2.792-7.549h-.012ZM13.748 19.76c-.342.573-1.28.402-1.756.878-.232.22-.305.56-.33.878-.012.244 0 .524.207.67a.66.66 0 0 0 .342.11c.366.061.756.122 1.11 0 .268-.097.5-.305.768-.378.415-.11.841.098 1.232.269.39.17.866.329 1.231.11.415-.232.513-.793.525-1.281.024-.695-.061-1.488-.622-1.903-.317-.231-.72-.304-1.098-.353-.963-.147-1.195.292-1.61 1Z" />
      </g>
    </svg>
  );
};
