export const BadgeExperimentalIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M17.868 21.073H6.132a.897.897 0 0 1-.798-1.309l3.722-7.212V7.344h5.89v5.206l3.722 7.212a.898.898 0 0 1-.799 1.31l-.001.002Z"
      />
      <path
        fill="#C1B1FF"
        d="M15.293 6.617H8.707a.397.397 0 0 0-.398.397v.08c0 .219.178.397.398.397h6.585c.22 0 .397-.178.397-.397v-.08a.397.397 0 0 0-.397-.397Z"
      />
      <path
        fill="#ADABFF"
        d="m14.716 14.019 2.498 4.84a.702.702 0 0 1-.624 1.025H7.41a.702.702 0 0 1-.624-1.024l2.444-4.734c.528-1.025 1.909-1.287 2.732-.48a.33.33 0 0 1 .038.037s.934.642 1.768.099a.657.657 0 0 1 .948.237Z"
      />
      <path
        fill="#fff"
        d="M10.797 5.867a1.47 1.47 0 1 0 0-2.94 1.47 1.47 0 0 0 0 2.94Zm2.382.412a.414.414 0 1 0 0-.828.414.414 0 0 0 0 .828Zm1.501-3.352-.256.876.257.874-.876-.256-.875.256.257-.874-.257-.876.875.256.876-.256Z"
      />
      <path fill="#ADABFF" d="m12.681 8.444.534.978.98.535-.98.535-.534.98-.535-.98-.98-.535.98-.535.535-.978Z" />
      <path
        fill="#C1B1FF"
        d="m13.805 12.062.204.372.37.202-.37.204-.204.37-.203-.37-.372-.204.372-.202.203-.372Zm-3.214-.663.203.372.372.202-.372.204-.203.37-.202-.37-.372-.204.372-.203.203-.371Z"
      />
    </svg>
  );
};
