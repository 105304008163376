export const SpaceWithPaddingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" fill="none" viewBox="0 0 16 17" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.833 12.166a2.833 2.833 0 1 1 5.667 0 2.833 2.833 0 0 1-5.667 0Zm2.833-1.833a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667ZM1.5 12.166a2.833 2.833 0 1 1 5.667 0 2.833 2.833 0 0 1-5.667 0Zm2.833-1.833a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667Zm4.5-5.5a2.833 2.833 0 1 1 5.667 0 2.833 2.833 0 0 1-5.667 0ZM11.666 3a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667ZM1.5 4.833a2.833 2.833 0 1 1 5.667 0 2.833 2.833 0 0 1-5.667 0ZM4.333 3a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
