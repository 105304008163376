export const SubmissionConfirmedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="91" height="95" fill="none" viewBox="0 0 91 95" {...props}>
      <path
        fill="currentColor"
        d="M70.2 45.3V5.2C70.2 2.4 67.9 0 65 0H5.2C2.4 0 0 2.3 0 5.2v79.3c0 1.8 1.1 3.5 2.8 4.1.5.2 1 .3 1.5.3 1.2 0 2.4-.5 3.3-1.5l11-12.7h22.1C42.9 86.3 53.1 95 65.3 95c13.8 0 25.1-11.3 25.1-25.1.1-12.2-8.7-22.4-20.2-24.6ZM14.9 14.8c0-.1.1-.2.2-.2h40.1c.1 0 .2.1.2.2v6.4c0 .1-.1.2-.2.2H15.1c-.1 0-.2-.1-.2-.2v-6.4Zm0 19.3c0-.1.1-.2.2-.2h40.1c.1 0 .2.1.2.2v6.4c0 .1-.1.2-.2.2H15.1c-.1 0-.2-.1-.2-.2v-6.4Zm.2 26c-.1 0-.2-.1-.2-.2v-6.4c0-.1.1-.2.2-.2h31.7c-1.8 2-3.3 4.3-4.4 6.8H15.1ZM65.4 88c-10 0-18.1-8.1-18.1-18.1 0-10 8.1-18.1 18.1-18.1 10 0 18.1 8.1 18.1 18.1 0 10-8.1 18.1-18.1 18.1Z"
      />
      <path
        fill="currentColor"
        d="m70.2 62.5-7.1 8.1-3.3-3.3c-1.4-1.4-3.6-1.4-4.9 0-1.4 1.4-1.4 3.6 0 4.9l5.9 5.9c.7.7 1.5 1 2.5 1h.1c1 0 1.9-.5 2.5-1.2L75.5 67c1.3-1.5 1.1-3.7-.3-4.9-1.5-1.2-3.7-1.1-5 .4Z"
      />
    </svg>
  );
};
