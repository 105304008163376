import React from "react";

import clsx from "clsx";

import { BetaIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";

export interface LogoProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: "white" | "black" | "purple";
  size?: "s" | "m" | "l";
  withBetaText?: boolean;
}

const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  color = "white",
  size = "m",
  withBetaText = true,
  href,
  ...linkProps
}) => {
  const logoStyle = clsx({
    "text-white": color === "white",
    "text-sembly-gray": color === "black",
    "text-dark-purple-100": color === "purple",
  });
  const logoFontSize = clsx({
    "text-xl sm:text-2xl": size === "s",
    "text-xl sm:text-4xl-deprecate": size === "m",
    "text-4xl-deprecate sm:text-[2rem]": size === "l",
  });
  const phaseSize = clsx({
    "h-10 w-[24px]": size === "s",
    "h-[19px] w-[28px]": size === "m",
    "h-[22px] w-[34px]": size === "l",
  });
  return (
    <a {...linkProps} {...(href ? { href } : {})}>
      <div className={`flex flex-row ${logoStyle} tracking-tighter`}>
        <div className={`${logoFontSize} font-bold`} translate="no">
          Sembly
        </div>
        {withBetaText && <BetaIcon className={phaseSize} />}
      </div>
    </a>
  );
};

Logo.displayName = "Logo";

export default Logo;
