import React, { useEffect, useState } from "react";

export type NoSSRProps = React.PropsWithChildren<{
  ssrFallback?: JSX.Element;
}>;

const defaultSSRFallback = <React.Fragment />;

const NoSSR: React.FC<React.PropsWithChildren<NoSSRProps>> = (props: NoSSRProps) => {
  const { ssrFallback = defaultSSRFallback } = props;
  const [canRender, toggleRender] = useState(false);
  useEffect(() => {
    toggleRender(true);
  }, []);

  return canRender ? <>{props.children}</> : <>{ssrFallback}</>;
};

NoSSR.displayName = "NoSSR";
export default NoSSR;
