export const TaskHistoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.012 4.25C7.359 2.956 8.585 2 10.044 2h2.086c1.46 0 2.685.956 3.032 2.25h1.142c1.585 0 2.87 1.231 2.87 2.75v3.343a8.327 8.327 0 0 0-1.565-.771V7c0-.69-.584-1.25-1.305-1.25h-1.043V7c0 .552-.467 1-1.044 1h-6.26c-.577 0-1.044-.448-1.044-1V5.75H5.87c-.72 0-1.305.56-1.305 1.25v11c0 1.243 1.051 2.25 2.348 2.25h.297c.26.535.582 1.038.956 1.5H6.913C4.752 21.75 3 20.071 3 18V7c0-1.519 1.285-2.75 2.87-2.75h1.142Zm3.032-.75h2.086c.865 0 1.566.672 1.566 1.5v1.5H8.478V5c0-.828.701-1.5 1.566-1.5Z"
        fill="currentColor"
      />
      <path
        d="M7.54 16.25h.987c.385-2.96 3.02-5.25 6.212-5.25C18.197 11 21 13.686 21 17s-2.803 6-6.26 6a6.429 6.429 0 0 1-3.48-1.01.731.731 0 0 1-.215-1.041.804.804 0 0 1 1.086-.206 4.816 4.816 0 0 0 2.608.757c2.593 0 4.696-2.015 4.696-4.5s-2.102-4.5-4.696-4.5c-2.327 0-4.258 1.622-4.63 3.75h.874c.258 0 .405.282.25.48l-1.722 2.2a.321.321 0 0 1-.5 0l-1.722-2.2c-.155-.198-.008-.48.25-.48Z"
        fill="currentColor"
      />
      <path
        d="M15.326 19.235c.25.337.74.416 1.092.176a.73.73 0 0 0 .184-1.046l-1.08-1.455v-2.16c0-.414-.35-.75-.783-.75-.432 0-.783.336-.783.75v2.64l1.37 1.845Z"
        fill="currentColor"
      />
    </svg>
  );
};
