export const MoreHorizontalIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 32 32" {...props}>
      <g stroke="currentColor" strokeWidth=".8" clipPath="url(#MoreHorizontalIcon_svg__clip0)">
        <circle cx="10.353" cy="16.647" r="1.953" transform="rotate(-90 10.353 16.647)" />
        <circle cx="16" cy="16.647" r="1.953" transform="rotate(-90 16 16.647)" />
        <path d="M21.647 14.694a1.953 1.953 0 1 1 0 3.906 1.953 1.953 0 0 1 0-3.906Z" />
      </g>
    </svg>
  );
};
