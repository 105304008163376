import * as Sentry from "@sentry/react";
import type { CaptureContext } from "@sentry/types";

export function captureException(error: Error, captureContext?: CaptureContext) {
  // eslint-disable-next-line no-console
  console.error(error.message, captureContext);
  Sentry.captureException(error, captureContext || undefined);
}

export function captureMessage(msg: string, captureContext?: CaptureContext) {
  // eslint-disable-next-line no-console
  console.error(msg, captureContext);
  Sentry.captureMessage(msg, captureContext || undefined);
}
