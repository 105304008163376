export const EmojiIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 2.292a7.708 7.708 0 1 0 0 15.416 7.708 7.708 0 0 0 0-15.416ZM1.042 10a8.958 8.958 0 1 1 17.916 0 8.958 8.958 0 0 1-17.916 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.2 13.733c-2.333 0-3.75-1.833-3.833-1.916-.167-.25-.167-.667.083-.834.25-.166.667-.166.833.084 0 0 1.084 1.416 2.834 1.416s2.833-1.416 2.833-1.416c.167-.25.583-.334.833-.084.25.167.334.584.084.834.083 0-1.334 1.916-3.667 1.916Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.875 7.5c0-.345.28-.625.625-.625h.008a.625.625 0 1 1 0 1.25H12.5a.625.625 0 0 1-.625-.625Zm-5 0c0-.345.28-.625.625-.625h.008a.625.625 0 1 1 0 1.25H7.5a.625.625 0 0 1-.625-.625Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
