export const TickOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.5 12a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0ZM12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.398 8.364a.75.75 0 0 1 .238 1.034L12.03 15.16a.95.95 0 0 1-1.544.095l-2.07-2.552a.75.75 0 1 1 1.166-.945l1.584 1.954 3.197-5.11a.75.75 0 0 1 1.034-.238Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
