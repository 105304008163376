export const ChatBubbleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="16" fill="none" viewBox="0 0 14 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.19 14.749c0 .268.302.427.523.274l4.24-2.928h4.38c.737 0 1.334-.597 1.334-1.333V2c0-.736-.597-1.333-1.334-1.333H1.667C.93.667.333 1.264.333 2v8.762c0 .736.597 1.333 1.334 1.333H3.19v2.654Zm3.966-7.682a.867.867 0 1 0 0-1.734.867.867 0 0 0 0 1.734ZM5.133 6.2a.867.867 0 1 1-1.733 0 .867.867 0 0 1 1.733 0Zm4.911.867a.867.867 0 1 0 0-1.734.867.867 0 0 0 0 1.734Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
