export const NodeContributorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="15" height="15" fill="none" viewBox="0 0 15 15" {...props}>
      <path
        fill="currentColor"
        d="M7.167 7.316a3.5 3.5 0 0 0 3.5-3.5 3.499 3.499 0 1 0-7 0c0 1.934 1.566 3.5 3.5 3.5Zm0 1.75c-2.337 0-7 1.173-7 3.5v1.75h14v-1.75c0-2.327-4.664-3.5-7-3.5Z"
      />
    </svg>
  );
};
