import type { SVGProps } from "react";

export const LoadingSpinnerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M12.002 18.744c.386 0 .698.312.698.698v1.86a.697.697 0 1 1-1.395 0v-1.86c0-.386.311-.698.697-.698ZM12.002 1.998c.386 0 .698.312.698.698v1.86a.697.697 0 1 1-1.395 0v-1.86c0-.386.311-.698.697-.698ZM14.921 3.138a.7.7 0 0 1 .912-.381.698.698 0 0 1 .377.911l-.712 1.721a.698.698 0 0 1-1.288-.535l.712-1.716ZM18.079 4.931a.701.701 0 0 1 .493-.204c.177 0 .358.07.493.204a.693.693 0 0 1 0 .986l-1.316 1.317a.697.697 0 0 1-.986-.986l1.316-1.317ZM3.142 14.917l1.72-.712a.7.7 0 0 1 .535 1.293l-1.72.712a.7.7 0 0 1-.535-1.293ZM19.149 9.794a.698.698 0 0 1-.535-1.288l1.72-.712a.7.7 0 0 1 .535 1.293l-1.72.707ZM5.256 12.002a.697.697 0 0 1-.698.697h-1.86a.697.697 0 1 1 0-1.395h1.86c.386 0 .698.312.698.697ZM18.746 12.002c0-.386.312-.698.698-.698h1.86a.697.697 0 1 1 0 1.395h-1.86a.697.697 0 0 1-.698-.697ZM3.677 7.794l1.72.712a.698.698 0 0 1-.535 1.288l-1.72-.712a.7.7 0 0 1 .27-1.344.797.797 0 0 1 .265.056ZM18.238 14.58a.7.7 0 0 1 .912-.382l1.72.712a.698.698 0 0 1-.535 1.288l-1.72-.711a.69.69 0 0 1-.377-.907ZM4.928 4.93a.702.702 0 0 1 .494-.206c.176 0 .358.07.493.205L7.23 6.245a.698.698 0 0 1-.986.986L4.928 5.915a.693.693 0 0 1 0-.986ZM17.257 16.564c.177 0 .359.07.493.205l1.317 1.316a.698.698 0 0 1-.986.986l-1.317-1.316a.693.693 0 0 1 0-.986.71.71 0 0 1 .493-.205ZM8.165 2.762a.7.7 0 0 1 .912.381l.711 1.722A.698.698 0 0 1 8.5 5.4l-.712-1.721a.7.7 0 0 1 .377-.917ZM14.587 18.233a.7.7 0 0 1 .912.381l.712 1.722a.698.698 0 0 1-1.289.534l-.712-1.72a.696.696 0 0 1 .377-.917Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 24h24V0H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
