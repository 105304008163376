export const BadgeChampionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M19.837 5.72h-2.433c.055-.734.044-1.196.044-1.196H6.498c0 .42.012.818.033 1.194H4.163s-.227 4.393 4.381 6.945c.677.921 1.418 1.455 2.034 1.76a.347.347 0 0 0-.006.069v.661c0 .208.17.378.378.378h.215v.763h-.6a1.263 1.263 0 0 0-1.267 1.268h5.347c0-.7-.567-1.268-1.268-1.268h-.6v-.763h.216c.208 0 .377-.17.377-.378v-.662c0-.008 0-.019-.002-.028a4.948 4.948 0 0 0 1.572-1.207c.149-.17.29-.348.42-.534 4.71-2.549 4.477-7.002 4.477-7.002ZM5.454 8.367a7.465 7.465 0 0 1-.528-1.915h1.649c.16 2.067.59 3.627 1.129 4.801-1-.796-1.755-1.755-2.25-2.886Zm13.114-.064c-.495 1.179-1.271 2.176-2.32 2.984.685-1.663.988-3.485 1.122-4.841h1.7a7.091 7.091 0 0 1-.502 1.857Z"
      />
      <path fill="#C1B1FF" d="M16.495 21.256v.569H7.447v-.569h.586v-3.694h7.877v3.694h.586Z" />
      <path fill="#ADABFF" d="M17.868 3.975H6.075a.273.273 0 1 0 0 .546h11.793a.273.273 0 1 0 0-.546Z" />
      <path fill="#fff" d="M15.096 18.137H8.847v2.543h6.249v-2.543Z" />
      <path fill="#C1B1FF" d="M6.747 4.741s.326 9.137 4.889 9.374c0 0-3.96-2.421-3.603-9.374H6.747Z" />
      <path
        fill="#ADABFF"
        d="M13.37 14.459c-.861.386-1.947.382-2.8-.038v.246s1.277.684 2.8 0v-.208Zm-.592 1.072h-1.614v.763h1.614v-.763Z"
      />
      <path
        fill="#C1B1FF"
        d="m15.681 5.232.572 1.044 1.044.571-1.044.57-.572 1.046-.571-1.046-1.044-.57 1.043-.571.572-1.044Zm-1.372 2.782.25.457.458.25-.458.249-.25.457-.25-.457-.457-.249.457-.25.25-.457Z"
      />
    </svg>
  );
};
