export const ChangeTypeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="M12.514 9.257a5.657 5.657 0 1 1-11.314 0 5.657 5.657 0 0 1 11.314 0Z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.403 18.5 16.5 10.002 11.597 18.5h9.806Zm-3.604-9.248a1.5 1.5 0 0 0-2.598 0l-4.903 8.498a1.5 1.5 0 0 0 1.3 2.25h9.805a1.5 1.5 0 0 0 1.3-2.25l-4.904-8.498Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
