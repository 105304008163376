export const ArrowSubRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 4.5a.75.75 0 0 0-1.5 0V14c0 .414.336.75.75.75h10.69l-3.72 3.72a.75.75 0 1 0 1.06 1.06l5-5a.748.748 0 0 0 0-1.06l-5-5a.75.75 0 1 0-1.06 1.06l3.72 3.72H6.75V4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
