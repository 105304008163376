export const MemberScreeningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 9c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm0 8.55a13.156 13.156 0 0 0-8-3.513c-.552-.041-1 .41-1 .963v9c0 .552.448.996 1 1.037a13.136 13.136 0 0 1 7.298 2.903c.408.33.997.33 1.405.001a13.186 13.186 0 0 1 7.298-2.903c.55-.042.999-.486.999-1.038V9c0-.552-.448-1.004-1-.963a13.156 13.156 0 0 0-8 3.513Zm7 5.58c-2.53.34-4.93 1.3-7 2.82a15.2 15.2 0 0 0-7-2.83v-6.95c2.1.38 4.05 1.35 5.64 2.83l.677.637a1 1 0 0 0 1.368.003l.675-.63A11.18 11.18 0 0 1 19 10.18v6.95Z"
      />
    </svg>
  );
};
