export const ArrowRightUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.391 14.609a.625.625 0 0 1 0-.884l8.334-8.334a.625.625 0 0 1 .884.884L6.275 14.61a.625.625 0 0 1-.884 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.167 14.792a.625.625 0 0 1-.625-.625V6.458H5.833a.625.625 0 0 1 0-1.25h8.334c.345 0 .625.28.625.625v8.334c0 .345-.28.625-.625.625Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
