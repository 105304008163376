export const BadgeWordsmithIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#2A0FCE"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0a12.012 12.012 0 0 1 10.123 5.556A11.939 11.939 0 0 1 24 12Z"
      />
      <path
        fill="#ADABFF"
        d="M22.123 5.556 17.75 9.929l-1.857 4.806a.336.336 0 0 1-.197.195L5.683 18.773a.341.341 0 0 1-.44-.442l3.84-10.013a.343.343 0 0 1 .196-.197l4.9-1.891 4.318-4.317a12.012 12.012 0 0 1 3.624 3.643h.002Z"
      />
      <path
        fill="#fff"
        d="m16.988 10.004-1.678 4.342-8.583 3.294 4.871-4.87a1.158 1.158 0 0 0 1.443-1.797 1.159 1.159 0 0 0-1.797 1.442l-4.866 4.867 3.291-8.578 4.341-1.677 2.978 2.977Z"
      />
    </svg>
  );
};
