export const CrossFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M8 .5A7.493 7.493 0 0 0 .5 8c0 4.148 3.353 7.5 7.5 7.5 4.148 0 7.5-3.352 7.5-7.5C15.5 3.853 12.148.5 8 .5Zm3.225 10.725a.747.747 0 0 1-1.057 0L8 9.057l-2.168 2.168a.747.747 0 1 1-1.057-1.057L6.942 8 4.776 5.832a.747.747 0 1 1 1.057-1.057L8 6.942l2.168-2.167a.747.747 0 1 1 1.057 1.057L9.057 8l2.168 2.168a.76.76 0 0 1 0 1.057Z"
      />
    </svg>
  );
};
