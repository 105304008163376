export const ResourceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667.833C.93.833.333 1.43.333 2.167v10.666c0 .737.597 1.334 1.334 1.334h10.666c.737 0 1.334-.597 1.334-1.334v-8.39a.667.667 0 0 0-.195-.472L10.529 1.03a.667.667 0 0 0-.472-.196h-8.39Zm0 12.334h10.666c.185 0 .334-.15.334-.334v-7.5h-2.333a1.167 1.167 0 0 1-1.167-1.166V1.833h-7.5a.333.333 0 0 0-.334.334v10.666c0 .184.15.334.334.334Zm8.5-11.086 2.252 2.252h-2.085a.167.167 0 0 1-.167-.166V2.08Zm-6.58 2.006a1.437 1.437 0 0 1 2.033 0l1.369 1.37a.5.5 0 1 0 .707-.707l-1.37-1.37a2.437 2.437 0 0 0-3.445 3.446l1.369 1.37a.5.5 0 0 0 .707-.707l-1.37-1.37a1.437 1.437 0 0 1 0-2.032Zm2.314 1.607a.5.5 0 1 0-.707.707L8.1 9.306a.5.5 0 1 0 .707-.707L5.901 5.694Zm3.85 1.11a.5.5 0 1 0-.708.707l1.37 1.37a1.437 1.437 0 1 1-2.032 2.031L7.01 9.543a.5.5 0 0 0-.707.707l1.37 1.37a2.437 2.437 0 0 0 3.446-3.446l-1.37-1.37Z"
        fill="currentColor"
      />
    </svg>
  );
};
