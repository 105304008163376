export const ColorfulGlobeIconWithLock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.547 10.65a6.75 6.75 0 1 0-8.185 4.9 2.372 2.372 0 0 1-.05-.482v-.7A5.628 5.628 0 0 1 3.413 8.34L6 11.25v.375c0 .299.087.577.238.81.13.205.294.406.458.61.229.282.46.57.617.874v-1.237c0-.983.608-1.865 1.505-2.212a3.493 3.493 0 0 1 .406-1.453A1.512 1.512 0 0 0 9 9H5.25V7.5h1.5a.75.75 0 0 0 .75-.75V6H9a1.5 1.5 0 0 0 1.5-1.5v-.923a5.628 5.628 0 0 1 4.12 5.177c.338.514.528 1.11.562 1.716.128.05.25.11.365.18Z"
        fill="url(#restricted__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.938 11.438v-.771c0-.573.204-1.134.583-1.555A1.99 1.99 0 0 1 12 8.437a1.99 1.99 0 0 1 1.479.675c.38.421.584.982.584 1.555v.77h.27c.691 0 1.23.57 1.23 1.245v2.386c0 .675-.539 1.245-1.23 1.245H9.667c-.691 0-1.23-.57-1.23-1.245v-2.386c0-.675.539-1.245 1.23-1.245h.27Zm1.42-1.574A.866.866 0 0 1 12 9.562c.228 0 .461.1.643.302.183.204.294.492.294.803v.77h-1.874v-.77c0-.311.111-.599.294-.803Z"
        fill="#949495"
      />
      <defs>
        <linearGradient id="restricted__a" x1="2.524" y1="8.9" x2="16.38" y2="12.895" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
