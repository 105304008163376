export const RocketIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="25" fill="none" viewBox="0 0 21 25" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.934 23.063 21 25l-3.084-7.837a25.071 25.071 0 0 1-1.982 5.9ZM0 25l5.066-1.938a25.068 25.068 0 0 1-1.982-5.9L0 25Zm17.063-12.5C17.063 2.5 10.5 0 10.5 0S3.937 2.5 3.937 12.5c0 3.875.998 7.188 2.192 9.788.434.9 1.365 1.462 2.402 1.462h3.938c1.037 0 1.969-.563 2.402-1.462 1.207-2.6 2.191-5.913 2.191-9.788Zm-5.665-4.736-.422-1.3a.5.5 0 0 0-.952 0l-.422 1.3a.5.5 0 0 1-.476.345H7.76a.5.5 0 0 0-.294.905l1.106.804a.5.5 0 0 1 .182.559l-.423 1.3a.5.5 0 0 0 .77.56l1.106-.805a.5.5 0 0 1 .588 0l1.106.804a.5.5 0 0 0 .77-.559l-.423-1.3a.5.5 0 0 1 .182-.56l1.106-.803a.5.5 0 0 0-.294-.905h-1.367a.5.5 0 0 1-.476-.345Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
