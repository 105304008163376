export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="7.5" fill="currentColor" stroke="currentColor" />
      <path
        fill="#fff"
        d="M6.382 13a.646.646 0 0 1-.48-.22l-2.708-3A.794.794 0 0 1 3 9.251c0-.197.072-.387.198-.527a.646.646 0 0 1 .476-.22c.179 0 .35.077.478.216l2.204 2.44 5.617-6.912a.653.653 0 0 1 .468-.247c.18-.01.355.058.489.192a.788.788 0 0 1 .223.518.8.8 0 0 1-.173.54l-6.095 7.5a.686.686 0 0 1-.22.18A.624.624 0 0 1 6.4 13h-.017Z"
      />
    </svg>
  );
};
