export const StarFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M11.098 3.542a1.022 1.022 0 0 1 1.804 0l2.473 4.437 4.801 1.087c.782.177 1.09 1.17.558 1.793l-3.274 3.83.495 5.108c.08.832-.727 1.446-1.46 1.109L12 18.835l-4.496 2.07c-.732.338-1.54-.276-1.46-1.108l.496-5.109-3.274-3.829c-.533-.623-.224-1.616.558-1.793l4.801-1.087 2.473-4.437z"
      />
    </svg>
  );
};
