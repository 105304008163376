export const TocIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M8 5.75A.75.75 0 0 1 8.75 5h10.5a.75.75 0 0 1 0 1.5H8.75A.75.75 0 0 1 8 5.75Zm0 6a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H8.75a.75.75 0 0 1-.75-.75Zm0 6a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H8.75a.75.75 0 0 1-.75-.75Zm-4-12a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 6a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 6a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Z"
      />
    </svg>
  );
};
