export const SubsectionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.5 5.5v4h13v-4h-13ZM5 4a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5Zm3.5 10.5v4h10v-4h-10ZM8 13a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H8Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M4 13h1.5v7H4v-7Z" />
    </svg>
  );
};
