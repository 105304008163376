export const BookmarkSingleFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 20.5a1 1 0 0 1-1.6.8l-5.8-4.35a1 1 0 0 0-1.2 0L5.6 21.3a1 1 0 0 1-1.6-.8v-17a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v17Z"
        fill="currentColor"
      />
    </svg>
  );
};
