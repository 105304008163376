// Using js so can import this on the talinwid.config.js without compiling

// Using rem (base 16) instead of px so it will respect user browser size
const spacing = {
  0: "0",
  1: "1px",
  2: "0.125rem",
  3: "0.1875rem",
  4: "0.25rem",
  5: "0.313rem",
  6: "0.375rem",
  7: "0.4375rem",
  8: "0.5rem",
  9: "0.5625rem",
  10: "0.625rem",
  12: "0.75rem",
  14: "0.875rem",
  16: "1rem",
  18: "1.125rem",
  20: "1.25rem",
  22: "1.375rem",
  24: "1.5rem",
  26: "1.625rem",
  28: "1.75rem",
  30: "1.875rem",
  32: "2rem",
  34: "2.125rem",
  36: "2.25rem",
  38: "2.375rem",
  40: "2.5rem",
  42: "2.625rem",
  44: "2.75rem",
  46: "2.875rem",
  48: "3rem",
  50: "3.125rem",
  52: "3.25rem",
  56: "3.5rem",
  58: "3.625rem",
  60: "3.75rem",
  64: "4rem",
  80: "5rem",
  96: "6rem",
  112: "7rem",
  128: "8rem",
  "1/8": "12.5%",
};

module.exports = {
  spacing,
};
