export const ArrowBigRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="33" height="32" fill="none" viewBox="0 0 33 32" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.167 16a1 1 0 0 1 1-1h18.667a1 1 0 0 1 0 2H7.167a1 1 0 0 1-1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.793 5.96a1 1 0 0 1 1.414 0l9.333 9.333a1 1 0 0 1 0 1.414l-9.333 9.333a1 1 0 1 1-1.414-1.414L24.419 16l-8.626-8.626a1 1 0 0 1 0-1.414Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
