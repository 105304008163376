export const PioneerScreeningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="18" fill="none" viewBox="0 0 16 18" {...props}>
      <path
        fill="currentColor"
        d="m11.82 4.22-1 .4c-.21-.16-.43-.29-.67-.39L10 3.17c-.02-.1-.1-.17-.2-.17H8.2c-.1 0-.18.07-.19.17l-.15 1.06c-.24.1-.47.23-.67.39l-1-.4c-.09-.03-.2 0-.24.09l-.8 1.38a.2.2 0 0 0 .05.26l.85.66C6.02 6.73 6 6.87 6 7s.01.26.03.39l-.84.66a.19.19 0 0 0-.05.25l.8 1.39c.05.09.15.12.25.09l.99-.4c.21.16.43.29.68.39L8 10.83c.02.1.1.17.2.17h1.6c.1 0 .18-.07.2-.17l.15-1.06c.24-.1.47-.23.67-.39l.99.4c.09.04.2 0 .24-.09l.8-1.39c.05-.09.03-.19-.05-.25l-.83-.66a2.561 2.561 0 0 0 0-.78l.85-.66a.2.2 0 0 0 .05-.26l-.8-1.38c-.05-.09-.16-.12-.25-.09ZM9 8.43a1.43 1.43 0 1 1 0-2.86 1.43 1.43 0 0 1 0 2.86Z"
      />
      <path
        fill="currentColor"
        d="M15.94 6.06C15.51 2.79 12.71.2 9.41.01 9.27 0 9.14 0 9 0 5.47 0 2.57 2.61 2.08 6L.15 9.48C-.26 10.14.22 11 1 11h1v2c0 1.1.9 2 2 2h1v3h7v-4.68a7.016 7.016 0 0 0 3.94-7.26Zm-5.05 5.57-.89.42V16H7v-3H4V9H2.7l1.33-2.33C4.21 4.06 6.35 2 9 2c2.76 0 5 2.24 5 5 0 2.09-1.29 3.88-3.11 4.63Z"
      />
    </svg>
  );
};
