export const RevisionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 4.75A7.25 7.25 0 0 0 5.75 12a.75.75 0 0 1-1.5 0 8.75 8.75 0 1 1 3.888 7.276.75.75 0 1 1 .835-1.246A7.25 7.25 0 1 0 13 4.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M7.793 12H2.207a.5.5 0 0 0-.353.854l2.792 2.792a.5.5 0 0 0 .708 0l2.792-2.792A.5.5 0 0 0 7.793 12Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 8.25a.75.75 0 0 1 .75.75v3.69l1.78 1.78a.75.75 0 1 1-1.06 1.06l-2.22-2.22V9a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
