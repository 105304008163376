export const ChevronBigTallLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="56" viewBox="0 0 20 56" fill="none" {...props}>
      <path
        d="M19.6339 2.27614C20.122 1.75544 20.122 0.911223 19.6339 0.390524C19.1457 -0.130175 18.3543 -0.130175 17.8661 0.390524L0.366116 27.0572C-0.122039 27.5779 -0.122039 28.4221 0.366116 28.9428L17.8661 55.6095C18.3543 56.1302 19.1457 56.1302 19.6339 55.6095C20.122 55.0888 20.122 54.2446 19.6339 53.7239L3.01777 28L19.6339 2.27614Z"
        fill="currentColor"
      />
    </svg>
  );
};
