export const GlobeIconWithLock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 9C15.75 9.56909 15.6796 10.1217 15.547 10.6497C15.4318 10.5797 15.3098 10.5193 15.182 10.4697C15.1481 9.86411 14.9584 9.26788 14.6197 8.75394C14.513 6.27551 12.8026 4.21273 10.5 3.57721V4.49999C10.5 5.32842 9.82843 6 9 6H7.5V6.75C7.5 7.16421 7.16421 7.5 6.75 7.5H5.25V9H9C9.07607 9 9.15082 9.00566 9.22385 9.01659C8.98287 9.46626 8.84623 9.96472 8.818 10.4697C7.92139 10.8173 7.3125 11.6988 7.3125 12.6818V13.9193C7.15684 13.6145 6.92466 13.3272 6.69586 13.0441L6.69585 13.0441C6.53151 12.8407 6.36892 12.6396 6.23768 12.4356C6.08726 12.2019 6 11.9236 6 11.625V11.25L3.41331 8.33997C3.38801 8.55647 3.375 8.77671 3.375 9C3.375 11.5187 5.03038 13.6507 7.3125 14.3675V15.0682C7.3125 15.2322 7.32946 15.3935 7.36189 15.5499C4.42554 14.8179 2.25 12.163 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9ZM9.9375 10.6667V11.4375H9.66667C8.97597 11.4375 8.4375 12.0066 8.4375 12.6818V15.0682C8.4375 15.7434 8.97597 16.3125 9.66667 16.3125H14.3333C15.024 16.3125 15.5625 15.7434 15.5625 15.0682V12.6818C15.5625 12.0066 15.024 11.4375 14.3333 11.4375H14.0625V10.6667C14.0625 10.0935 13.8581 9.53336 13.4788 9.11186C13.0977 8.68841 12.5673 8.4375 12 8.4375C11.4327 8.4375 10.9023 8.68841 10.5212 9.11186C10.1419 9.53336 9.9375 10.0935 9.9375 10.6667ZM12 9.5625C11.7716 9.5625 11.5389 9.66278 11.3574 9.86445C11.1742 10.0681 11.0625 10.3558 11.0625 10.6667V11.4375H12.9375V10.6667C12.9375 10.3558 12.8258 10.0681 12.6426 9.86445C12.4611 9.66278 12.2284 9.5625 12 9.5625Z"
        fill="currentColor"
      />
    </svg>
  );
};
