export const ArrowBigUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.47 12.53a.75.75 0 1 0 1.06-1.06l-7-7a.748.748 0 0 0-1.059-.002l-.001.002m0 0-7 7a.75.75 0 1 0 1.06 1.06l5.72-5.72V19a.75.75 0 0 0 1.5 0V6.81l5.72 5.72"
        fill="currentColor"
      />
    </svg>
  );
};
