import React, { useId } from "react";

export const RefineHistoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
      <g clipPath={`url(#${id1})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.737 1.679a.37.37 0 0 0 .305-.357.326.326 0 0 0-.38-.329 4.558 4.558 0 0 0-1.756.728.326.326 0 0 0-.036.501.37.37 0 0 0 .468.037c.416-.279.89-.479 1.399-.58ZM10.083 5.5a4.585 4.585 0 0 1-3.745 4.507.326.326 0 0 1-.38-.33.37.37 0 0 1 .305-.356 3.897 3.897 0 0 0 0-7.642.37.37 0 0 1-.305-.357c0-.205.178-.366.38-.329A4.585 4.585 0 0 1 10.083 5.5Zm-8.76-.458a.37.37 0 0 0 .356-.305c.1-.51.301-.982.58-1.4a.37.37 0 0 0-.037-.467.326.326 0 0 0-.501.036 4.558 4.558 0 0 0-.728 1.757.326.326 0 0 0 .33.379Zm1.474 3.685a.37.37 0 0 1 .473-.032c.433.303.93.52 1.467.626a.37.37 0 0 1 .305.357.326.326 0 0 1-.38.33 4.56 4.56 0 0 1-1.835-.784.326.326 0 0 1-.03-.497Zm-.583-1.133a.37.37 0 0 1-.042.461.326.326 0 0 1-.505-.04 4.557 4.557 0 0 1-.674-1.677.326.326 0 0 1 .33-.38.37.37 0 0 1 .356.305c.095.482.28.93.535 1.33ZM6.715 3.96a.248.248 0 0 1 .27.404L4.542 6.81l-.482.132.131-.483 2.445-2.445a.248.248 0 0 1 .08-.053Zm.095-.57a.798.798 0 0 0-.564.235L3.749 6.122a.275.275 0 0 0-.07.122L3.4 7.261a.275.275 0 0 0 .338.338l1.017-.278a.275.275 0 0 0 .122-.07l2.497-2.497a.798.798 0 0 0-.565-1.362Z"
          fill={`url(#${id2})`}
        />
      </g>
      <defs>
        <linearGradient id={id2} x1=".988" y1="10.012" x2="10.083" y2="10.012" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9795D7" />
          <stop offset="1" stopColor="#99C1FF" />
        </linearGradient>
        <clipPath id={id1}>
          <path fill="#fff" d="M0 0h11v11H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
