export const ContributorScreeningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2.286 0a1.714 1.714 0 1 1-3.428 0 1.714 1.714 0 0 1 3.428 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M4.036 17.876c.01.04.02.082.032.124H8v2H3.143C2.512 20 2 18.345 2 17.714c0-.99.505-1.774 1.118-2.34.605-.558 1.388-.979 2.172-1.293 1.566-.626 3.425-.938 4.71-.938.503 0 1.094.047 1.72.143l-1.869 1.858c-1.036.024-2.555.29-3.818.794-.662.265-1.2.575-1.559.906-.344.318-.466.598-.474.846l.003.018c.005.039.016.096.033.168Z"
      />
      <path
        fill="currentColor"
        d="M9.146 17.145A.5.5 0 0 0 9 17.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .354-.146l9.102-9.103-1.354-1.354-1.354-1.354-9.102 9.102Zm1.963 1.41h-.665v-.664l7.315-7.316.665.664-7.315 7.317ZM21.789 8.9l-1.69-1.69a.709.709 0 0 0-.513-.21.71.71 0 0 0-.505.21l-.833.833 1.354 1.354 1.354 1.354.833-.833a.72.72 0 0 0 0-1.019Z"
      />
    </svg>
  );
};
