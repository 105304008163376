export const HistoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M5 12a8 8 0 1 1 3.556 6.653" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M7.793 12H2.207a.5.5 0 0 0-.353.854l2.792 2.792a.5.5 0 0 0 .708 0l2.792-2.792A.5.5 0 0 0 7.793 12Z"
        fill="currentColor"
      />
      <path d="M13 9v4l2 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
