export const BellIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.313 13.5a1.687 1.687 0 1 0 3.375 0h1.124a2.813 2.813 0 0 1-5.624 0h1.125Zm6.037-2.325a1.125 1.125 0 0 1-.225-.675V6.75a4.125 4.125 0 1 0-8.25 0v3.75c0 .243-.079.48-.225.675l-.9 1.2h10.5l-.9-1.2Zm.9-4.425v3.75l1.35 1.8a.75.75 0 0 1-.6 1.2H3a.75.75 0 0 1-.6-1.2l1.35-1.8V6.75a5.25 5.25 0 0 1 10.5 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
