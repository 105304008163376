export const ArrowRightFillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="26" height="24" fill="none" viewBox="0 0 26 24" {...props}>
      <rect width="25.102" height="24" x=".898" fill="currentColor" rx="12" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.114 18.707c.409.39 1.071.39 1.48 0l5.916-5.657a.97.97 0 0 0 0-1.414l-5.916-5.657a1.08 1.08 0 0 0-1.48 0 .97.97 0 0 0 0 1.414l4.139 3.957H6.082a1 1 0 1 0 0 2h12.156l-4.124 3.943a.97.97 0 0 0 0 1.414Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
