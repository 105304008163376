export const RepliesIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5.455 4.636a.818.818 0 0 0-.819.818v9.816c0 .452.367.818.819.818h.818c.452 0 .818.366.818.818v1.716l1.82-2.233a.818.818 0 0 1 .634-.3h9a.818.818 0 0 0 .819-.819V5.454a.818.818 0 0 0-.819-.818H5.456ZM3 5.454A2.454 2.454 0 0 1 5.455 3h13.09A2.454 2.454 0 0 1 21 5.454v9.816a2.454 2.454 0 0 1-2.454 2.454H9.934l-2.301 2.822c-.73.895-2.178.379-2.178-.775v-2.047A2.454 2.454 0 0 1 3 15.27V5.454Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
