export const LightbulbIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.127 4.888a4.688 4.688 0 0 0-4.254 1.053A4.824 4.824 0 0 0 7.516 7.98a4.885 4.885 0 0 0-.189 2.454 4.85 4.85 0 0 0 1.025 2.222 6.82 6.82 0 0 1 1.591 3.595h4.126a7.407 7.407 0 0 1 1.631-3.662 4.76 4.76 0 0 0 1.05-3.01v-.005c0-.717-.157-1.425-.46-2.072a4.811 4.811 0 0 0-1.293-1.67 4.727 4.727 0 0 0-1.87-.943ZM10.734 3.38a6.188 6.188 0 0 1 2.755.052 6.228 6.228 0 0 1 2.463 1.243 6.312 6.312 0 0 1 1.696 2.189c.396.846.602 1.771.602 2.707a6.26 6.26 0 0 1-1.386 3.964l-.008.009a5.915 5.915 0 0 0-1.358 3.493l-.036.463H8.508l-.012-.487a5.326 5.326 0 0 0-1.286-3.385l-.005-.007-.006-.006A6.35 6.35 0 0 1 5.851 10.7a6.385 6.385 0 0 1 .247-3.209 6.324 6.324 0 0 1 1.78-2.67 6.23 6.23 0 0 1 2.856-1.44Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M11.25 12.75a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-1.5 0v-3.5Z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.15 17.5H10V19a.5.5 0 0 0 .5.5h3.15a.5.5 0 0 0 .5-.5v-1.5ZM8.5 16v3a2 2 0 0 0 2 2h3.15a2 2 0 0 0 2-2v-3H8.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
