export const MinimizeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20 11.75a.75.75 0 0 1-.75.75H4.75a.75.75 0 0 1 0-1.5h14.5a.75.75 0 0 1 .75.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
