export const RefineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#Refine_svg__a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M11.71 6.945a.95.95 0 1 1 1.345 1.345l-4.54 4.539-1.849.504.505-1.849 4.539-4.539Z"
        />
        <path
          fill="currentColor"
          d="M17.596 10.834c.373 0 .666.322.599.689a8.288 8.288 0 0 1-1.324 3.194c-.211.308-.647.328-.911.064a.673.673 0 0 1-.067-.85 7.042 7.042 0 0 0 1.055-2.543.673.673 0 0 1 .648-.554Zm-3.664 5.059a.673.673 0 0 1 .85.067c.263.264.243.7-.065.911a8.289 8.289 0 0 1-3.194 1.324c-.367.067-.69-.226-.69-.599 0-.32.24-.586.555-.648a7.041 7.041 0 0 0 2.544-1.055Zm.832-11.858c.268-.263.25-.703-.061-.915a8.288 8.288 0 0 0-3.18-1.314c-.367-.068-.69.225-.69.598 0 .32.24.586.555.649.92.183 1.777.544 2.531 1.046a.673.673 0 0 0 .845-.064Zm2.097 1.235c-.21-.306-.642-.328-.906-.068a.673.673 0 0 0-.07.855c.51.76.878 1.625 1.063 2.556a.674.674 0 0 0 .648.554c.373 0 .666-.323.599-.69A8.288 8.288 0 0 0 16.86 5.27ZM2.404 9.167c.32 0 .586-.24.649-.554a7.044 7.044 0 0 1 1.054-2.544.673.673 0 0 0-.067-.85c-.263-.264-.7-.243-.91.064a8.288 8.288 0 0 0-1.324 3.195c-.068.366.225.689.598.689ZM5.283 3.13c-.307.21-.328.647-.064.91a.673.673 0 0 0 .85.067 7.043 7.043 0 0 1 2.544-1.054.673.673 0 0 0 .554-.649c0-.373-.323-.666-.69-.598a8.288 8.288 0 0 0-3.194 1.323Zm-2.23 8.258a.673.673 0 0 0-.649-.554c-.373 0-.666.322-.598.689a8.285 8.285 0 0 0 1.234 3.062c.207.313.646.34.914.076a.673.673 0 0 0 .079-.843 7.042 7.042 0 0 1-.98-2.43Zm2.903 4.428a.673.673 0 0 0-.855.056c-.264.26-.25.691.051.907a8.29 8.29 0 0 0 3.326 1.416c.366.067.689-.226.689-.599 0-.32-.24-.586-.554-.648a7.045 7.045 0 0 1-2.657-1.132Z"
        />
      </g>
      <defs>
        <clipPath id="Refine_svg__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
