import type { SVGProps } from "react";

export function NewDragHandleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M7.333 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM10.667 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.333 8.333a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM10.667 8.333a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.333 11.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM10.667 11.667a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      />
    </svg>
  );
}
