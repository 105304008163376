export const ReorderIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="12" fill="none" viewBox="0 0 17 12" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M4.055 1.477V11m0-9.523L7.018 4.44M4.055 1.477 1.092 4.44M12.943 11V1m0 10 2.963-2.963M12.943 11 9.98 8.037"
      />
    </svg>
  );
};
