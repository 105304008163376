export const ChevronThickDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="13" fill="none" viewBox="0 0 12 13" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".5"
        d="M2.558 4.558a.625.625 0 0 1 .884 0L6 7.116l2.558-2.558a.625.625 0 1 1 .884.884l-3 3a.625.625 0 0 1-.884 0l-3-3a.625.625 0 0 1 0-.884Zm.53.354a.125.125 0 0 0-.176.176l3 3a.125.125 0 0 0 .176 0l3-3 .177.177-.177-.177a.125.125 0 0 0 0-.176l.176-.176-.176.176a.125.125 0 0 0-.176 0L6.177 7.646a.25.25 0 0 1-.354 0L3.088 4.912Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
