export const BubblesRoundOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m16.273 15.353.407 1.82-1.806-.413-.415.224a5 5 0 1 1 2.033-2.04l-.22.41Zm-1.218 2.73a6.25 6.25 0 1 1 2.54-2.55l.594 2.656a.417.417 0 0 1-.5.497l-2.634-.603Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m3.32 13.006.407-1.82-.22-.41a5 5 0 1 1 8.961-4.431c.495.03.974.118 1.43.256a6.25 6.25 0 1 0-11.493 4.766l-.594 2.655c-.067.3.2.566.5.497l2.634-.602c.365.197.752.36 1.156.482a6.23 6.23 0 0 1-.256-1.43 4.975 4.975 0 0 1-.305-.152l-.414-.224-1.806.413Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
