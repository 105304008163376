export const StarStrikeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m8.049 8.11-4.225.956c-.782.177-1.09 1.17-.558 1.793l3.274 3.83-.495 5.108c-.08.832.727 1.446 1.46 1.109L12 18.835l4.496 2.07c.732.338 1.54-.276 1.46-1.108l-.192-1.972-1.668-1.669.295 3.05-3.763-1.733-.628-.29-.627.29-3.764 1.733.424-4.373.061-.635-.414-.484-2.853-3.337 4.13-.935.346-.078L8.049 8.11zm8.02 5.898.251-.294 2.853-3.337-4.13-.935-.653-.148-.326-.585L12 5.005l-1.766 3.169L9.131 7.07l1.967-3.53a1.022 1.022 0 0 1 1.804 0l2.473 4.438 4.801 1.087c.782.177 1.09 1.17.558 1.793l-3.274 3.83.077.787-1.468-1.468z"
      />
      <rect width="25.059" height="1.5" x="3.061" y="3" fill="currentColor" rx=".75" transform="rotate(45 3.06 3)" />
    </svg>
  );
};
