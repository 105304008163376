export const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 22 18" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.07 3.8a4.601 4.601 0 0 1 9.06 0h1.27a1 1 0 1 1 0 2h-1.358a4.602 4.602 0 0 1-8.884 0H1.6a1 1 0 1 1 0-2h8.47Zm2.055 0a2.601 2.601 0 1 1 4.95 1.601 2.601 2.601 0 0 1-4.95-1.6ZM2.87 12.6a4.601 4.601 0 0 1 9.06 0h8.47a1 1 0 1 1 0 2h-8.558a4.602 4.602 0 0 1-8.884 0H1.6a1 1 0 1 1 0-2h1.27Zm2.056 0a2.601 2.601 0 1 1 4.948 1.604 2.601 2.601 0 0 1-4.948-1.605Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
