export const WikiIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 20.5h10a.5.5 0 0 0 .5-.5V9.621L11.379 3.5H7a.5.5 0 0 0-.5.5v16a.5.5 0 0 0 .5.5ZM7 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9.414a1 1 0 0 0-.293-.707l-6.414-6.414A1 1 0 0 0 11.586 2H7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 8V3h1.5v5c0 .138.112.25.25.25h5v1.5h-5A1.75 1.75 0 0 1 11.25 8Z"
        fill="currentColor"
      />
      <path
        d="M8 16.75a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1-.75-.75Zm0-3a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
