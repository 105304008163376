export const UnresolvedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.886 9.171a6.031 6.031 0 0 0 0-2.342l-.981.194a5.025 5.025 0 0 1 0 1.954l.98.194Zm-.897 2.163-.83-.557a5.031 5.031 0 0 1-1.382 1.381l.557.831a6.035 6.035 0 0 0 1.655-1.655Zm-3.818 2.552-.194-.981a5.025 5.025 0 0 1-1.954 0l-.194.98a6.031 6.031 0 0 0 2.342 0Zm-4.505-.897.557-.83a5.032 5.032 0 0 1-1.381-1.382l-.831.557a6.034 6.034 0 0 0 1.655 1.655ZM2.114 9.171a6.03 6.03 0 0 1 0-2.342l.981.194a5.03 5.03 0 0 0 0 1.954l-.98.194Zm.897-4.505.83.557a5.033 5.033 0 0 1 1.382-1.381l-.557-.831a6.033 6.033 0 0 0-1.655 1.655Zm3.818-2.552.194.981a5.03 5.03 0 0 1 1.954 0l.194-.98a6.03 6.03 0 0 0-2.342 0Zm4.505.897-.557.83a5.032 5.032 0 0 1 1.381 1.382l.831-.557a6.034 6.034 0 0 0-1.655-1.655Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </svg>
  );
};
