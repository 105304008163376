export const ChevronMiniDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.493 6.18a.576.576 0 0 0-.838 0L8 7.907 6.345 6.181a.576.576 0 0 0-.838 0 .636.636 0 0 0 0 .873l1.97 2.053c.523.524.523.524 1.047 0l1.97-2.053a.636.636 0 0 0 0-.873Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
