export const OpenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.178 2.8a.625.625 0 1 0 0 1.25h4.384l-6.004 6.004a.625.625 0 0 0 .884.884l6.004-6.004v4.384a.625.625 0 1 0 1.25 0V3.425a.625.625 0 0 0-.625-.625h-5.893Zm4.238 12.7v-3.333h1.25V15.5c0 .92-.746 1.667-1.666 1.667H5c-.92 0-1.667-.747-1.667-1.667v-10c0-.92.746-1.667 1.667-1.667h3.333v1.25H5a.417.417 0 0 0-.417.417v10c0 .23.187.417.417.417h10c.23 0 .416-.187.416-.417Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
