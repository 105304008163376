import { useId } from "react";

export const ContributeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  return (
    <svg width="18" height="14" fill="none" viewBox="0 0 18 14" {...props}>
      <path
        fill={`url(#${id1})`}
        d="M8.75 9.5h1.666a7.5 7.5 0 0 1 6.3 3.425c.106.165.367.104.367-.092A8.333 8.333 0 0 0 8.75 4.5V1.2a.417.417 0 0 0-.677-.325l-7.25 5.8a.417.417 0 0 0 0 .65l7.25 5.8a.417.417 0 0 0 .677-.325V9.5Z"
      />
      <defs>
        <linearGradient id={id1} x1="1" x2="17" y1="7" y2="13" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
