export const EmbedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.542 6.333c0-.805.652-1.458 1.458-1.458h10c.805 0 1.458.653 1.458 1.458v8.334c0 .805-.653 1.458-1.458 1.458H5a1.458 1.458 0 0 1-1.458-1.458V6.333ZM5 6.125a.208.208 0 0 0-.208.208v8.334c0 .115.093.208.208.208h10a.208.208 0 0 0 .208-.208V6.333A.208.208 0 0 0 15 6.125H5ZM2.708 3c0-.345.28-.625.625-.625h13.334a.625.625 0 1 1 0 1.25H3.333A.625.625 0 0 1 2.709 3Zm0 15c0-.345.28-.625.625-.625h13.334a.625.625 0 1 1 0 1.25H3.333A.625.625 0 0 1 2.709 18Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.286 9.687c.219.204.23.547.027.766l-.568.608a.957.957 0 0 0 1.376 1.33l.718-.719a.542.542 0 1 1 .766.766l-.718.719a2.04 2.04 0 0 1-2.934-2.835l.568-.608a.542.542 0 0 1 .765-.027Zm2.25-1.457a.97.97 0 1 1 1.372 1.373l-.735.736a.542.542 0 0 0 .766.766l.736-.736A2.054 2.054 0 1 0 9.77 7.464l-.57.57a.542.542 0 1 0 .766.766l.57-.57Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.8 9.284a.542.542 0 0 1 0 .765L9.55 11.3a.542.542 0 1 1-.766-.765l1.25-1.25a.542.542 0 0 1 .766 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
