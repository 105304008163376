export const FacebookMessengerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="url(#FacebookMessengerIcon_svg__a)"
        fillRule="evenodd"
        d="M11.994 2.62c-5.281 0-9.375 3.869-9.375 9.094 0 2.733 1.12 5.095 2.944 6.726a.744.744 0 0 1 .252.534l.051 1.668a.75.75 0 0 0 1.053.663l1.86-.821a.748.748 0 0 1 .501-.037c.856.235 1.766.36 2.714.36 5.281 0 9.375-3.868 9.375-9.093 0-5.226-4.094-9.094-9.375-9.094Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m6.371 14.613 2.754-4.37a1.406 1.406 0 0 1 2.034-.375l2.19 1.643c.2.15.477.15.677-.002l2.959-2.245c.394-.3.91.173.646.592l-2.754 4.37a1.406 1.406 0 0 1-2.034.374l-2.19-1.642a.563.563 0 0 0-.678.002l-2.958 2.245c-.395.3-.91-.173-.646-.592Z"
        clipRule="evenodd"
      />
      <defs>
        <radialGradient
          id="FacebookMessengerIcon_svg__a"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(6.228 21.27) scale(20.4299)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09F" />
          <stop offset=".61" stopColor="#A033FF" />
          <stop offset=".935" stopColor="#FF5280" />
          <stop offset="1" stopColor="#FF7061" />
        </radialGradient>
      </defs>
    </svg>
  );
};
