export const UserCircleFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10A9.996 9.996 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10Z"
        clipRule="evenodd"
      />
      <path fill="#fff" d="M12 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.262 16.866a.75.75 0 0 1 .332-.497c2.517-1.618 9.265-3.778 16.741-.04.17.085.3.23.367.408l.043.116A9.997 9.997 0 0 1 12 22a9.996 9.996 0 0 1-8.738-5.134Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.247 17.164c2.526-1.227 7.674-2.444 13.459.06A8.485 8.485 0 0 1 12 20.5a8.485 8.485 0 0 1-6.753-3.336Zm15.498-.311A9.997 9.997 0 0 1 12 22a9.996 9.996 0 0 1-8.738-5.134.75.75 0 0 1 .332-.497c2.517-1.618 9.265-3.778 16.741-.04.17.085.3.23.367.408l.043.116Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
