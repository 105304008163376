export const ImportContactIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
      <path
        fill="currentColor"
        d="M21 14a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5v-5Z"
      />
      <rect width="39" height="39" x=".5" y=".5" stroke="currentColor" rx="19.5" />
    </svg>
  );
};
