export const NodeChallengeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="15" fill="none" viewBox="0 0 18 15" {...props}>
      <path
        fill="currentColor"
        d="M17.167 2.24c0-.51-.227-1-.63-1.36a2.284 2.284 0 0 0-1.52-.564H2.984c-.57 0-1.117.203-1.52.564-.403.36-.63.85-.63 1.36v7.115c0 .51.227.999.63 1.36.403.36.95.563 1.52.563h.644v2.654c0 .072.022.142.063.203.042.061.102.11.174.143l.193.038a.464.464 0 0 0 .258-.077l4.19-2.961h6.513a2.29 2.29 0 0 0 1.52-.563 1.83 1.83 0 0 0 .629-1.36V2.239Z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth=".5"
        d="m9.885 5.816 1.832-1.832a.39.39 0 1 0-.553-.553L9.332 5.263 7.5 3.431a.39.39 0 1 0-.552.553L8.78 5.816 6.948 7.648a.39.39 0 0 0 .273.668c.1 0 .2-.036.274-.115l1.837-1.832L11.164 8.2a.392.392 0 0 0 .274.115c.1 0 .2-.036.274-.115a.39.39 0 0 0 0-.553L9.885 5.816Z"
      />
    </svg>
  );
};
