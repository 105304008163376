import React from "react";

export const CheckboxTickIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM15.6358 9.39777C15.8555 9.04662 15.749 8.58386 15.3978 8.36416C15.0467 8.14447 14.5839 8.25103 14.3642 8.60219L11.1672 13.7121L9.58251 11.7583C9.32157 11.4366 8.84926 11.3873 8.52757 11.6483C8.20587 11.9092 8.15661 12.3815 8.41754 12.7032L10.4873 15.255C10.8979 15.7612 11.6848 15.713 12.0305 15.1604L15.6358 9.39777Z"
        fill="currentColor"
      />
    </svg>
  );
};
