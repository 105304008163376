export const ReactionThankYouIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="m11.737 22.528-.525.595-5.355-4.981.525-.584A1.18 1.18 0 0 1 8.05 17.5l3.617 3.407c.455.431.49 1.155.07 1.621Z"
        fill="#C0A3FF"
      />
      <path
        d="m11.212 23.123-.724.817a1.144 1.144 0 0 1-.875.397H4.48a1.17 1.17 0 0 1-1.167-1.167v-1.797c0-.291.105-.571.304-.793l2.24-2.438 5.355 4.981Z"
        fill="#6D28FF"
      />
      <path
        d="m8.05 17.5 1.808-2.147a3.442 3.442 0 0 0 .794-1.866l.921-9.229A1.217 1.217 0 0 1 14 4.375v11.2a6.144 6.144 0 0 1-1.085 3.5l-1.248 1.82-3.617-3.407v.012Z"
        fill="#FFD600"
      />
      <path
        d="m16.66 22.528.525.595 5.355-4.981-.525-.584a1.18 1.18 0 0 0-1.668-.058l-3.617 3.407a1.167 1.167 0 0 0-.07 1.621Z"
        fill="#C0A3FF"
      />
      <path
        d="m17.185 23.123.723.817c.222.257.537.397.875.397h5.134a1.17 1.17 0 0 0 1.166-1.167v-1.797c0-.291-.105-.571-.303-.793l-2.24-2.438-5.355 4.981Z"
        fill="#6D28FF"
      />
      <path
        d="m20.347 17.5-1.809-2.147a3.441 3.441 0 0 1-.793-1.866l-.922-9.229a1.217 1.217 0 0 0-2.426.117v11.2c0 1.248.373 2.473 1.085 3.5l1.248 1.82 3.617-3.407v.012Z"
        fill="#FFD600"
      />
    </svg>
  );
};
