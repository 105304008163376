import React from "react";

import type { ButtonBaseProps } from "@/src/components/sembly-ui/core/BaseComponent/Button/types";
import { CircularIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { cn } from "@/src/utils/cn";

export type Variant =
  | "primary"
  | "outline"
  | "secondary"
  | "secondary-version-2" // This for button that looks like variant secondary, but has hover color of purple-ish instead of gray-ish
  | "coachmark-primary"
  | "coachmark-secondary";

export interface IButtonProps extends ButtonBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: Variant;
  loading?: boolean;
  transparent?: boolean;
  rounded?: boolean;
  iconClassName?: string;
  textClassName?: string;
  leftIconClassName?: string;
  rightIconClassName?: string;
  withFocusClasses?: boolean;
}

/**
 * @deprecated
 * Please use Button.tsx, this component will be removed
 */
const Button = React.forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const {
    variant,
    fluid,
    size,
    disabled,
    loading,
    rounded,
    icon: Icon,
    iconPosition,
    iconClassName,
    className,
    textClassName,
    children,
    transparent,
    leftIconClassName,
    rightIconClassName,
    withFocusClasses = true,
    ...rest
  } = props;

  const isOutlineStyleButton = ["secondary", "secondary-version-2", "outline"].includes(variant);
  const buttonClass = cn(
    "box-border",
    "inline-flex",
    "items-center",
    "justify-center",
    "disabled:cursor-not-allowed",
    {
      "focus:outline-none focus:ring-2 focus:ring-medium-purple-100 focus:ring-opacity-60 focus:ring-offset-1":
        withFocusClasses,
    },
    {
      // Primary Style
      "bg-medium-purple-100 font-medium text-white disabled:bg-medium-purple-20  disabled:text-gray-40":
        variant === "primary",
      // 10% darker
      "hover:bg-[#4947aa]": variant === "primary" && !disabled,
      //Secondary Style
      "bg-transparent": isOutlineStyleButton || transparent,
      border: isOutlineStyleButton,
      "border-gray-80 font-medium text-gray-80 disabled:border-gray-40 disabled:text-gray-40": variant === "secondary",
      "hover:border-sembly-gray hover:bg-light-gray-40 hover:text-sembly-gray": variant === "secondary" && !disabled,
      "border-gray-80 text-gray-80 disabled:border-gray-40 disabled:text-gray-40 sm:border-gray-60 sm:text-gray-60":
        variant === "secondary-version-2",
      "hover:border-medium-purple-100 hover:bg-medium-purple-20 hover:text-medium-purple-100":
        variant === "secondary-version-2" && !disabled,
      //Outline Style
      "border-medium-purple-100 text-medium-purple-100 disabled:border-medium-purple-20 disabled:text-medium-purple-20 ":
        variant === "outline",
      "hover:bg-medium-purple-20": variant === "outline" && !disabled,
      "h-[29px] w-[57px] rounded-md bg-white px-16 py-6 font-medium text-medium-blue-80 hover:bg-medium-blue-20":
        variant === "coachmark-primary",
      "h-[29px] w-[57px] rounded-md border border-white bg-medium-blue-80 px-16 py-6 font-medium text-white hover:bg-medium-blue-60":
        variant === "coachmark-secondary",
    },
    {
      // Size
      "px-8": size === "s",
      "leading-tight": size === "s",
      rounded: ["s", "m"].includes(size) && !rounded,
      "rounded-full": rounded,
      "px-16": size === "m",
      "px-32": size === "l",
      "text-xs": size === "s",
      "text-base": size === "m" || size === "l",
      "rounded-lg": size === "l",
      "font-medium": size === "l",
      "py-4": size === "s" && !isOutlineStyleButton,
      "py-8": size === "m" && !isOutlineStyleButton,
      "py-10": size === "l" && !isOutlineStyleButton,
      //Outline Variant padding
      "py-3": size === "s" && isOutlineStyleButton,
      "py-7": size === "m" && isOutlineStyleButton,
      "py-9": size === "l" && isOutlineStyleButton,
    },
    {
      "w-full": fluid,
    },
    className,
  );

  const iconClass = cn(
    [
      {
        "h-14": size === "s" || size === "m",
        "w-14": size === "s" || size === "m",
        "h-16": size === "l",
        "w-16": size === "l",
      },
    ],
    iconClassName,
  );

  const leftIconContainerClass = cn([
    "inline-flex",
    "-ml-2",
    {
      invisible: loading,
      // don't add margin when button is only icon
      "mr-4": size === "s" && Boolean(children),
      "mr-8": (size === "m" || size === "l") && Boolean(children),
    },
    leftIconClassName,
  ]);

  const rightIconContainerClass = cn([
    "inline-flex",
    {
      invisible: loading,
      // don't add margin when button is only icon
      "ml-4": size === "s" && Boolean(children),
      "ml-8": (size === "m" || size === "l") && Boolean(children),
    },
    rightIconClassName,
  ]);

  return (
    <button ref={ref} disabled={loading || disabled} className={buttonClass} {...rest}>
      {loading && (
        <div className="absolute">
          <CircularIcon className={iconClass} />
        </div>
      )}

      {iconPosition === "left" && Icon && (
        <span className={leftIconContainerClass}>{Icon && <Icon className={iconClass} />}</span>
      )}
      <span className={`${loading && "invisible"} ${textClassName}`}>{children}</span>
      {iconPosition === "right" && Icon && (
        <span className={rightIconContainerClass}>{Icon && <Icon className={iconClass} />}</span>
      )}
    </button>
  );
});
Button.displayName = "Button";

Button.defaultProps = {
  size: "m",
  variant: "primary",
  disabled: false,
  fluid: false,
  iconPosition: "left",
  className: "",
};

export default Button;
