export const PeopleGatheringIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" fill="none" viewBox="0 0 36 25" {...props}>
      <path
        fill="#949495"
        d="M29.007 20.75a17.922 17.922 0 0 1-11 3.75A17.922 17.922 0 0 1 7.01 20.75c1.136-3.367 4.5-5.815 8.463-5.815h5.071c3.963 0 7.328 2.448 8.464 5.815Z"
      />
      <path
        fill="#949495"
        d="M29.884 20.027c-1.274-3.028-4.053-5.31-7.409-6.082a7.487 7.487 0 0 1 5.423-2.314h4.27c1.033 0 2.017.209 2.913.586a18.021 18.021 0 0 1-5.197 7.81ZM6.07 19.972c1.285-3 4.049-5.257 7.385-6.025a7.487 7.487 0 0 0-5.422-2.314h-4.27a7.497 7.497 0 0 0-2.838.555 18.019 18.019 0 0 0 5.145 7.784ZM25.229 4.758c0 2.624 2.155 4.759 4.804 4.759 2.649 0 4.804-2.135 4.804-4.759C34.837 2.135 32.682 0 30.033 0c-2.649 0-4.804 2.135-4.804 4.758ZM5.897 9.519c-2.648 0-4.804-2.135-4.804-4.759C1.093 2.137 3.25.002 5.897.002c2.65 0 4.804 2.135 4.804 4.758 0 2.624-2.155 4.759-4.804 4.759Zm6.707-2.335c0 2.948 2.259 5.375 5.404 5.375s5.405-2.427 5.405-5.375-2.26-5.319-5.405-5.319c-3.145 0-5.404 2.37-5.404 5.319Z"
      />
    </svg>
  );
};
