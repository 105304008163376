export const BadgeFacilitatorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#Badge Icons__a)">
        <path d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z" fill="#2A0FCE" />
        <path
          d="M9 2.808 4.053 4.764v5.795a6.517 6.517 0 0 0 4.513 4.511l.434.122.434-.122a6.512 6.512 0 0 0 4.513-4.51V4.763L9 2.808Zm2.507 8.978L9 10.468l-2.506 1.318.479-2.792-2.029-1.976 2.803-.406L9 4.072l1.253 2.54 2.803.406-2.028 1.976.479 2.792Zm-3.816.667.904-.132.405-.82.405.82.906.132-.655.639.154.9-.81-.425-.81.426.155-.901-.655-.64Z"
          fill="#fff"
        />
        <path
          d="m11.028 8.994.479 2.792L9 10.468l-2.507 1.318.48-2.792-2.029-1.976 2.803-.406L9 4.072l1.253 2.54 2.803.406-2.028 1.976Z"
          fill="#2A0FCE"
        />
        <path
          d="m9.656 13.092.154.9-.81-.425-.81.426.155-.901-.655-.64.905-.131.405-.82.405.82.906.132-.655.639Z"
          fill="#2A0FCE"
        />
      </g>
      <defs>
        <clipPath id="Badge Icons__a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
