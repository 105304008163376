import React from "react";

export const FirstContributionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m2.656 13.338.523-2.335-.176-.328a5.667 5.667 0 0 1 6.915-8.009c.212-.298.474-.557.775-.767a6.667 6.667 0 0 0-8.57 9.248l-.674 3.004c-.054.24.16.453.4.398l2.982-.682a6.667 6.667 0 0 0 9.744-6.974c-.274.237-.59.429-.934.562a5.667 5.667 0 0 1-8.334 5.532l-.332-.18-2.32.531Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15 4.5a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
