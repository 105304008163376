import React from "react";

import Logo from "@/src/components/sembly-ui/components/Logo/Logo";

const MaintenancePage: React.FC = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-sembly-gray">
      <div className="flex flex-col justify-center rounded-xl border-2 border-white p-32 text-center">
        <div className="mb-16 flex justify-center">
          <Logo color="white" size="l" />
        </div>
        <div className="text-bold text-2xl text-white">Sembly is currently down for maintenance.</div>
      </div>
    </div>
  );
};

MaintenancePage.displayName = "MaintenancePage";
export default MaintenancePage;
