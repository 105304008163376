export const BadgeQualityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path fill="#C1B1FF" d="M7.167 6.28h9.55l4.285 5.21-9.008 8.74-8.995-8.74 4.168-5.21Z" />
      <path fill="#ADABFF" d="m7.167 6.28.471 5.993-4.178-.335-.462-.448 4.169-5.21Z" />
      <path fill="#fff" d="m11.994 6.28-4.356 5.993h8.724L11.994 6.28Z" />
      <path
        fill="#ADABFF"
        d="m16.833 6.28-.471 5.993 4.176-.335.462-.448-4.167-5.21Zm-4.839 13.95-4.356-7.957h8.724l-4.368 7.957Z"
      />
      <path fill="#6D28FF" d="m11.994 20.23 4.368-7.957 4.176-.335-8.544 8.292Z" />
      <path fill="#6D28FF" d="m12 20.23-4.368-7.957-4.176-.335L12 20.23Z" />
      <path
        fill="#fff"
        d="m17.066 3.77.816 1.489 1.489.814-1.49.815-.816 1.491-.814-1.491-1.49-.814 1.49-.815.814-1.49Z"
      />
    </svg>
  );
};
