export const DragHandleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="9" height="14" fill="none" viewBox="0 0 9 14" {...props}>
      <path
        fill="currentColor"
        d="M3.5 12.25c0 .963-.788 1.75-1.75 1.75C.787 14 0 13.213 0 12.25s.787-1.75 1.75-1.75c.962 0 1.75.787 1.75 1.75Zm-1.75-7C.787 5.25 0 6.037 0 7s.787 1.75 1.75 1.75c.962 0 1.75-.787 1.75-1.75s-.788-1.75-1.75-1.75Zm0-5.25C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0ZM7 3.5c.963 0 1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0S5.25.787 5.25 1.75c0 .962.787 1.75 1.75 1.75Zm0 1.75c-.963 0-1.75.787-1.75 1.75S6.037 8.75 7 8.75 8.75 7.963 8.75 7 7.963 5.25 7 5.25Zm0 5.25c-.963 0-1.75.787-1.75 1.75S6.037 14 7 14s1.75-.787 1.75-1.75S7.963 10.5 7 10.5Z"
      />
    </svg>
  );
};
