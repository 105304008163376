export const BookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.534 4.368a3.958 3.958 0 0 1 2.8-1.16H17.5c.345 0 .625.28.625.625V15.5c0 .345-.28.625-.625.625h-5A1.875 1.875 0 0 0 10.625 18a.625.625 0 1 1-1.25 0V7.167a3.96 3.96 0 0 1 1.16-2.8Zm.091 11.132a3.125 3.125 0 0 1 1.875-.625h4.375V4.458h-3.542a2.708 2.708 0 0 0-2.708 2.709V15.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.875 3.833c0-.345.28-.625.625-.625h4.167a3.958 3.958 0 0 1 3.958 3.959V18a.625.625 0 1 1-1.25 0A1.875 1.875 0 0 0 7.5 16.125h-5a.625.625 0 0 1-.625-.625V3.833Zm7.5 11.667V7.167a2.708 2.708 0 0 0-2.708-2.709H3.125v10.417H7.5c.68 0 1.337.222 1.875.625Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
