export const ExpandArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="8" fill="none" viewBox="0 0 12 8" {...props}>
      <path
        fill="currentColor"
        d="M.314 7.19a1.053 1.053 0 0 1 0-1.5L5.243.81a1.077 1.077 0 0 1 1.514 0l4.929 4.88a1.051 1.051 0 0 1 0 1.5 1.077 1.077 0 0 1-1.514 0L5.995 3.064 1.828 7.189a1.097 1.097 0 0 1-1.514 0Z"
      />
    </svg>
  );
};
