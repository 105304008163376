export const DiscussionMapIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.903 4H9.097A.097.097 0 0 0 9 4.097v3.806c0 .053.044.097.097.097h5.806A.097.097 0 0 0 15 7.903V4.097A.097.097 0 0 0 14.903 4ZM9.097 2.5c-.882 0-1.597.715-1.597 1.597v3.806c0 .882.715 1.597 1.597 1.597h2.153v.918c0 .468-.38.847-.847.847H7.597a2.347 2.347 0 0 0-2.347 2.347v.888H3.097c-.882 0-1.597.715-1.597 1.597v3.806c0 .882.715 1.597 1.597 1.597h5.806c.882 0 1.597-.715 1.597-1.597v-3.806c0-.882-.715-1.597-1.597-1.597H6.75v-.888c0-.468.38-.847.847-.847h2.806A2.34 2.34 0 0 0 12 12.138c.419.389.98.627 1.597.627h2.806c.468 0 .847.38.847.847v.888h-2.153c-.882 0-1.597.715-1.597 1.597v3.806c0 .882.715 1.597 1.597 1.597h5.806c.882 0 1.597-.715 1.597-1.597v-3.806c0-.882-.715-1.597-1.597-1.597H18.75v-.888a2.347 2.347 0 0 0-2.347-2.347h-2.806a.847.847 0 0 1-.847-.847V9.5h2.153c.882 0 1.597-.715 1.597-1.597V4.097c0-.882-.715-1.597-1.597-1.597H9.097Zm-6 13.5h5.806c.053 0 .097.044.097.097v3.806a.097.097 0 0 1-.097.097H3.097A.097.097 0 0 1 3 19.903v-3.806c0-.053.044-.097.097-.097Zm17.806 0h-5.806a.097.097 0 0 0-.097.097v3.806c0 .053.043.097.097.097h5.806a.097.097 0 0 0 .097-.097v-3.806a.097.097 0 0 0-.097-.097Z"
        fill="currentColor"
      />
    </svg>
  );
};
