export const RemoveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        d="M13.725 4.283a.747.747 0 0 0-1.057 0L9 7.943 5.332 4.275a.747.747 0 1 0-1.057 1.058L7.942 9l-3.667 3.668a.747.747 0 1 0 1.057 1.057L9 10.057l3.668 3.668a.747.747 0 1 0 1.057-1.057L10.057 9l3.668-3.667a.752.752 0 0 0 0-1.05Z"
      />
    </svg>
  );
};
