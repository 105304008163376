export const PendingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 2.5a.75.75 0 0 0 0 1.5h.536l.778 4.088a6.042 6.042 0 0 0 3.508 4.404 6.233 6.233 0 0 0-3.657 4.389L5.333 19.5H4.75a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5h-.583l-.582-2.62a6.233 6.233 0 0 0-3.657-4.388 6.042 6.042 0 0 0 3.508-4.404L18.714 4h.536a.75.75 0 0 0 0-1.5H4.75Zm12.38 17-.51-2.294a4.733 4.733 0 0 0-9.24 0L6.87 19.5h10.26ZM7.538 7.808 6.813 4h10.374l-.725 3.808a4.542 4.542 0 0 1-8.924 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
