export const BetaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="8" fill="none" viewBox="0 0 20 8" {...props}>
      <path
        fill="currentColor"
        d="M5.592 4.84c0 .808-.188 1.45-.565 1.929-.374.478-.889.717-1.546.717-.633 0-1.126-.22-1.48-.66l-.06.563h-1.1V0h1.215v2.684c.35-.397.822-.596 1.415-.596.66 0 1.179.236 1.556.707.377.472.565 1.13.565 1.977v.068Zm-1.215-.101c0-.565-.104-.988-.31-1.27-.207-.283-.507-.424-.9-.424-.527 0-.898.222-1.11.664v2.146c.216.452.59.678 1.12.678.38 0 .673-.136.88-.409.206-.272.313-.685.32-1.236v-.15Zm4.621 2.747c-.77 0-1.396-.233-1.876-.698-.477-.468-.715-1.09-.715-1.867v-.144c0-.52.103-.983.31-1.39.21-.41.504-.73.88-.958a2.389 2.389 0 0 1 1.26-.341c.737 0 1.306.226 1.706.678.403.452.605 1.092.605 1.92v.471H7.632c.037.43.185.77.445 1.02.264.25.594.375.99.375.557 0 1.01-.216 1.36-.65l.656.602a2.18 2.18 0 0 1-.87.727c-.36.17-.765.255-1.215.255Zm-.145-4.46c-.334 0-.604.112-.81.337-.204.224-.334.537-.39.938h2.315v-.087c-.027-.391-.135-.686-.325-.885-.19-.202-.454-.303-.79-.303ZM13.639.919v1.265h.955v.866h-.955v2.906c0 .199.04.343.12.433.083.086.23.13.44.13.14 0 .281-.016.425-.049v.905c-.277.074-.544.11-.8.11-.934 0-1.4-.495-1.4-1.486V3.05h-.89v-.866h.89V.92h1.215ZM18.76 7.39c-.054-.1-.1-.262-.14-.487-.387.389-.86.583-1.42.583-.544 0-.988-.15-1.33-.448a1.408 1.408 0 0 1-.516-1.106c0-.555.213-.98.64-1.275.43-.298 1.044-.448 1.84-.448h.746v-.341c0-.27-.079-.484-.235-.645-.157-.163-.395-.245-.715-.245-.277 0-.504.067-.68.202a.604.604 0 0 0-.266.505H15.47c0-.285.098-.552.295-.799a1.95 1.95 0 0 1 .8-.586c.34-.142.719-.212 1.136-.212.633 0 1.138.154 1.515.462.377.304.57.734.58 1.289v2.348c0 .468.068.842.205 1.12v.082h-1.24Zm-1.336-.843c.24 0 .466-.056.676-.168.213-.112.373-.263.48-.452v-.982h-.655c-.45 0-.789.076-1.016.227a.725.725 0 0 0-.34.64.68.68 0 0 0 .23.538c.157.132.365.197.625.197Z"
      />
    </svg>
  );
};
