export const WriteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="60" height="60" fill="none" viewBox="0 0 60 60" {...props}>
      <path
        fill="currentColor"
        d="M7.646 42.979a.5.5 0 0 0-.146.353V52a.5.5 0 0 0 .5.5h8.668a.5.5 0 0 0 .353-.146L44.525 24.85l-9.375-9.375L7.646 42.979ZM14.8 47.5h-2.3v-2.3l22.65-22.65 2.3 2.3L14.8 47.5Zm36.975-33.425-5.85-5.85c-.5-.5-1.125-.725-1.775-.725-.65 0-1.275.25-1.75.725L37.825 12.8l9.375 9.375 4.575-4.575a2.49 2.49 0 0 0 0-3.525Z"
      />
    </svg>
  );
};
