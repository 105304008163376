export const ReactionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 2.813a6.188 6.188 0 1 0 6.034 4.811.562.562 0 1 1 1.097-.248 7.312 7.312 0 1 1-5.507-5.507.563.563 0 0 1-.249 1.097A6.21 6.21 0 0 0 9 2.812Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.688 7.5c0-.31.251-.563.562-.563h.008a.562.562 0 1 1 0 1.125h-.008a.562.562 0 0 1-.563-.562Zm-4.5 0c0-.31.251-.563.562-.563h.008a.563.563 0 0 1 0 1.125H6.75a.563.563 0 0 1-.563-.562Zm7.5-6c.31 0 .562.252.562.563v4.5a.562.562 0 1 1-1.125 0v-4.5c0-.311.252-.563.563-.563Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.5 4.313c0 .31-.252.562-.563.562h-4.5a.562.562 0 1 1 0-1.125h4.5c.311 0 .563.252.563.563Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.06 12.548c-2.1 0-3.375-1.65-3.45-1.725-.15-.225-.15-.6.075-.75.225-.15.6-.15.75.075 0 0 .975 1.275 2.55 1.275 1.575 0 2.55-1.275 2.55-1.275.15-.225.525-.3.75-.075.225.15.3.525.075.75.075 0-1.2 1.725-3.3 1.725Z"
      />
    </svg>
  );
};
