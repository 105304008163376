import type { SVGProps } from "react";

export function NewMoreIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M9.333 4a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM9.333 8a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0ZM9.333 12a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0Z"
      />
    </svg>
  );
}
