export const ExpandIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M19 11.222V5h-6.222l2.559 2.559-7.778 7.778L5 12.777V19h6.222l-2.559-2.559 7.778-7.778L19 11.223Z"
      />
    </svg>
  );
};
