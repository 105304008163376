export const ReactionBrilliantIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="m15.75 6.393 1.808 3.652 4.037.583c1.598.234 2.24 2.194 1.085 3.325L19.763 16.8l.689 4.013c.268 1.599-1.4 2.812-2.835 2.054l-3.605-1.902-3.605 1.902c-1.435.746-3.104-.467-2.835-2.054L8.26 16.8l-2.917-2.847c-1.155-1.131-.513-3.091 1.085-3.325l4.037-.583 1.808-3.652c.712-1.446 2.777-1.446 3.5 0h-.023Z"
        fill="#FFD600"
      />
      <path
        d="M22.295 5.063A.807.807 0 0 1 22.33 6.3l-3.395 3.022L21.07 5.32a.814.814 0 0 1 1.213-.257h.012Zm-16.59 0A.807.807 0 0 0 5.67 6.3l3.395 3.022L6.93 5.32a.814.814 0 0 0-1.213-.257h-.012Zm-2.24 15.692a.808.808 0 0 1 .222-1.225l3.943-2.263-2.917 3.476a.806.806 0 0 1-1.236 0l-.012.012Zm21.467 0a.808.808 0 0 0-.222-1.225l-3.943-2.263 2.916 3.476a.806.806 0 0 0 1.237 0l.012.012Z"
        fill="#6D28FF"
      />
    </svg>
  );
};
