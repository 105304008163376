export const TasksIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7 5.75c-.69 0-1.25.56-1.25 1.25v11A2.25 2.25 0 0 0 8 20.25h8A2.25 2.25 0 0 0 18.25 18V7c0-.69-.56-1.25-1.25-1.25h-2.375v-1.5H17A2.75 2.75 0 0 1 19.75 7v11A3.75 3.75 0 0 1 16 21.75H8A3.75 3.75 0 0 1 4.25 18V7A2.75 2.75 0 0 1 7 4.25h2.375v1.5H7Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 3.5h-2A1.5 1.5 0 0 0 9.5 5v1.5h5V5A1.5 1.5 0 0 0 13 3.5ZM11 2a3 3 0 0 0-3 3v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V5a3 3 0 0 0-3-3h-2Zm5.47 7.415a.75.75 0 0 1 .115 1.054l-4.812 6a.75.75 0 0 1-1.124.053L8.46 14.264a.75.75 0 1 1 1.08-1.044l1.595 1.647 4.28-5.336a.75.75 0 0 1 1.055-.116Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
