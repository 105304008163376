export const ReopenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="22" fill="none" viewBox="0 0 24 22" {...props}>
      <svg width="18" height="16" x="3" y="3" viewBox="0 0 18 16">
        <path
          fill="currentColor"
          d="M10.05.5A7.508 7.508 0 0 0 2.333 8H.842a.412.412 0 0 0-.292.708l2.325 2.334a.413.413 0 0 0 .592 0l2.325-2.334a.417.417 0 0 0-.3-.708H4c0-3.25 2.65-5.875 5.917-5.833 3.1.041 5.708 2.65 5.75 5.75a5.837 5.837 0 0 1-5.834 5.916A5.746 5.746 0 0 1 6.267 12.6a.829.829 0 0 0-1.1.067c-.35.35-.325.941.066 1.241a7.381 7.381 0 0 0 4.6 1.592 7.508 7.508 0 0 0 7.5-7.717C17.225 3.875 13.958.608 10.05.5Z"
        />
      </svg>
    </svg>
  );
};
