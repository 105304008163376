export const NotificationStrikeThroughIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.125 15a1.875 1.875 0 0 0 3.75 0h1.25a3.125 3.125 0 1 1-6.25 0h1.25Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.583 11.666c0 .271.088.534.25.75l1 1.334h-.072l1.199 1.198a.835.835 0 0 0 .373-1.282l-1.5-2V7.5A5.833 5.833 0 0 0 5.638 3.626l.886.886a4.583 4.583 0 0 1 8.06 2.988v4.166Zm-.59 2.084H4.166l1-1.334a1.25 1.25 0 0 0 .25-.75V7.5c0-.682.15-1.33.416-1.911l-.929-.93a5.804 5.804 0 0 0-.737 2.84v4.167l-1.5 2A.833.833 0 0 0 3.333 15h11.91l-1.25-1.25Z"
        clipRule="evenodd"
      />
      <path
        fill="#4D4D4F"
        d="M2.403 2.942a.625.625 0 0 1 .884 0l13.882 13.882a.625.625 0 0 1-.884.884L2.403 3.826a.625.625 0 0 1 0-.884Z"
      />
    </svg>
  );
};
