export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 27 28" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.874 9.061a1.125 1.125 0 0 0-1.59-1.59l-4.941 4.94-4.784-4.784a1.125 1.125 0 1 0-1.59 1.591l4.783 4.784-4.784 4.783a1.125 1.125 0 1 0 1.591 1.591l4.784-4.783 4.94 4.94a1.125 1.125 0 1 0 1.591-1.591l-4.94-4.94 4.94-4.94Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
