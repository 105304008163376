export const ArrowBigDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.53 11.47a.75.75 0 0 0-1.06 1.06l7 7a.747.747 0 0 0 1.059.002l.001-.002m0 0 7-7a.75.75 0 1 0-1.06-1.06l-5.72 5.72V5a.75.75 0 0 0-1.5 0v12.19l-5.72-5.72"
        fill="currentColor"
      />
    </svg>
  );
};
