export const NoteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".2"
        d="M20.494 6.986v-.003A3.7 3.7 0 0 0 16.8 3.5H7.197l-.21.006h-.004A3.7 3.7 0 0 0 3.5 7.2v9.602l.006.212v.003A3.7 3.7 0 0 0 7.2 20.5h5.26l.212-.01h.004a2.5 2.5 0 0 0 1.547-.723l5.546-5.545.003-.003.142-.156.003-.004a2.5 2.5 0 0 0 .584-1.605V7.197l-.006-.211ZM19.1 11.9h-3.503l-.21.006h-.004A3.7 3.7 0 0 0 11.9 15.6v3.5H7.202l-.176-.006A2.3 2.3 0 0 1 4.9 16.8V7.2l.006-.176A2.3 2.3 0 0 1 7.2 4.9h9.598l.177.005A2.3 2.3 0 0 1 19.1 7.2v4.7Zm-3.5 1.4h3.11l-5.41 5.41V15.6l.006-.176A2.3 2.3 0 0 1 15.6 13.3Z"
      />
    </svg>
  );
};
