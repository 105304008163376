export const ClockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="19" fill="none" viewBox="0 0 18 19" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 6.688c.31 0 .563.251.563.562v2.652l1.189.595a.563.563 0 0 1-.504 1.006l-1.81-.905V7.25c0-.31.251-.563.562-.563Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 15.125a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Zm0 1.125a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
