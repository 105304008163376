export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="9" stroke="currentColor" strokeWidth="2" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m5.5 11.04 2.75 3.031L14.5 7"
      />
    </svg>
  );
};
