export const BadgeNewMemberIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#49599A" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="m16.398 19.724 1.452 2.755a11.932 11.932 0 0 1-4.974 1.49l-1.197-3.352c-3.084-3.766-5.085-8.314-5.154-8.475-.782-1.175-1.264-2.2-1.264-2.684 0-.122.03-.209.093-.257.383-.29 1.516 1.065 2.916 2.923l.024.034h.034c.2 0 .39-.08.53-.22l.032-.033a17.926 17.926 0 0 1-1.203-2.057C6.59 7.67 6.074 5.718 6.535 5.486c.461-.233 1.532 1.605 2.629 3.781.28.557.535 1.082.751 1.559.09-.07.185-.13.284-.182a60.41 60.41 0 0 1-.745-2.45c-.676-2.341-1.218-4.243-.721-4.387.496-.142 1.446 1.64 2.122 3.981.26.899.441 1.749.539 2.464a1.22 1.22 0 0 0 .451-.26 43.947 43.947 0 0 1-.16-1.701c-.188-2.432-.231-4.505.284-4.544.516-.039 1.083 1.899 1.269 4.329.058.765.124 1.446.154 2.034.081.276.176.506.293.693.24.382.633.633 1.061.705l.11-.177c1.37-2.173 2.969-3.627 3.571-3.246.604.38-.598 1.491-1.386 4.623a2.69 2.69 0 0 1-.072.252l-.781 5.559c-.058.414.015.836.21 1.205Z"
      />
      <path
        fill="#ADABFF"
        d="M11.709 21.703c-4.899 0-8.884-3.985-8.884-8.884h.25c0 4.761 3.873 8.635 8.635 8.635v.251l-.001-.002Zm9.468-10.414h-.25c0-4.761-3.873-8.635-8.636-8.635v-.251c4.899 0 8.884 3.986 8.884 8.884l.002.002Z"
      />
      <path
        fill="#ADABFF"
        d="M16.002 17.407a.13.13 0 0 1-.1-.049c-1.191-1.588-1.598-3.765-1.064-5.678a.126.126 0 0 1 .155-.086.126.126 0 0 1 .086.154c-.514 1.839-.123 3.932 1.023 5.46a.125.125 0 0 1-.025.176.13.13 0 0 1-.075.025v-.002ZM8.53 13.74a.127.127 0 0 1-.124-.11.124.124 0 0 1 .108-.139 7.66 7.66 0 0 0 4.085-1.87.124.124 0 0 1 .176.01.124.124 0 0 1-.01.177 7.913 7.913 0 0 1-4.22 1.932H8.53h.001Z"
      />
      <path fill="#fff" d="m16.671 1.7.816 1.489 1.49.814-1.49.816-.816 1.49-.814-1.49-1.49-.816 1.49-.814.814-1.49Z" />
    </svg>
  );
};
