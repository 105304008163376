export const BadgeWizardIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#C1B1FF"
        d="m5.481 15.594 3.236-8.487c.166-.437.39-.849.684-1.21.433-.535 1.12-1.117 2.047-1.117h3.765s1.229.393 1.521 1.596a.6.6 0 0 1-.591.731h-1.734a.6.6 0 0 0-.569.793l2.618 7.694H5.481Z"
      />
      <path
        fill="#fff"
        d="M17.103 15.594H5.127a.72.72 0 0 0-.72.72v2.187c0 .398.322.72.72.72h11.976a.72.72 0 0 0 .72-.72v-2.187a.72.72 0 0 0-.72-.72Z"
      />
      <path fill="#C1B1FF" d="M17.317 10.185a2.276 2.276 0 1 0 0-4.551 2.276 2.276 0 0 0 0 4.551Z" />
      <path fill="#fff" d="M16.57 9.643a.746.746 0 1 0 0-1.49.746.746 0 0 0 0 1.49Z" />
      <path
        fill="#2A0FCE"
        d="m11.115 8.898.947 1.731 1.73.947-1.73.946-.947 1.73-.947-1.73-1.729-.947 1.73-.946.946-1.731Zm2.678 3.876.334.612.61.335-.61.334-.335.612-.334-.612-.612-.335.612-.334.335-.612Zm-4.808-4.29.336.612.61.334-.61.335-.336.612-.335-.612-.61-.335.61-.334.335-.612Z"
      />
    </svg>
  );
};
