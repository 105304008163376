export const ChatBubbleRoundedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m5.96 20.63-.98 4.38 4.348-.996.622.337A10.57 10.57 0 0 0 15 25.625c5.868 0 10.625-4.757 10.625-10.625S20.868 4.375 15 4.375 4.375 9.132 4.375 15c0 1.817.455 3.523 1.255 5.015l.33.615ZM9.057 26c1.768.957 3.792 1.5 5.943 1.5 6.904 0 12.5-5.596 12.5-12.5S21.904 2.5 15 2.5 2.5 8.096 2.5 15c0 2.134.535 4.144 1.478 5.902l-1.261 5.631c-.1.449.3.848.749.746l5.591-1.28Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.5 15a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm3.75 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM20 15a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
      />
    </svg>
  );
};
