export const UsersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21 20v-1.704c0-.755-.296-1.488-.839-2.084-.543-.597-1.303-1.023-2.161-1.212m-3 5v-1.667c0-.884-.316-1.732-.879-2.357C13.56 15.351 12.796 15 12 15H6c-.796 0-1.559.351-2.121.976C3.316 16.601 3 17.45 3 18.333V20M16 4a3.982 3.982 0 0 1 2.162 1.468C18.705 6.192 19 7.083 19 8s-.295 1.808-.838 2.532A3.982 3.982 0 0 1 16 12m-7 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
    </svg>
  );
};
