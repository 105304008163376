export const WarningOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3509 13.4999L8.28871 2.99992C8.16041 2.7777 7.83966 2.7777 7.71136 2.99992L1.64919 13.4999C1.52089 13.7221 1.68126 13.9999 1.93786 13.9999H14.0622C14.3188 13.9999 14.4792 13.7221 14.3509 13.4999ZM9.15474 2.49992C8.64154 1.61103 7.35854 1.61103 6.84534 2.49992L0.783161 12.9999C0.269961 13.8888 0.91146 14.9999 1.93786 14.9999H14.0622C15.0886 14.9999 15.7301 13.8888 15.2169 12.9999L9.15474 2.49992ZM8 5.83325C8.27614 5.83325 8.5 6.05711 8.5 6.33325V9.33325C8.5 9.60939 8.27614 9.83325 8 9.83325C7.72386 9.83325 7.5 9.60939 7.5 9.33325V6.33325C7.5 6.05711 7.72386 5.83325 8 5.83325ZM8 12.4999C8.36819 12.4999 8.66667 12.2014 8.66667 11.8333C8.66667 11.4651 8.36819 11.1666 8 11.1666C7.63181 11.1666 7.33333 11.4651 7.33333 11.8333C7.33333 12.2014 7.63181 12.4999 8 12.4999Z"
        fill="currentColor"
      />
    </svg>
  );
};
