import React from "react";

import Button from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyButton";

export type UpdateToastProps = {
  text?: string;
  updateButtonText?: string;
  handleUpdate?: () => void;
};

const UpdateToast: React.FC<React.PropsWithChildren<UpdateToastProps>> = ({
  text = "New app updates available",
  updateButtonText = "Refresh",
  handleUpdate = () => {},
}) => {
  return (
    <div className="flex">
      <div className="flex-1 text-base"> {text}</div>
      <Button onClick={handleUpdate} size="s" variant="primary" className="!bg-medium-purple-80">
        {updateButtonText}
      </Button>
    </div>
  );
};

UpdateToast.displayName = "UpdateToast";
export default UpdateToast;
