export const AssemblyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3.732 3.804 5.866v4.268L7.5 12.268l3.696-2.134V5.866L7.5 3.732ZM8 2.289a1 1 0 0 0-1 0L2.804 4.71a1 1 0 0 0-.5.866v4.846a1 1 0 0 0 .5.866l4 2.309v4.825a1 1 0 0 0 .5.866L11.5 21.71a1 1 0 0 0 1 0l4.196-2.422a1 1 0 0 0 .5-.866v-4.825l4-2.31a1 1 0 0 0 .5-.865V5.577a1 1 0 0 0-.5-.866L17 2.29a1 1 0 0 0-1 0l-4 2.309-4-2.31Zm8.5 1.443-3.696 2.134v4.268l3.696 2.134 3.696-2.134V5.866L16.5 3.732ZM8.304 13.866 12 11.732l3.696 2.134v4.268L12 20.268l-3.696-2.134v-4.268Z"
        fill="currentColor"
      />
    </svg>
  );
};
