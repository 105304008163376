export const ClosedIndicatorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M2.872 13.5c-.337 0-.622-.117-.855-.35a1.163 1.163 0 0 1-.35-.855v-7.59c0-.337.116-.622.35-.855.233-.233.518-.35.855-.35h3.66l1.333 1.333h5.263c.337 0 .622.117.855.35.233.234.35.519.35.855v6.257c0 .337-.117.622-.35.855-.233.233-.518.35-.855.35H2.872Zm0-1h10.256a.2.2 0 0 0 .147-.058.2.2 0 0 0 .058-.147V6.038a.2.2 0 0 0-.058-.147.2.2 0 0 0-.147-.058H7.456L6.123 4.5H2.872a.2.2 0 0 0-.148.058.2.2 0 0 0-.058.147v7.59a.2.2 0 0 0 .058.147.2.2 0 0 0 .148.058Z"
        fill="currentColor"
      />
    </svg>
  );
};
