export const GroupIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="12" fill="none" viewBox="0 0 18 12" {...props}>
      <path
        fill="currentColor"
        d="M6.5 7.458c-1.95 0-5.833.975-5.833 2.917v1.458h11.666v-1.458c0-1.942-3.883-2.917-5.833-2.917Zm-3.883 2.709c.7-.484 2.391-1.042 3.883-1.042s3.183.558 3.883 1.042H2.617ZM6.5 6a2.92 2.92 0 0 0 2.917-2.917A2.92 2.92 0 0 0 6.5.167a2.92 2.92 0 0 0-2.917 2.916A2.92 2.92 0 0 0 6.5 6Zm0-4.167c.692 0 1.25.559 1.25 1.25 0 .692-.558 1.25-1.25 1.25s-1.25-.558-1.25-1.25c0-.691.558-1.25 1.25-1.25Zm5.867 5.675c.966.7 1.633 1.634 1.633 2.867v1.458h3.333v-1.458c0-1.683-2.916-2.642-4.966-2.867ZM11.5 6a2.92 2.92 0 0 0 2.917-2.917A2.92 2.92 0 0 0 11.5.167c-.45 0-.867.108-1.25.291.525.742.833 1.65.833 2.625 0 .975-.308 1.884-.833 2.625.383.184.8.292 1.25.292Z"
      />
    </svg>
  );
};
