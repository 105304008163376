export const BadgeExplorerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="m18.172 3.107.815 1.489 1.49.814-1.49.815-.814 1.491-.815-1.491-1.49-.814 1.49-.815.814-1.49Z"
      />
      <path
        fill="#ADABFF"
        d="M23.826 14.046a11.952 11.952 0 0 1-2.858 5.925c-.274.309-.565.606-.871.885a2.9 2.9 0 0 1-1.317-1.892 1.333 1.333 0 1 1 .454-2.213 2.892 2.892 0 0 1 2.144-1.242 1.948 1.948 0 0 1 2.448-1.463Zm-10.494 8.19a1.332 1.332 0 1 0 0-2.664 1.332 1.332 0 0 0 0 2.664Zm-4.162-.695a2.89 2.89 0 0 1-.73 1.922 12.005 12.005 0 0 1-4.748-2.803 11.985 11.985 0 0 1-3.455-6.281 1.944 1.944 0 0 1 3.126 1.305 1.45 1.45 0 0 1 1.69 1.089 1.947 1.947 0 0 1 2.463 2.151c.275.13.525.303.743.509.561.527.91 1.277.91 2.108Z"
      />
      <path
        fill="#C1B1FF"
        d="M20.968 19.971A11.962 11.962 0 0 1 12 24c-1.239 0-2.435-.188-3.56-.537a12.005 12.005 0 0 1-4.748-2.803 1.912 1.912 0 0 1 3.574-.68 3.542 3.542 0 0 1 2.178-.752 3.53 3.53 0 0 1 3.247 2.136 3.502 3.502 0 0 1 1.634-.457 3.535 3.535 0 0 1 6.643-.936Z"
      />
      <path
        fill="#fff"
        d="M15.819 11.979c.333-3.732-1.125-6.813-1.125-6.813a15.035 15.035 0 0 1-5.388 0l-.008.016a16.06 16.06 0 0 0-.294.738c-.024.068-.048.134-.07.2a15.11 15.11 0 0 0-.795 4.288v.016c-.05 2.5.63 4.395 1.048 5.319.029.061.054.118.08.169.072.147.148.294.23.44l.007.013h4.99c.18-.321.338-.646.478-.972.998.684 1.496 4.023 1.496 4.023 1.931-4.908-.136-7.008-.649-7.437ZM12 11.019a1.257 1.257 0 1 1 0-2.513 1.257 1.257 0 0 1 0 2.513Z"
      />
      <path
        fill="#fff"
        d="M9.004 5.92c.149-.413.264-.673.294-.737-.106.248-.204.495-.294.737Zm.183 9.822a7.2 7.2 0 0 1-.159-.349c-.999.684-1.497 4.023-1.497 4.023-1.93-4.908.137-7.008.65-7.437a12.702 12.702 0 0 1-.042-1.555c-.05 2.5.63 4.395 1.048 5.319ZM12 11.019a1.257 1.257 0 1 0 0-2.514 1.257 1.257 0 0 0 0 2.514Zm0-5.609a14.955 14.955 0 0 1-2.694-.244h5.388c-.89.162-1.791.244-2.694.244Z"
      />
      <path
        fill="#ADABFF"
        d="M14.694 5.166a15.035 15.035 0 0 1-5.388 0s0-.003.003-.008c.378-.946.756-1.656 1.104-2.188 0-.003.003-.006.006-.009.849-1.297 1.521-1.53 1.576-1.548.002 0 .004 0 .005-.002 0 0 1.347.377 2.694 3.755ZM12 11.019a1.257 1.257 0 1 0 0-2.514 1.257 1.257 0 0 0 0 2.514Z"
      />
      <path
        fill="#fff"
        d="M8.934 6.12c-.027.077-.05.153-.075.23.025-.08.051-.158.075-.23Zm-.795 4.289c.025-1.223.228-2.593.72-4.06a15.085 15.085 0 0 0-.72 4.06Zm1.128 5.503ZM12 1.411s-1.261 1.362-2.211 3.755l-.03.075a15.003 15.003 0 0 1-.453-.075s0-.003.003-.008a9.998 9.998 0 0 1 1.104-2.188c0-.003.003-.006.006-.009.414-.607.94-1.194 1.576-1.548.002 0 .004 0 .005-.002Z"
        style={{ mixBlendMode: "soft-light" }}
      />
      <path fill="#C1B1FF" d="m13.534 17.335.411 1.058H10.02l.411-1.059-.617-.968h4.345l-.617.968s-.005 0-.008.002Z" />
      <path
        fill="#ADABFF"
        d="M11.838 13.572c-.177 1.244-.373 3.429-.015 5.598.056.333.31.327.357-.01.162-1.16.342-3.252.017-5.584-.048-.343-.311-.348-.359-.004Z"
      />
      <path fill="#C1B1FF" d="M9.756 5.241c-1.026 2.631-2.08 5.385.168 11.125h-.417s-3.134-4.893-.201-11.2l.45.075Z" />
    </svg>
  );
};
