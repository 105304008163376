export const BadgeVoterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M17.904 8.052H5.085c-.732 0-1.326.594-1.326 1.326v9.09c0 .732.594 1.326 1.326 1.326h12.819c.732 0 1.326-.594 1.326-1.326v-9.09c0-.732-.594-1.326-1.326-1.326Z"
      />
      <path
        fill="#C1B1FF"
        d="M12.146 12.688a1.536 1.536 0 0 1-1.304 0l-7.083-3.31c0-.732.594-1.326 1.326-1.326h12.819c.732 0 1.326.594 1.326 1.326l-7.085 3.31Z"
      />
      <path
        fill="#2A0FCE"
        fillRule="evenodd"
        d="M11.495 17.2c-1.428-.793-3.659-2.857-3.568-4.688.095-1.894 2.518-2.699 3.568-.726 1.05-1.973 3.472-1.168 3.567.726.091 1.831-2.141 3.893-3.567 4.689Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="m17.337 4.207 1.027 1.877 1.877 1.027-1.877 1.026-1.027 1.878-1.027-1.878-1.877-1.026 1.877-1.027 1.027-1.877Z"
      />
    </svg>
  );
};
