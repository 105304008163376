export const RequirementIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="12" fill="none" viewBox="0 0 13 12" {...props}>
      <path
        fill="currentColor"
        d="M5.314 5.457c1.467 0 2.656-1.222 2.656-2.729C7.97 1.222 6.78 0 5.314 0 3.846 0 2.657 1.222 2.657 2.728c0 1.507 1.19 2.729 2.657 2.729Zm.232 1.371C3.733 6.76 0 7.688 0 9.55v.682c0 .375.299.682.664.682h5.672c-1.64-1.883-.817-4.018-.79-4.086Zm6.031 2.735a2.727 2.727 0 0 0 .319-1.923c-.226-1.12-1.143-2.013-2.246-2.156-1.747-.232-3.222 1.276-2.99 3.07.147 1.132 1.01 2.073 2.1 2.305a2.539 2.539 0 0 0 1.873-.327l1.235 1.268a.65.65 0 0 0 .937 0 .692.692 0 0 0 0-.961l-1.229-1.276ZM9.299 9.55c-.73 0-1.329-.614-1.329-1.365 0-.75.598-1.364 1.329-1.364.73 0 1.328.614 1.328 1.364 0 .75-.598 1.365-1.328 1.365Z"
      />
    </svg>
  );
};
