export const FileWordIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="46" height="46" fill="none" viewBox="0 0 46 46" {...props}>
      <rect width="45" height="45" x=".5" y=".5" fill="#185ABD" rx="5" />
      <path
        fill="#fff"
        d="m18.137 28.051 3.398-14.355h1.898l.434 2.39-3.621 14.672h-2.039l-.07-2.707ZM16.39 13.696l2.812 14.355-.234 2.707h-2.274L12.91 13.696h3.48Zm10.464 14.296 2.778-14.296h3.48L29.34 30.758h-2.274l-.21-2.766ZM24.5 13.696l3.422 14.414-.094 2.648H25.79l-3.645-14.683.47-2.38H24.5Z"
      />
    </svg>
  );
};
