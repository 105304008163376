// Using js so can import this on the talinwid.config.js without compiling
const screens = {
  xxs: "280px",
  xs: "320px",
  sm: "600px",
  md: "960px",
  lg: "1280px",
  xl: "1440px",
  xxl: "1920px",
};

module.exports = {
  screens,
};
