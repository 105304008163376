export const ChannelIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 11.845C2 8.973 3.132 6.374 4.96 4.5l1.088 1.185a8.833 8.833 0 0 0-2.469 6.16 8.82 8.82 0 0 0 2.784 6.47L5.276 19.5C3.263 17.608 2 14.88 2 11.845ZM18.724 19.5C20.737 17.608 22 14.88 22 11.845c0-2.872-1.132-5.471-2.96-7.345l-1.087 1.185a8.833 8.833 0 0 1 2.468 6.16 8.82 8.82 0 0 1-2.784 6.47l1.087 1.185ZM7.79 11.845a4.42 4.42 0 0 0 1.234 3.08l-1.117 1.154a6.073 6.073 0 0 1-1.696-4.234c0-1.653.648-3.15 1.696-4.234l1.117 1.155a4.417 4.417 0 0 0-1.234 3.08Zm7.188-3.079a4.416 4.416 0 0 1 1.233 3.08 4.416 4.416 0 0 1-1.233 3.078l1.117 1.155a6.073 6.073 0 0 0 1.695-4.234c0-1.653-.648-3.15-1.695-4.234l-1.117 1.155ZM10.884 13a1.64 1.64 0 0 1-.366-.53 1.679 1.679 0 0 1 .326-1.819 1.535 1.535 0 0 1 2.272.041c.279.31.43.72.424 1.142a1.66 1.66 0 0 1-.463 1.126c-.289.3-.68.47-1.088.478A1.55 1.55 0 0 1 10.884 13Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
