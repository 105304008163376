export const MemberAdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="14" fill="none" viewBox="0 0 12 14" {...props}>
      <path
        fill="currentColor"
        d="M10.667 1.667H7.88A2.007 2.007 0 0 0 6 .333c-.867 0-1.6.56-1.88 1.334H1.333C.6 1.667 0 2.267 0 3v9.333c0 .734.6 1.334 1.333 1.334h9.334c.733 0 1.333-.6 1.333-1.334V3c0-.733-.6-1.333-1.333-1.333ZM6 1.5a.5.5 0 0 1 .367.167c.08.086.133.206.133.333 0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5c0-.127.053-.247.133-.333A.5.5 0 0 1 6 1.5Zm4.667 10.833H1.333V3h9.334v9.333ZM6 3.667c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 2.666a.669.669 0 0 1-.667-.666C5.333 5.3 5.633 5 6 5c.367 0 .667.3.667.667 0 .366-.3.666-.667.666Zm-4 4.314v1.02h8v-1.02C10 8.98 7.353 8.26 6 8.26c-1.353 0-4 .713-4 2.387Zm1.54-.314C4 9.96 5.127 9.587 6 9.587s2.007.373 2.46.746H3.54Z"
      />
    </svg>
  );
};
