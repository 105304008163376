export const CollapseArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="8" fill="none" viewBox="0 0 12 8" {...props}>
      <path
        fill="currentColor"
        d="M11.686.81a1.053 1.053 0 0 1 0 1.5L6.757 7.19a1.077 1.077 0 0 1-1.514 0L.314 2.31a1.051 1.051 0 0 1 0-1.5 1.077 1.077 0 0 1 1.514 0l4.177 4.125L10.172.811a1.097 1.097 0 0 1 1.514 0Z"
      />
    </svg>
  );
};
