export const MemberReaderIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" fill="none" viewBox="0 0 12 12" {...props}>
      <path
        fill="currentColor"
        d="M6 2.857c2.067 0 3.91 1.217 4.81 3.143C9.91 7.926 8.068 9.143 6 9.143 3.933 9.143 2.09 7.926 1.19 6 2.09 4.074 3.932 2.857 6 2.857Zm0-1.143C3.273 1.714.944 3.491 0 6c.944 2.509 3.273 4.286 6 4.286S11.056 8.509 12 6c-.944-2.509-3.273-4.286-6-4.286Zm0 2.858c.753 0 1.364.64 1.364 1.428 0 .79-.611 1.43-1.364 1.43-.753 0-1.364-.64-1.364-1.429 0-.788.611-1.428 1.364-1.428Zm0-1.143C4.647 3.429 3.545 4.583 3.545 6c0 1.417 1.102 2.572 2.455 2.572S8.455 7.417 8.455 6 7.353 3.43 6 3.43Z"
      />
    </svg>
  );
};
