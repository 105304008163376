export const TelegramIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="url(#TelegramIcon_svg__a)" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.074 11.905a428.706 428.706 0 0 1 5.249-2.26c2.5-1.04 3.019-1.221 3.357-1.227a.592.592 0 0 1 .349.105.379.379 0 0 1 .128.243c.012.07.027.23.015.355-.135 1.423-.722 4.876-1.02 6.47-.126.675-.374.9-.615.923-.522.048-.92-.345-1.426-.677-.792-.52-1.24-.843-2.008-1.35-.889-.585-.313-.907.194-1.433.132-.137 2.435-2.232 2.48-2.422.005-.024.01-.112-.042-.16-.053-.046-.13-.03-.186-.017-.08.018-1.345.854-3.797 2.509-.359.247-.684.367-.976.36-.321-.006-.94-.181-1.399-.33-.563-.184-1.011-.28-.972-.592.02-.162.243-.328.67-.497Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id="TelegramIcon_svg__a" x1="12" x2="12" y1="3" y2="20.866" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
