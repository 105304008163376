export const BadgeStakeholderTwoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#C1B1FF"
        d="m10.246 3.602.938-1.188v1.188h2.093c.453 0 .88.216 1.149.58l1.93 2.62c.174.236.384.518.384.81 0 0 .146 1.439-.117 1.805-.144.201-.938.312-.938.312h-.813l-.862-.71a.725.725 0 0 0-.46-.164h-.99l-.292.625 3.333 4.15a1.137 1.137 0 0 1-.888 1.851H9.122l-.5-.616a2.53 2.53 0 0 1-.564-1.591V6.582c0-.357.086-.708.25-1.024l.422-.813a1.5 1.5 0 0 1 .588-.612l.928-.531Z"
      />
      <path fill="#fff" d="M16.137 18.48H8.262v2.311h7.875V18.48Z" />
      <path fill="#C1B1FF" d="M16.137 20.791H8.262v.796h7.875v-.796Zm.002-2.311H8.262l.86-1.5h6.157l.86 1.5Z" />
      <path
        fill="#fff"
        d="M8.661 7.106H7.68a.46.46 0 0 0 0 .92h.981a.46.46 0 1 0 0-.92Zm0 1.041H7.68a.46.46 0 0 0 0 .92h.981a.46.46 0 1 0 0-.92Zm0 1.041H7.68a.46.46 0 0 0 0 .92h.981a.46.46 0 1 0 0-.92Zm6.618 6.292H9.122v1.5h6.157v-1.5Z"
      />
    </svg>
  );
};
