export const BadgeTrendsetterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M19.764 18.284c0 1.383-.44 2.672-1.198 3.762A11.968 11.968 0 0 1 12 24c-.963 0-1.9-.114-2.799-.328a11.892 11.892 0 0 1-3.762-1.625 6.53 6.53 0 0 1-1.202-3.764c0-1.8.809-3.565 1.971-5.041 1.271-1.614 3.342-2.628 4.546-4.281 1.294-1.776 1.67-3.984.468-5.871.037.018.335.182.772.512.468.358 1.098.909 1.739 1.7 1.157 1.429 1.849 3.14 1.849 4.886 0 1.851-.902 3.194-1.873 4.788-.722 1.179-.467 2.931 1.495 2.931 1.254 0 1.861-.88 1.99-1.834.14-1.028.22-2.082-.094-3.095 1.632 1.289 2.664 3.189 2.664 5.306Z"
      />
      <path
        fill="#C1B1FF"
        d="M16.15 23.262a12.043 12.043 0 0 1-6.95.41c-1.473-1.68-2.514-4.236.423-7.16 0 0-.536 3.25 4.956 5.293 0 0 1.208.409 1.572 1.457Z"
      />
      <path
        fill="#fff"
        d="m5.484 5.649.82 1.498 1.499.82-1.498.818-.821 1.499-.819-1.499-1.498-.819 1.498-.819.819-1.498Z"
      />
      <path
        fill="#C1B1FF"
        d="M16.623 8.223s.948 1.062.32 2.45c0 0-.452.816-.235 1.576 0 0-1.015-.444-.439-1.824a4.1 4.1 0 0 1 .123-.26c.087-.169.447-.958.231-1.942ZM10.04 4.469s1.116 1.797-.806 4.276c0 0 .6.125 1.275-.987.637-1.051.502-2.452-.429-3.256l-.04-.035v.002Z"
      />
    </svg>
  );
};
