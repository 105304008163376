export const ReportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.939 16.75 10.36 3.625a.417.417 0 0 0-.722 0L2.061 16.75c-.16.278.04.625.361.625h15.156c.32 0 .521-.347.36-.625ZM11.443 3a1.667 1.667 0 0 0-2.886 0L.979 16.125a1.667 1.667 0 0 0 1.443 2.5h15.156a1.667 1.667 0 0 0 1.443-2.5L11.443 3Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 7.167c.345 0 .625.28.625.625v3.75a.625.625 0 1 1-1.25 0v-3.75c0-.346.28-.625.625-.625Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M10.833 14.667a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0Z" />
    </svg>
  );
};
