export const ArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="11" height="14" fill="none" viewBox="0 0 11 14" {...props}>
      <path
        fill="currentColor"
        d="M5.243 1 5.95.293a1 1 0 0 0-1.414 0L5.243 1Zm3.535 4.95a1 1 0 0 0 1.415-1.414L8.777 5.95ZM.293 4.536A1 1 0 0 0 1.707 5.95L.293 4.536ZM6.25 1a1 1 0 0 0-2 0h2Zm-2 12a1 1 0 1 0 2 0h-2Zm.286-11.293L8.778 5.95l1.415-1.414L5.95.293 4.536 1.707ZM1.707 5.95 5.95 1.707 4.536.293.293 4.536 1.707 5.95ZM4.25 1v12h2V1h-2Z"
      />
    </svg>
  );
};
