export const UserFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="20" fill="none" viewBox="0 0 22 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.533 12.35a4.117 4.117 0 0 0-4.116 4.117V18.2a.65.65 0 0 0 1.147.418A3.594 3.594 0 0 0 7.4 20h7.2c1.151 0 2.177-.54 2.836-1.382a.649.649 0 0 0 1.147-.418v-1.733a4.116 4.116 0 0 0-4.116-4.117H7.533ZM11 1.95a4.117 4.117 0 1 0 0 8.233 4.117 4.117 0 0 0 0-8.233Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
