export const MoreRepliesIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
      <circle cx="8.7" cy="8" r="5.5" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M11.7 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      />
    </svg>
  );
};
