import React from "react";

export const ChevronRoundedDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.307 9.713A.75.75 0 0 1 8 9.25h8a.75.75 0 0 1 .53 1.28l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1-.163-.817Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
