export const VisibilityOffIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.318 9.379a3.75 3.75 0 0 0 5.303 5.303l-1.06-1.06a2.25 2.25 0 0 1-3.181-3.181L9.317 9.378Zm4.925 2.803a2.25 2.25 0 0 0-2.425-2.425l-1.232-1.231a3.75 3.75 0 0 1 4.888 4.888l-1.231-1.232Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M2.884 3.53a.75.75 0 0 1 1.06 0l16.66 16.66a.75.75 0 0 1-1.061 1.06L2.883 4.59a.75.75 0 0 1 0-1.06Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.023 6.084C2.773 8.454 1 12 1 12s4 8 11 8c2.306 0 4.287-.868 5.906-2.033l-1.076-1.076c-1.386.952-3.005 1.609-4.83 1.609-2.9 0-5.282-1.66-7.03-3.526A18.174 18.174 0 0 1 2.721 12 18.174 18.174 0 0 1 4.97 9.026a13.677 13.677 0 0 1 2.128-1.867L6.023 6.084ZM18.02 15.96c.358-.316.695-.647 1.012-.985A18.18 18.18 0 0 0 21.278 12a18.18 18.18 0 0 0-2.248-2.974C17.283 7.16 14.9 5.5 12 5.5c-1.298 0-2.492.333-3.572.867l-1.111-1.11C8.679 4.502 10.245 4 12 4c7 0 11 8 11 8s-1.373 2.746-3.919 5.02l-1.061-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
