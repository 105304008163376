const fontSize = {
  "4xs": ["0.375rem", { lineHeight: "1.4" }],
  "3xs": ["0.5rem", { lineHeight: "1.4" }],
  "2xs": ["0.625rem", { lineHeight: "1.4" }],
  xs: ["0.75rem", { lineHeight: "1.4" }],
  base: ["0.9375rem", { lineHeight: "1.4" }],
  lg: ["1.125rem", { lineHeight: "1.25" }],
  xl: ["1.313rem", { lineHeight: "1.15" }],
  "2xl": ["1.5rem", { lineHeight: "1.15" }],
  "3xl": ["3rem", { lineHeight: "1.15" }],
  "4xl": ["3.750rem", { lineHeight: "1.15" }],
  //4xl deprecate will remove on seperate pr
  "4xl-deprecate": ["1.75rem", { lineHeight: "1.05" }],

  // Last updated 20 May 2023
  // Follow Design system definition https://www.figma.com/design/C7TLLuNBlkq5VzU6IDADVf/%F0%9F%8C%90-General-2022?node-id=12-11006&m=dev

  // headline-1: 60px, line-height: 69px
  "headline-1": ["3.75rem", { lineHeight: "4.3125rem", fontWeight: 300 }],

  // headline-2: 48px, line-height: 55.2px
  "headline-2": ["3rem", { lineHeight: "3.45rem", fontWeight: 400 }],

  // headline-3: 24px, line-height: 27.6px
  "headline-3": ["1.5rem", { lineHeight: "1.725rem", fontWeight: 400 }],

  // headline-4: 21px, line-height: 24.15px
  "headline-4": ["1.3125rem", { lineHeight: "1.509375rem", fontWeight: 700 }],

  // headline-5: 21px, line-height: 24.1px
  "headline-5": ["1.3125rem", { lineHeight: "1.50625rem", fontWeight: 400 }],

  // headline-6: 18px, line-height: 22.5px
  "headline-6": ["1.125rem", { lineHeight: "1.40625rem", fontWeight: 500 }],

  // headline-7: 18px, line-height: 22.5px
  "headline-7": ["1.125rem", { lineHeight: "1.40625rem", fontWeight: 400 }],

  // headline-8: 16px, line-height: 22.4px
  "headline-8": ["1rem", { lineHeight: "1.4rem", fontWeight: 400 }],

  // subhead-1: 15px, line-height: 21px
  "subhead-1": ["0.9375rem", { lineHeight: "1.3125rem", fontWeight: 700 }],

  // subhead-2: 15px, line-height: 21px
  "subhead-2": ["0.9375rem", { lineHeight: "1.3125rem", fontWeight: 500 }],

  // body-1: 15px, line-height: 21px
  "body-1": ["0.9375rem", { lineHeight: "1.3125rem", fontWeight: 400 }],

  // body-2: 14px, line-height: 19.6px
  "body-2": ["0.875rem", { lineHeight: "1.225rem", fontWeight: 400 }],

  // body-3: 12px, line-height: 16.8px
  "body-3": ["0.75rem", { lineHeight: "1.05rem", fontWeight: 400 }],

  // textfield-1: 15px, line-height: 21px
  "textfield-1": ["0.9375rem", { lineHeight: "1.3125rem", fontWeight: 400 }],

  // textfield-2: 14px, line-height: 19.6px
  "textfield-2": ["0.875rem", { lineHeight: "1.225rem", fontWeight: 400 }],

  // textfield-3: 12px, line-height: 16.8px
  "textfield-3": ["0.75rem", { lineHeight: "1.05rem", fontWeight: 400 }],

  // caption-1: 12px, line-height: 16.8px
  "caption-1": ["0.75rem", { lineHeight: "1.05rem", fontWeight: 700 }],

  // caption-2: 12px, line-height: 16.8px
  "caption-2": ["0.75rem", { lineHeight: "1.05rem", fontWeight: 500 }],

  // overline-1: 15px, line-height: 21px
  "overline-1": ["0.9375rem", { lineHeight: "1.3125rem", fontWeight: 700, letterSpacing: "0.078125rem" }],

  // overline-2: 12px, line-height: 16.8px
  "overline-2": ["0.75rem", { lineHeight: "1.05rem", fontWeight: 400, letterSpacing: "0.025rem" }],

  // overline-3: 12px, line-height: 16.8px
  "overline-3": ["0.75rem", { lineHeight: "1.05rem", fontWeight: 500, letterSpacing: "0.025rem" }],

  // overline-4: 10px, line-height: 16.8px
  "overline-4": ["0.625rem", { lineHeight: "1.05rem", fontWeight: 500, letterSpacing: "0.09375rem" }],
};

module.exports = {
  fontSize,
};
