export const ReactionIlluminatingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path d="M14 20.918a7.257 7.257 0 1 0 0-14.513 7.257 7.257 0 0 0 0 14.513Z" fill="#FFD600" />
      <path
        d="m18.13 19.623-.525.572a2.509 2.509 0 0 0-.665 1.715v1.843a1.827 1.827 0 0 1-1.832 1.832h-2.216a1.827 1.827 0 0 1-1.832-1.832V21.91c0-.63-.245-1.248-.665-1.715l-.525-.572h8.26Z"
        fill="#6D28FF"
      />
      <path
        d="M15.995 22.365h-4.06a.373.373 0 0 0 0 .747h4.06a.373.373 0 0 0 0-.747Zm0 1.225h-4.06a.373.373 0 1 0 0 .747h4.06a.373.373 0 0 0 0-.747Z"
        fill="#fff"
      />
      <path
        d="M3.897 14.548h-1.89m4.853-7.14L5.518 6.067M14 4.445v-1.89m7.14 4.842 1.342-1.33m1.621 8.481h1.89"
        stroke="#FFD600"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
