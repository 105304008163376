export const ColorfulGlobeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.483 14.418c-.08-.5-.438-.941-.787-1.374a7.202 7.202 0 0 1-.458-.608A1.493 1.493 0 0 1 6 11.625v-.375L3.413 8.34a5.627 5.627 0 0 0 4.07 6.078ZM10.5 3.578a5.627 5.627 0 0 1 1.5 10.18v-.634c0-.433-.184-.824-.478-1.098a28 28 0 0 0-.09-.083c-.435-.403-.932-.863-.932-1.444A1.5 1.5 0 0 0 9 9H5.25V7.5h1.5a.75.75 0 0 0 .75-.75V6H9a1.5 1.5 0 0 0 1.5-1.5v-.923ZM15.75 9a6.75 6.75 0 1 1-13.5 0 6.75 6.75 0 0 1 13.5 0Z"
        fill="url(#globe__a)"
      />
      <defs>
        <linearGradient id="globe__a" x1="2.524" y1="9" x2="16.412" y2="12.945" gradientUnits="userSpaceOnUse">
          <stop stop-color="#00BFFF" />
          <stop offset="1" stop-color="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
