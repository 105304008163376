export const UnattendedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 17v4m4-5 2 2M6 18l2-2m9-4h4M3 12h4m9-4 2-2M6 6l2 2m4-5v4"
      />
    </svg>
  );
};
