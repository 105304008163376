export const SuggestionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 3.5h9.334c.184 0 .333.15.333.333v9.334c0 .184-.15.333-.333.333H3.333A.333.333 0 0 1 3 13.167V3.833c0-.184.15-.333.333-.333ZM2 3.833C2 3.097 2.597 2.5 3.333 2.5h9.334c.736 0 1.333.597 1.333 1.333v9.334c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 13.167V3.833Zm7.667.334c-.737 0-1.334.597-1.334 1.333v1.333c0 .737.597 1.334 1.334 1.334H11c.736 0 1.333-.597 1.333-1.334V5.5c0-.736-.597-1.333-1.333-1.333H9.667Z"
        fill="currentColor"
      />
    </svg>
  );
};
