export const TrashIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="16" fill="none" viewBox="0 0 12 16" {...props}>
      <path
        fill="currentColor"
        d="M9.333 5.5v8.333H2.666V5.5h6.667Zm-1.25-5H3.916l-.833.833H.167V3h11.666V1.333H8.916L8.083.5ZM11 3.833H1v10c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10Z"
      />
    </svg>
  );
};
