export const ArrowBlockFilledUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1848 7.21449L8.35623 2.80182C8.15951 2.62205 7.84057 2.62205 7.64385 2.80182L2.81526 7.21449C2.49793 7.50448 2.72267 8.00033 3.17144 8.00033H6.00004L6.00004 13.0003C6.00004 13.1844 6.14928 13.3337 6.33337 13.3337H9.66671C9.8508 13.3337 10 13.1844 10 13.0003V8.00033H12.8286C13.2774 8.00033 13.5022 7.50448 13.1848 7.21449Z"
        fill="currentColor"
      />
    </svg>
  );
};
