export const ChevronMiniRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.726 6.884a.72.72 0 0 0 0 1.047L9.883 10l-2.157 2.069a.72.72 0 0 0 0 1.047c.302.29.79.29 1.092 0l2.566-2.461c.655-.655.655-.655 0-1.31L8.818 6.884a.795.795 0 0 0-1.092 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
