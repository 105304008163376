export const ManageBadgeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 2.292a5.208 5.208 0 1 0 0 10.416 5.208 5.208 0 0 0 0-10.416ZM3.542 7.5a6.458 6.458 0 1 1 12.916 0 6.458 6.458 0 0 1-12.916 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.052 11.884c.34-.059.664.17.722.51l1.009 5.833a.625.625 0 0 1-.878.674L10 17.103 6.095 18.9a.625.625 0 0 1-.877-.674L6.226 12.4a.625.625 0 1 1 1.232.213l-.806 4.655 3.087-1.421a.625.625 0 0 1 .523 0l3.086 1.421-.805-4.661a.625.625 0 0 1 .51-.723Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
