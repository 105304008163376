export const ResponseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.99 11.667h9.343c.184 0 .334-.15.334-.334v-8A.333.333 0 0 0 13.333 3H2.667a.333.333 0 0 0-.334.333v9.622l1.657-1.288Zm-2.119 2.915a.333.333 0 0 1-.538-.264V3.334C1.333 2.597 1.93 2 2.667 2h10.666c.737 0 1.334.597 1.334 1.333v8c0 .737-.597 1.334-1.334 1.334h-9l-2.462 1.915Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h4.333a.5.5 0 1 1 0 1H4.5a.5.5 0 0 1-.5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
