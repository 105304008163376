import { useId } from "react";

export const WatchStatsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  return (
    <svg width="17" height="17" fill="none" viewBox="0 0 17 17" {...props}>
      <path
        fill={`url(#${id1})`}
        d="M7.899 2.861a.681.681 0 0 1 1.202 0L10.75 5.82l3.2.725c.522.118.728.78.373 1.196l-2.183 2.552.33 3.406c.054.555-.485.964-.973.74L8.5 13.056l-2.997 1.38c-.488.225-1.027-.184-.973-.739l.33-3.406L2.678 7.74c-.356-.416-.15-1.078.371-1.196l3.201-.725L7.9 2.861Z"
      />
      <defs>
        <linearGradient id={id1} x1="2.744" x2="15.088" y1="8.5" y2="12.006" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
