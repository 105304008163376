export const NoteSquareIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" fill="none" viewBox="0 0 16 17" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.667 14.167h10.666c.184 0 .334-.15.334-.334V7.581L8.919 2.833H2.667a.333.333 0 0 0-.334.334v10.666c0 .184.15.334.334.334Zm0-12.334c-.737 0-1.334.597-1.334 1.334v10.666c0 .737.597 1.334 1.334 1.334h10.666c.737 0 1.334-.597 1.334-1.334v-6.39a.667.667 0 0 0-.196-.472L9.53 2.03a.667.667 0 0 0-.472-.196h-6.39Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.167 7.167V2.5h1v4.667c0 .092.074.166.166.166H14v1H9.333a1.167 1.167 0 0 1-1.166-1.166Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
