export const FilterFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0.25C0.726467 0.25 0.474626 0.398916 0.342818 0.638598C0.211011 0.87828 0.220143 1.17071 0.366649 1.4017L6.25 10.6778V15C6.25 15.2841 6.4105 15.5438 6.66459 15.6708L10.6646 17.6708C10.8971 17.7871 11.1732 17.7746 11.3943 17.638C11.6154 17.5013 11.75 17.2599 11.75 17V10.6778L17.6334 1.4017C17.7799 1.17071 17.789 0.87828 17.6572 0.638598C17.5254 0.398916 17.2735 0.25 17 0.25H1Z"
        fill="currentColor"
      />
    </svg>
  );
};
