export const UpvotePillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" fill="none" viewBox="0 0 34 34" {...props}>
      <circle cx="17" cy="17" r="16.5" stroke="currentColor" />
      <path
        d="M18.06 9.916a1 1 0 0 0-1.492 0l-5.576 6.254c-.575.644-.117 1.665.746 1.665h2.298v4.837a1 1 0 0 0 1 1h4.556a1 1 0 0 0 1-1v-4.837h2.299c.863 0 1.32-1.02.746-1.665L18.06 9.916Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m16.568 9.916.746.666-.746-.666Zm1.493 0-.747.666.747-.666Zm-7.07 6.254-.745-.666.746.666Zm3.045 1.665h1v-1h-1v1Zm6.556 0v-1h-1v1h1Zm3.045-1.665.746-.666-.746.666Zm-6.323-5.588 1.493-1.331a2 2 0 0 0-2.985 0l1.492 1.331Zm-5.576 6.253 5.576-6.253-1.492-1.331-5.576 6.253 1.492 1.331Zm0 0-1.492-1.33c-1.15 1.288-.235 3.33 1.492 3.33v-2Zm2.298 0h-2.298v2h2.298v-2Zm1 5.837v-4.837h-2v4.837h2Zm0 0h-2a2 2 0 0 0 2 2v-2Zm4.556 0h-4.556v2h4.556v-2Zm0 0v2a2 2 0 0 0 2-2h-2Zm0-4.837v4.837h2v-4.837h-2Zm3.299-1h-2.299v2h2.299v-2Zm0 0v2c1.727 0 2.642-2.042 1.492-3.33l-1.492 1.33Zm-5.577-6.253 5.576 6.253 1.493-1.33-5.576-6.254-1.493 1.331Z"
      />
    </svg>
  );
};
