import React from "react";

export const LinkOutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="10" height="10" fill="none" viewBox="0 0 10 10" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.625 4.375 8.75 1.25M6.667 1.25H8.75v2.083M8.75 5.833v2.084a.833.833 0 0 1-.833.833H2.083a.833.833 0 0 1-.833-.833V2.083a.833.833 0 0 1 .833-.833h2.084"
      />
    </svg>
  );
};
