export const MarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.348 5.5 20.41 12l-4.062 6.5H3.679v-13h12.669Zm.277-1.5a1 1 0 0 1 .848.47l4.375 7a1 1 0 0 1 0 1.06l-4.375 7a1 1 0 0 1-.848.47H3.179a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h13.446Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
