import React from "react";

export const ClockIconDashed = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} fill="none" {...props}>
      <path
        d="m10.696 13.72.708 1.233a6.954 6.954 0 0 1-2.196.595v-1.431a5.488 5.488 0 0 0 1.488-.397ZM2.883 9.208h-1.43c.077.78.282 1.516.594 2.196l1.233-.708a5.488 5.488 0 0 1-.397-1.488Zm7.813-5.928.708-1.233a6.904 6.904 0 0 0-2.196-.595v1.43c.525.065 1.02.2 1.488.397Zm3.421 4.512h1.431a6.906 6.906 0 0 0-.595-2.196l-1.232.708c.198.468.332.963.396 1.488ZM6.304 13.72l-.708 1.233a6.95 6.95 0 0 0 2.196.595v-1.431a5.488 5.488 0 0 1-1.488-.397ZM7.792 2.883V1.452a6.904 6.904 0 0 0-2.196.595l.708 1.232a5.488 5.488 0 0 1 1.488-.396Zm5.213 2.196 1.233-.716a7.215 7.215 0 0 0-1.608-1.608l-.716 1.233c.418.319.78.68 1.091 1.09ZM3.28 6.304l-1.233-.708a6.905 6.905 0 0 0-.595 2.196h1.431c.064-.525.198-1.02.397-1.488Zm10.837 2.904a5.487 5.487 0 0 1-.397 1.488l1.233.708a6.95 6.95 0 0 0 .595-2.196h-1.43Zm-2.196 3.797.716 1.232a7.212 7.212 0 0 0 1.608-1.607l-1.233-.716c-.319.418-.68.78-1.09 1.09ZM5.08 3.995l-.709-1.24A7.1 7.1 0 0 0 2.763 4.37l1.232.716a5.44 5.44 0 0 1 1.084-1.091ZM3.995 11.92l-1.232.709a7.214 7.214 0 0 0 1.607 1.607l.716-1.232a5.455 5.455 0 0 1-1.09-1.084Zm5.213-6.963H7.792V8.79l3.039 3.04.998-1-2.62-2.62V4.958Z"
        fill="currentColor"
      />
    </svg>
  );
};
