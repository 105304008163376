export const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#1877F2"
        d="M21 12a9 9 0 1 0-10.406 8.89v-6.289H8.309V12h2.285v-1.983c0-2.255 1.343-3.501 3.4-3.501.984 0 2.014.175 2.014.175v2.215h-1.135c-1.118 0-1.467.694-1.467 1.406V12h2.496l-.399 2.601h-2.097v6.29C17.71 20.215 21 16.492 21 12Z"
      />
      <path
        fill="#fff"
        d="m15.503 14.602.4-2.602h-2.497v-1.688c0-.712.349-1.406 1.467-1.406h1.135V6.691s-1.03-.175-2.015-.175c-2.056 0-3.4 1.246-3.4 3.501V12H8.31v2.602h2.285v6.289a9.066 9.066 0 0 0 2.812 0V14.6h2.097Z"
      />
    </svg>
  );
};
