export const HourglassIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
      <path
        fill="currentcolor"
        d="M3.5 1.167v3.5h.006l-.006.006L5.833 7 3.5 9.334l.006.005H3.5v3.495h7V9.339h-.006l.006-.005L8.167 7 10.5 4.673l-.006-.006h.006v-3.5h-7Zm5.833 8.458v2.042H4.667V9.625L7 7.292l2.333 2.333ZM7 6.71 4.667 4.375V2.334h4.666v2.041L7 6.71Z"
      />
    </svg>
  );
};
