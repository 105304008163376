export const RoleContributorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" {...props}>
      <path d="M12 10.8V6.6h-1.2v4.2H1.2V1.2h4.2V0H1.2A1.2 1.2 0 0 0 0 1.2v9.6A1.2 1.2 0 0 0 1.2 12h9.6a1.2 1.2 0 0 0 1.2-1.2Z" />
      <path
        fillRule="evenodd"
        d="M10.307.434a1.472 1.472 0 0 0-2.09 0L3.196 5.457a2.428 2.428 0 0 0-.794 1.616L2.4 9.002v.6h2.48a2.43 2.43 0 0 0 1.695-.828l4.992-4.99a1.477 1.477 0 0 0 0-2.09l-1.26-1.26ZM4.838 8.403c.32-.023.62-.172.859-.446L9.334 4.32 7.681 2.667 4.018 6.33a1.243 1.243 0 0 0-.418.79v1.282h1.238ZM8.53 1.819l1.652 1.652.537-.536a.277.277 0 0 0 0-.392L9.457 1.281a.274.274 0 0 0-.389 0l-.538.538Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
