export const ShareIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm12-7.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM18 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m8.263 10.03 6.803-3.401c.108.506.343.965.671 1.34l-6.803 3.402a2.99 2.99 0 0 0-.671-1.34Zm.671 2.599a2.99 2.99 0 0 1-.671 1.34l6.803 3.403a2.99 2.99 0 0 1 .671-1.342l-6.803-3.402Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm0 1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
