export const BadgePioneerIcon = (
  props: React.SVGProps<SVGSVGElement> & { outerBgColor?: string; borderColor?: string; insideBgColor?: string },
) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill={props.outerBgColor || "#2A0FCE"}
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill={props.borderColor || "#C1B1FF"}
        d="M20.51 4.817c.304.08.338.496.055.634-1.218.594-2.16 1.638-2.781 2.829-1.161 2.226-.56 5.013-1.618 7.259-.521 1.105-1.678 1.893-2.813 2.344-2.236.888-5.183-.214-6.039-2.485-.917-2.429-.524-5.367 1.077-7.413 1.338-1.71 3.183-2.397 5.214-2.903 2.224-.555 4.656-.856 6.906-.264l-.002-.001Z"
      />
      <path
        fill={props.insideBgColor || "#fff"}
        d="M19.923 4.782c.305.081.339.497.056.635-1.218.594-2.16 1.638-2.781 2.829-1.162 2.226-.854 4.813-1.913 7.059-.52 1.105-1.383 2.092-2.518 2.542-2.237.888-5.183-.215-6.04-2.486-.916-2.428-.523-5.367 1.077-7.413 1.338-1.71 3.184-2.397 5.214-2.902 2.225-.555 4.656-.857 6.906-.264h-.001Z"
      />
      <path
        fill={props.borderColor || "#C1B1FF"}
        d="M14.43 9.528c-.543.273-1.185.277-1.779.208a5.525 5.525 0 0 1-.463-.076 12.647 12.647 0 0 0-1.582 2.337c.551-.005 1.109-.107 1.572-.408-.424.435-1.027.651-1.612.78a6.527 6.527 0 0 1-.167.033c-.916 1.86-1.453 3.926-1.76 5.97a31.317 31.317 0 0 0-.39 4.467l-.076.003c-.14-2.788.044-5.639.774-8.343-.187-.767-.274-1.611.06-2.343-.12.532-.025 1.083.156 1.596.215-.695.468-1.379.764-2.048a8.78 8.78 0 0 1 .699-1.255c-.162-.726-.216-1.506.096-2.19-.133.59-.006 1.2.216 1.758a7.432 7.432 0 0 1 1.981-1.82 6.081 6.081 0 0 1 2.151-.814l.023.072a9.124 9.124 0 0 0-2.277 1.528c-.1.1-.2.201-.296.305.602.246 1.273.397 1.91.24ZM8.2 5.646l.627 1.146 1.146.627-1.146.627L8.2 9.192l-.627-1.146-1.146-.627 1.146-.627.627-1.146Z"
      />
    </svg>
  );
};
