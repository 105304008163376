export const KickMemberIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.61 12.105c.485-.646 1.188-1.063 1.973-1.063H8.75c.786 0 1.488.417 1.973 1.063.482.643.735 1.486.735 2.34v1.388a.625.625 0 1 1-1.25 0v-1.389c0-.62-.186-1.19-.485-1.589-.296-.395-.654-.563-.973-.563H4.583c-.319 0-.676.168-.973.563-.299.4-.485.97-.485 1.59v1.388a.625.625 0 1 1-1.25 0v-1.389c0-.853.253-1.696.735-2.339Zm4.057-7.313a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75ZM3.542 6.667a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0Zm14.792 3.125c0 .345-.28.625-.625.625h-5.417a.625.625 0 0 1 0-1.25h5.417c.345 0 .625.28.625.625Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
