export const SplitPostIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
      <svg width="8" height="10" x="9" y="3" viewBox="0 0 8 10">
        <path
          fill="currentColor"
          d="M7.801 5.179a.495.495 0 0 0-.108-.532L4.166 1.121a.495.495 0 0 0-.7.7l2.681 2.681H1.01a.495.495 0 0 0 0 .99h5.137l-2.68 2.682a.495.495 0 0 0 .7.7l3.526-3.527A.479.479 0 0 0 7.8 5.18Z"
        />
      </svg>
      <svg width="2" height="16" x="7" viewBox="0 0 2 16">
        <path
          fill="currentColor"
          d="M1.42 0a.495.495 0 0 0-.496.495v15.01a.495.495 0 1 0 .99 0V.495A.495.495 0 0 0 1.42 0Z"
        />
      </svg>
      <svg width="8" height="10" y="3" viewBox="0 0 8 10">
        <path
          fill="currentColor"
          d="M6.827 4.502H1.69l2.681-2.68a.495.495 0 0 0-.7-.7L.145 4.646a.495.495 0 0 0 0 .7L3.67 8.874a.489.489 0 0 0 .802-.163.499.499 0 0 0-.102-.537L1.69 5.493h5.137a.495.495 0 0 0 0-.99Z"
        />
      </svg>
    </svg>
  );
};
