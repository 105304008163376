import { useEffect } from "react";

import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import Head from "next/head";
import "react-toastify/dist/ReactToastify.min.css";
import "tailwindcss/tailwind.css";

import "@/src/components/App.css";
// Todo move to styled component
import MaintenancePage from "@/src/components/sembly-ui/components/MaintenancePage/MaintenancePage";
import NoSSR from "@/src/components/sembly-ui/components/NoSSR/NoSSR";
import "@/src/components/sembly-ui/components/RichEditor/themes/SemblyTheme.css";
import SemblyToastContainer from "@/src/components/sembly-ui/components/SemblyToast/SemblyToastContainer";
import { APP_TITLE, META_APPLICATION_NAME, META_AUTHOR, META_COPYRIGHT } from "@/src/constants/AppConstant";
import useWorkbox from "@/src/hooks/useWorkbox";
import "@/src/index.css";
import * as ric from "@/src/utils/requestIdleCallback";

const IS_MAINTENANCE_MODE = false;

const App = ({ Component, pageProps }: AppProps) => {
  useWorkbox();

  useEffect(() => {
    ric.polyfill();
  }, []);

  return (
    <>
      <Head>
        {/* Expected hard coded string */}
        {/* Note: We can learn about what meta tags are needed from other tech company sites */}
        <title key="title">{APP_TITLE}</title>
        <meta http-equiv="Content-Language" content="en_US" />
        <meta name="google" content="notranslate" />
        <meta
          key="viewport"
          name="viewport"
          content="maximum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta key="author" name="author" content={META_AUTHOR} />
        <meta key="copyright" name="copyright" content={META_COPYRIGHT} />
        <meta key="application-name" name="application-name" content={META_APPLICATION_NAME} />
        <meta key="og:site_name" property="og:site_name" content={META_APPLICATION_NAME} />
      </Head>
      {IS_MAINTENANCE_MODE ? <MaintenancePage /> : <Component {...pageProps} />}
      <NoSSR>
        <SemblyToastContainer />
      </NoSSR>
    </>
  );
};

export default appWithTranslation(App);
