export const ReactIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.75a8.25 8.25 0 1 0 8.046 6.416.75.75 0 1 1 1.462-.332A9.75 9.75 0 0 1 12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25a9.78 9.78 0 0 1 2.166.242.75.75 0 1 1-.332 1.462A8.279 8.279 0 0 0 12 3.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5H15a.75.75 0 0 1-.75-.75Zm-6 0A.75.75 0 0 1 9 9.25h.01a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Zm10-8a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 5.75a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 .75.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.08 16.731c-2.8 0-4.5-2.2-4.6-2.3-.2-.3-.2-.8.1-1 .3-.2.8-.2 1 .1 0 0 1.3 1.7 3.4 1.7 2.1 0 3.4-1.7 3.4-1.7.2-.3.7-.4 1-.1.3.2.4.7.1 1 .1 0-1.6 2.3-4.4 2.3Z"
      />
    </svg>
  );
};
