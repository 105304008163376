export const LatestIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.416 4H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-8.416a4.969 4.969 0 0 1-1.5.391V19a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5h8.025a4.969 4.969 0 0 1 .391-1.5Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M21 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
  );
};
