export const UnfoldLessIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="7" height="11" fill="none" viewBox="0 0 7 11" {...props}>
      <path
        fill="currentColor"
        d="m1.3 10.95-.525-.525 2.85-2.85 2.85 2.85-.525.525-2.325-2.325L1.3 10.95Zm2.325-7.575L.775.525 1.3 0l2.325 2.325L5.95 0l.525.525-2.85 2.85Z"
      />
    </svg>
  );
};
