export const UpvoteFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fill="#fff"
        stroke="currentColor"
        strokeWidth="2"
        d="M14 18h-4c-.55 0-1-.45-1-1v-5H7.41c-.89 0-1.33-1.08-.7-1.71L11.3 5.7a.996.996 0 0 1 1.41 0l4.59 4.59c.63.63.18 1.71-.71 1.71H15v5c0 .55-.45 1-1 1Z"
      />
    </svg>
  );
};
