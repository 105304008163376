import { useEffect, useState } from "react";

import getConfig from "next/config";
import { toast } from "react-toastify";
import type { WorkboxLifecycleWaitingEvent } from "workbox-window";
import { Workbox } from "workbox-window";

import UpdateToast from "@/src/components/sembly-ui/components/UpdateToast/UpdateToast";
import { captureException } from "@/src/utils/logging/SentryLogging";

const { publicRuntimeConfig } = getConfig();

// 1 min
const CHECK_UPDATE_INTERVAL = 60 * 1000;
const TOAST_ID = "UPDATE_TOAST";

// 2 min after refresh
const bufferTime = 60 * 1000 * 2;

const LOCAL_STORAGE_KEY = "sembly-sw-version";

async function getServerHashVersion() {
  try {
    const appInfo = await fetch("/api/info").then(response => response.json());
    return appInfo?.hash;
  } catch (e) {
    captureException(e as Error);
    return "";
  }
}

async function showUpdateLog(updateOnbackground?: boolean) {
  if (window.localStorage) {
    const currentSWVersion = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    const latestSWVersion = await getServerHashVersion();
    if (updateOnbackground) {
      // eslint-disable-next-line no-console
      console.log(`Update on background. From ${currentSWVersion} to Version: ${latestSWVersion}`);
    } else {
      // eslint-disable-next-line no-console
      console.log(`New update Available. Current Version: ${currentSWVersion}. New Version: ${latestSWVersion}`);
    }
  }
}

function useWorkbox() {
  const [initialTime] = useState(Date.now());

  useEffect(() => {
    const enableSW = publicRuntimeConfig.APP_ENV !== "local";
    const currentSWVersion = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if ("serviceWorker" in navigator && enableSW) {
      const wb = new Workbox("/service-worker.js");

      const showUpdateModal = async (event: WorkboxLifecycleWaitingEvent) => {
        // App that already run more than 3min (buffer time)
        // If app run more than 3 min will show update notification
        // Else app is newly loaded/refresh, will just update the SW without showing notif and reloading the app. Since the app already load latest asset we only need to activate the sw to broadcast other browser tab
        const isLongRunningAPP = Date.now() > initialTime + bufferTime;
        wb.addEventListener("controlling", async () => {
          const latestSWVersion = await getServerHashVersion();
          if (window.localStorage && latestSWVersion) {
            window.localStorage.setItem(LOCAL_STORAGE_KEY, latestSWVersion);
          }

          if (isLongRunningAPP) {
            window.location.reload();
          }
        });

        if (isLongRunningAPP) {
          if (currentSWVersion) {
            // eslint-disable-next-line no-console
          }

          if (!toast.isActive(TOAST_ID)) {
            toast(
              <UpdateToast
                handleUpdate={() => {
                  wb.messageSkipWaiting();
                  toast.dismiss();
                }}
              />,
              {
                autoClose: false,
                closeOnClick: false,
                closeButton: false,
                draggable: false,
                position: "top-right",
                type: "info",
                toastId: TOAST_ID,
                className: "!bg-medium-purple-100 w-screen sm:w-auto",
              },
            );
          }
        } else {
          if (currentSWVersion) {
            showUpdateLog(true);
          }
          wb.messageSkipWaiting();
        }
      };

      // Redundant got triggered when the app got two update but never click the update.
      wb.addEventListener("redundant", async () => {
        showUpdateLog();
      });

      wb.addEventListener("installed", async () => {
        showUpdateLog();
      });

      wb.addEventListener("waiting", async event => {
        showUpdateModal(event);
      });

      navigator.serviceWorker.ready
        .then(registration => {
          setInterval(() => {
            registration.update();
          }, CHECK_UPDATE_INTERVAL);
        })
        .catch(e => {
          captureException(e);
        });

      wb.register();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useWorkbox;
