export const AdminBadgeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 2 4 5.16v9.358a10.52 10.52 0 0 0 7.298 7.286L12 22l.702-.196A10.525 10.525 0 0 0 20 14.518V5.16L12 2Zm.601 2.361L14.25 7.32l3.2.725c.522.118.728.78.373 1.196l-2.183 2.552.33 3.406c.054.555-.485.964-.973.74L12 14.556l-2.997 1.38c-.488.225-1.027-.184-.973-.739l.33-3.406L6.178 9.24c-.356-.416-.15-1.078.371-1.196l3.201-.725 1.65-2.958a.681.681 0 0 1 1.202 0Zm.149 12.745-.55-.986a.227.227 0 0 0-.4 0l-.55.986-1.067.242c-.174.04-.242.26-.124.399l.728.85-.11 1.136c-.018.185.161.321.324.246l.999-.46.999.46c.163.075.342-.061.324-.246l-.11-1.136.728-.85c.118-.14.05-.36-.124-.399l-1.067-.241Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
