export const InfoOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        clipRule="evenodd"
      />
      <rect width="2" height="7" x="11" y="10" fill="currentColor" rx="1" />
      <circle cx="12" cy="8" r="1" fill="currentColor" />
    </svg>
  );
};
