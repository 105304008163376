export const BadgeStakeholderIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#C1B1FF"
        d="M16.815 20.716h-9.63a4.813 4.813 0 0 1 2.49-4.216h-.813c1.938-.713 1.734-5.298 1.734-5.298 0-1.12-.569-1.713-.569-1.713h-.063v-1.08c0-.11.068-.204.162-.244a3.686 3.686 0 0 1-1.124-1.191.986.986 0 0 1 .104-1.152c.748-.803 2.034-1.241 2.56-1.394v-.513h.803v.503c.5.144 1.826.582 2.593 1.404.28.304.319.79.102 1.152-.234.387-.621.886-1.211 1.243a.264.264 0 0 1 .083.192h.007v1.08s-.568.592-.568 1.713c0 0-.204 4.585 1.729 5.298h-.879a4.813 4.813 0 0 1 2.49 4.216Z"
      />
      <path fill="#C1B1FF" d="M12.27 8.658h-.373v.018h.373v-.018Z" />
      <path
        fill="#fff"
        d="M17.18 20.718H6.82a.313.313 0 0 0-.313.314v.001c0 .173.14.313.313.313h10.36c.173 0 .313-.14.313-.313v-.002a.313.313 0 0 0-.313-.313ZM12.085 2.654c.55 0 .995.445.995.994a.422.422 0 0 1-.421.422H11.51a.422.422 0 0 1-.421-.422c0-.549.445-.994.994-.994h.002Zm2.811 13.248H9.102a.377.377 0 0 0-.377.376v.149c0 .207.17.376.377.376h5.794a.377.377 0 0 0 .377-.377v-.148a.377.377 0 0 0-.377-.376Zm-.922-7.337h-3.948a.462.462 0 0 0-.462.462v.002c0 .255.207.462.462.462h3.948a.462.462 0 0 0 .462-.463v-.001a.462.462 0 0 0-.462-.462Z"
      />
    </svg>
  );
};
