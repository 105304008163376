export const ResignAsAdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <rect
        width="20.883"
        height="1.25"
        x="2.845"
        y="2.5"
        fill="currentColor"
        rx=".625"
        transform="rotate(45 2.845 2.5)"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.468 3.457 10 1.667 16.667 4.3v7.799a8.758 8.758 0 0 1-.74 1.817l-2.765-2.766-.128-1.323L14.852 7.7c.296-.347.125-.898-.31-.997L11.876 6.1l-1.374-2.466a.568.568 0 0 0-1.002 0L8.125 6.1l-.011.002-2.646-2.645Zm-1.267.5-.867.343v7.799a8.768 8.768 0 0 0 6.08 6.07l.586.164.585-.163a8.774 8.774 0 0 0 4.651-3.178l-1.988-1.988a.57.57 0 0 1-.75.277L10 12.131l-2.497 1.15c-.407.187-.856-.154-.811-.616l.275-2.838L5.148 7.7c-.296-.347-.125-.898.31-.997l1.215-.275L4.2 3.957Zm5.966 9.477.458.822.89.2c.144.034.201.217.102.333l-.606.709.092.946c.015.154-.135.268-.27.205L10 16.266l-.832.383c-.136.063-.286-.05-.27-.205l.091-.946-.606-.71c-.099-.115-.042-.299.103-.331l.89-.202.457-.821a.19.19 0 0 1 .334 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
