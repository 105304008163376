export const BadgeButterflyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M20.4 5.018v3.919c0 2.48-2 4.48-4.48 4.48h-.13c1.58 0 2.85 1.28 2.85 2.85v3.8h-.01a6.58 6.58 0 0 1-4.69-1.95 6.596 6.596 0 0 1-1.944-4.443c.002-.076.004-.153.004-.231v-.025h-.01v.025c0 .078.001.155.005.231a6.597 6.597 0 0 1-1.945 4.443 6.58 6.58 0 0 1-4.69 1.95h-.01v-3.8c0-1.57 1.27-2.85 2.85-2.85h-.12a4.473 4.473 0 0 1-4.48-4.48v-3.92h3.92c2.48 0 4.48 2 4.48 4.48 0-2.48 2-4.48 4.48-4.48h3.92Z"
      />
      <path fill="#fff" d="M12 5.721a.894.894 0 1 0 0-1.788.894.894 0 0 0 0 1.788Z" />
      <path
        fill="#C1B1FF"
        d="M12 13.418H8.08c-2.479 0-4.48-2-4.48-4.481v-3.91l8.4 8.389v.002Zm0 0h3.92c2.479 0 4.48-2 4.48-4.481v-3.91L12 13.416v.002Z"
      />
      <path
        fill="#C1B1FF"
        d="M12 13.418a6.6 6.6 0 0 1-1.95 4.699 6.58 6.58 0 0 1-4.69 1.95c.949-.36 4.86-2.08 6.64-6.65Z"
      />
      <path
        fill="#C1B1FF"
        d="M11.99 13.418c0 1.83.739 3.499 1.95 4.699a6.58 6.58 0 0 0 4.69 1.95c-.95-.36-4.86-2.08-6.64-6.65Z"
      />
      <path
        fill="#2A0FCE"
        d="m8.974 7.894-.388 1.322.388 1.323-1.323-.387-1.321.387.387-1.323-.387-1.321 1.321.386 1.323-.387Zm8.696 0-.387 1.322.387 1.323-1.322-.387-1.322.387.388-1.323-.389-1.321 1.324.386 1.321-.387Z"
      />
      <path
        fill="#ADABFF"
        d="M7.651 17.288a1.322 1.322 0 1 0 0-2.644 1.322 1.322 0 0 0 0 2.644Zm8.698 0a1.322 1.322 0 1 0 0-2.644 1.322 1.322 0 0 0 0 2.644Z"
      />
    </svg>
  );
};
