export const PollBorderlessIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="9" height="10" fill="none" viewBox="0 0 9 10" {...props}>
      <svg width="3" height="5" x="6" y="5" fill="currentColor" viewBox="0 0 3 5">
        <path
          fillRule="evenodd"
          d="M1.667.917c.38 0 .687.307.687.687v2.292a.688.688 0 0 1-1.375 0V1.604c0-.38.308-.687.688-.687Z"
          clipRule="evenodd"
        />
      </svg>
      <svg width="3" height="7" y="3" fill="currentColor" viewBox="0 0 3 7">
        <path
          fillRule="evenodd"
          d="M1.333.167c.38 0 .688.307.688.687v5.042a.687.687 0 1 1-1.375 0V.854c0-.38.308-.687.687-.687Z"
          clipRule="evenodd"
        />
      </svg>
      <svg width="3" height="10" x="3" fill="currentColor" viewBox="0 0 2 10">
        <path
          fillRule="evenodd"
          d="M1 .417c.38 0 .688.307.688.687v7.792a.687.687 0 1 1-1.375 0V1.104C.313.724.62.417 1 .417Z"
          clipRule="evenodd"
        />
      </svg>
    </svg>
  );
};
