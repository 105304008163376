export const UnfoldMoreMediumIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.188c.15 0 .292.059.398.164l3 3a.562.562 0 1 1-.796.796L9 4.545 6.398 7.149a.563.563 0 0 1-.796-.796l3-3A.563.563 0 0 1 9 3.188Zm-3.398 7.664c.22-.22.576-.22.796 0L9 13.454l2.602-2.602a.562.562 0 1 1 .796.796l-3 3a.562.562 0 0 1-.796 0l-3-3a.562.562 0 0 1 0-.796Z"
        fill="currentColor"
      />
    </svg>
  );
};
