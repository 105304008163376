export const UserRetractIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.088 9.684c.388-.517.95-.85 1.579-.85H7c.629 0 1.19.333 1.579.85.385.515.588 1.189.588 1.872v1.11a.5.5 0 0 1-1 0v-1.11c0-.497-.15-.953-.388-1.272-.238-.316-.524-.45-.779-.45H3.667c-.256 0-.542.134-.779.45-.24.32-.388.775-.388 1.271v1.112a.5.5 0 0 1-1 0v-1.111c0-.683.202-1.357.588-1.872Zm3.245-5.851a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm11.833 2.5a.5.5 0 0 1-.5.5H9.833a.5.5 0 0 1 0-1h4.333a.5.5 0 0 1 .5.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.313 5.48a.5.5 0 0 1 .707 0l2 2a.5.5 0 0 1 0 .707l-2 2a.5.5 0 1 1-.707-.707l1.646-1.647-1.646-1.646a.5.5 0 0 1 0-.707Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
