export const ArrowDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="11" height="14" fill="none" viewBox="0 0 11 14" {...props}>
      <path
        fill="currentColor"
        d="m5.243 13-.707.707a1 1 0 0 0 1.414 0L5.243 13ZM1.707 8.05A1 1 0 0 0 .293 9.464L1.707 8.05Zm8.485 1.414A1 1 0 0 0 8.778 8.05l1.414 1.414ZM4.235 13a1 1 0 1 0 2 0h-2Zm2-12a1 1 0 0 0-2 0h2ZM5.95 12.293 1.707 8.05.293 9.464l4.243 4.243 1.414-1.414ZM8.778 8.05l-4.242 4.243 1.414 1.414 4.242-4.243L8.778 8.05ZM6.235 13V1h-2v12h2Z"
      />
    </svg>
  );
};
