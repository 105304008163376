export const LockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.708 10.682c0-1.169.93-2.14 2.107-2.14h10.37c1.177 0 2.107.971 2.107 2.14v5.303c0 1.169-.93 2.14-2.107 2.14H4.815c-1.177 0-2.107-.971-2.107-2.14v-5.303Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 3.125c-.696 0-1.378.307-1.893.878-.516.573-.815 1.364-.815 2.2v2.964a.625.625 0 0 1-1.25 0V6.204c0-1.128.402-2.222 1.136-3.037.736-.818 1.75-1.292 2.822-1.292s2.085.474 2.822 1.292c.734.815 1.136 1.909 1.136 3.037v2.963a.625.625 0 1 1-1.25 0V6.204c0-.837-.3-1.628-.816-2.201-.514-.571-1.196-.878-1.892-.878Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
