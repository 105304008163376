export const ColorfulSemiStarIconWithLock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.2764 16.1372C9.09318 16.3206 8.75876 16.2802 8.64193 16.0173L6.87511 12.042C6.79656 11.8652 6.65542 11.7238 6.47885 11.6448L2.4819 9.85771C2.1727 9.71946 2.1727 9.28054 2.4819 9.14229L6.47885 7.35519C6.65542 7.27624 6.79656 7.13479 6.87511 6.95804L8.64193 2.9827C8.77922 2.6738 9.21701 2.67204 9.35678 2.97983L11.1659 6.96379C11.2446 7.13703 11.3836 7.27574 11.557 7.35403L15.5194 9.14287C15.8269 9.28168 15.8269 9.71832 15.5194 9.85713L15.1646 10.0173C15.0937 9.91232 15.0151 9.81161 14.9291 9.71606C14.4454 9.17863 13.7555 8.84375 13 8.84375C12.2445 8.84375 11.5546 9.17863 11.0709 9.71606C10.629 10.2071 10.3841 10.8346 10.3483 11.4748C9.60116 11.7645 9.09375 12.499 9.09375 13.3182V15.3068C9.09375 15.5999 9.15872 15.8822 9.2764 16.1372Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2812 12.2812V11.6389C11.2812 11.1612 11.4516 10.6945 11.7677 10.3432C12.0853 9.99034 12.5273 9.78125 13 9.78125C13.4727 9.78125 13.9147 9.99034 14.2323 10.3432C14.5484 10.6945 14.7188 11.1612 14.7188 11.6389V12.2812H14.9444C15.52 12.2812 15.9688 12.7555 15.9688 13.3182V15.3068C15.9688 15.8695 15.52 16.3438 14.9444 16.3438H11.0556C10.48 16.3438 10.0312 15.8695 10.0312 15.3068V13.3182C10.0312 12.7555 10.48 12.2812 11.0556 12.2812H11.2812ZM12.4645 10.9704C12.6158 10.8023 12.8097 10.7188 13 10.7188C13.1903 10.7188 13.3842 10.8023 13.5355 10.9704C13.6882 11.1401 13.7812 11.3798 13.7812 11.6389V12.2812H12.2188V11.6389C12.2188 11.3798 12.3118 11.1401 12.4645 10.9704Z"
        fill="#949495"
      />
    </svg>
  );
};
