export const BadgeEngineerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M19.881 8.175v.006a3.528 3.528 0 0 1-3.747 3.522 3.415 3.415 0 0 1-1.007-.216l-4.066 4.066a3.53 3.53 0 0 1-5.558 3.695.323.323 0 0 1 .054-.545l.005-.002 2.337-1.177c.511-.255.717-.877.46-1.387l-.093-.186a1.036 1.036 0 0 0-1.389-.46L4.54 16.666c-.009.005-.018.011-.029.015a.263.263 0 0 1-.391-.228v-.005a3.527 3.527 0 0 1 4.422-3.415l4.323-4.322a3.53 3.53 0 0 1 5.633-3.331.324.324 0 0 1-.055.545h-.004l-2.337 1.179a1.032 1.032 0 0 0-.46 1.388l.092.186c.257.51.88.715 1.39.459L19.46 7.96a.516.516 0 0 1 .029-.014.263.263 0 0 1 .391.228Z"
      />
      <path
        fill="#C1B1FF"
        d="m7.647 4.026 1.027 1.877 1.879 1.027-1.879 1.026-1.027 1.878-1.026-1.878L4.743 6.93l1.878-1.027 1.026-1.877Zm10.138 11.51.741 1.354 1.355.74-1.355.74-.74 1.355-.74-1.355-1.354-.74 1.354-.74.74-1.354Z"
      />
    </svg>
  );
};
