// Using js so can import this on the talinwid.config.js without compiling

const fontWeight = {
  light: "300",
  normal: "400",
  medium: "500",
  bold: "700",
  black: "900",
};

module.exports = {
  fontWeight,
};
