export const FilePDFIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="46" height="46" fill="#FA0F00" viewBox="0 0 46 46" {...props}>
      <rect width="45" height="45" x=".5" y=".5" rx="5" />
      <path
        fill="#fff"
        d="M21.3 19.2s-1.6 5.2-4.1 9.7c0 0-.6.2-1.4.5-1.4.6-2.5 1.3-2.9 1.5-1.2.8-3.4 2.2-3.7 3.8 0 .3-.2.9.2 1.5.1.3.3.4.4.5.6.5 1.1.7 1.5.8.9.1 1.9-.2 1.9-.2s1.4-.4 3-2.2 2.5-3.1 2.5-3.1 1.3-2.1 1.4-2.3c0 0 5.4-1.8 7.3-1.9 0 0 1.8 1.7 3.5 2.3 1.7.6 1.9.6 1.9.6.5.1 3.2.9 4.7-.6.1-.1.3-.3.4-.5.1-.2.4-.8.3-1.6-.2-.9-.9-1.7-1.8-1.9 0 0-2-1.1-8.3-.3 0 0-3.2-3.5-4-5.3.9-2.3 1.2-4.2 1.3-5.6.1-1.8.2-3.5-1-4.7-.2-.2-1-1.1-2.2-1.1-.8 0-1.4.5-1.5.6-.2.1-.7.6-1 1.6-.3.9-.2 1.9.1 3.6.4 1.2.8 2.6 1.5 4.3z"
      />
      <path d="M22.2 17.5s-1-3.1-.9-4.7.5-2 1.1-2 .8 1.1.8 1.1 0 3.7-1 5.6m1.2 5.3s-1.7 4.5-1.9 4.6c0 0 4.3-1.2 4.6-1.2 0 0-2.8-3-2.7-3.4zm-6.9 8.1c-.4.2-3.7 1.8-5 3-.4.4-.8.8-.8 1.3 0 .1 0 .4.2.6.3.3.8.3 1.4.1.6-.3 1.3-.8 2-1.5 1.2-1.3 1.9-2.6 2.2-3.5zm12.7-3.8s2 1.5 3.3 1.8c1.2.4 2.8 1 3.3-.6.1-.3 0-.6 0-.7-.2-.6-1.3-1-2.6-.9-.4 0-1.6.2-2 .2-.5.1-1.7.2-2 .2z" />
    </svg>
  );
};
