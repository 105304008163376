export const BubblesOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.5 14.5h-1a.995.995 0 0 1-.5-.134v3.121a2 2 0 0 0 2 2h8.85l2.338 1.865a.5.5 0 0 0 .812-.39V10a2 2 0 0 0-2-2h-4v1.5h4a.5.5 0 0 1 .5.5v8.885l-1.125-.898H10a.5.5 0 0 1-.5-.5V14.5z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.625 12.987H14a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H4a.5.5 0 0 0-.5.5v8.885l1.125-.898zm-1.813 3.365a.5.5 0 0 1-.812-.39V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v7.487a2 2 0 0 1-2 2H5.15l-2.338 1.865z"
      />
    </svg>
  );
};
