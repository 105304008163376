export const EndorseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.688 9a7.313 7.313 0 1 1 14.625 0A7.313 7.313 0 0 1 1.688 9ZM9 2.812a6.188 6.188 0 1 0 0 12.376A6.188 6.188 0 0 0 9 2.812Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.602 9.398c.22.22.576.22.796 0L9 6.795l2.602 2.603a.562.562 0 1 0 .796-.796l-3-3a.563.563 0 0 0-.796 0l-3 3a.563.563 0 0 0 0 .796Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 5.438c.31 0 .563.251.563.562v6a.562.562 0 1 1-1.126 0V6c0-.31.252-.563.563-.563Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
