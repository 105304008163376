import { useId } from "react";

export const HourglassGradientFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();
  const id4 = useId();
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill={`url(#${id1})`}
        fillRule="evenodd"
        d="M3 2.438c0-.311.252-.563.563-.563h10.874a.562.562 0 1 1 0 1.125H3.563A.563.563 0 0 1 3 2.437Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id2})`}
        fillRule="evenodd"
        d="M3 15.188c0-.311.252-.563.563-.563h10.874a.562.562 0 1 1 0 1.125H3.563A.562.562 0 0 1 3 15.187Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id3})`}
        fillRule="evenodd"
        d="m3.75 1.875.798 4.191a4.532 4.532 0 0 0 8.904 0l.798-4.191H3.75ZM5.11 3l.543 2.856a3.407 3.407 0 0 0 6.694 0L12.89 3H5.11Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id4})`}
        fillRule="evenodd"
        d="m3.75 15.75.687-3.09a4.675 4.675 0 0 1 9.127 0l.686 3.09H3.75Zm1.402-1.125.383-1.72a3.55 3.55 0 0 1 6.93 0l.383 1.72H5.152Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id={id1} x1="3.244" x2="4.554" y1="2.438" y2="6.408" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id2} x1="3.244" x2="4.554" y1="15.188" y2="19.158" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id3} x1="3.963" x2="14.172" y1="5.813" y2="9.679" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id4} x1="3.963" x2="13.729" y1="12.375" y2="8.06" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
