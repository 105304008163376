export const DraftLabelIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="13" fill="none" viewBox="0 0 16 13" {...props}>
      <path
        fill="currentColor"
        d="M6.222 3.467V0L0 6.067l6.222 6.066V8.58c4.445 0 7.556 1.387 9.778 4.42-.889-4.333-3.556-8.667-9.778-9.533Z"
      />
    </svg>
  );
};
