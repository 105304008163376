export const ChallengeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.539 6.264a.901.901 0 0 0-1.275 1.275L10.725 12l-4.46 4.461a.901.901 0 0 0 1.274 1.275L12 13.275l4.461 4.461a.901.901 0 1 0 1.275-1.275l-4.461-4.46 4.461-4.462a.901.901 0 1 0-1.275-1.275l-4.46 4.461-4.462-4.461Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
