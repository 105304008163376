export const AnswerProposedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.667 3H5.334a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h8.333V6a3 3 0 0 0-3-3ZM5.334 2a4 4 0 0 0-4 4v4a4 4 0 0 0 4 4h9.333V6a4 4 0 0 0-4-4H5.334Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M6.667 8a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Zm2 0a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Zm2 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Z"
      />
    </svg>
  );
};
