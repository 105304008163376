export const ResolvedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.757 3.432a.5.5 0 1 0-.848-.53l-4.633 7.406L5.722 7.16a.5.5 0 0 0-.777.63l2.877 3.548a.633.633 0 0 0 1.03-.063l4.905-7.842ZM3.333 3.167h8.231l-.626 1H3.333A.333.333 0 0 0 3 4.5v8.667c0 .184.15.333.333.333H12c.184 0 .333-.15.333-.333V7.593l1-1.598v7.172c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 13.167V4.5c0-.736.597-1.333 1.333-1.333Z"
        fill="currentColor"
      />
    </svg>
  );
};
