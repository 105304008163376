export const BadgeSuperstarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#BadgeSuperstar_svg__a)">
        <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
        <path
          fill="#ADABFF"
          d="M20.314 20.654a12.027 12.027 0 0 1-5.924 3.108 12.054 12.054 0 0 1-4.7.014 11.98 11.98 0 0 1-5.901-3.027c7.574-5.263 8.098-15.777 8.098-15.777 0 7.403 6.236 13.698 8.427 15.681Z"
        />
        <path
          fill="#C1B1FF"
          d="M11.892 7.863c-.141 4.023-.62 11.34-2.202 15.913a11.832 11.832 0 0 1-3.291-1.162c4.401-4.655 5.279-13.089 5.452-16.325 0 .534.014 1.058.041 1.574Zm.087 1.031c.713 6.825 4.201 11.8 5.715 13.67a11.93 11.93 0 0 1-3.304 1.198c-.75-2.405-2.15-7.744-2.411-14.868Z"
        />
        <path
          fill="#fff"
          d="M14.39 23.762a12.054 12.054 0 0 1-4.7.014c1.583-4.573 2.061-11.89 2.202-15.913.024.35.051.69.087 1.03.261 7.125 1.66 12.464 2.41 14.869Z"
        />
        <path
          fill="#fff"
          d="m13.389 2.54 1.341 2.716 2.997.435c1.27.185 1.777 1.746.858 2.642l-2.169 2.113.511 2.985c.218 1.266-1.111 2.23-2.246 1.634L12 13.655l-2.68 1.41c-1.137.597-2.465-.368-2.247-1.634l.511-2.985-2.169-2.113c-.92-.896-.412-2.457.858-2.642l2.997-.435 1.341-2.716c.569-1.152 2.21-1.152 2.778 0Z"
        />
      </g>
      <defs>
        <clipPath id="BadgeSuperstar_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
