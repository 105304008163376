export const ConnectionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="19" fill="none" viewBox="0 0 18 19" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.95 11.63a.563.563 0 0 1 .67-.43c.748.165 1.424.539 1.917 1.08.494.543.775 1.226.775 1.942V15.5a.562.562 0 1 1-1.125 0v-1.277c0-.416-.162-.834-.482-1.186a2.518 2.518 0 0 0-1.326-.738.563.563 0 0 1-.428-.67Zm-10.46.476a2.704 2.704 0 0 1 2.01-.918H9c.766 0 1.487.338 2.01.918.52.578.803 1.35.803 2.144v1.25a.562.562 0 1 1-1.126 0v-1.25c0-.532-.19-1.032-.514-1.392A1.58 1.58 0 0 0 9 12.313H4.5c-.427 0-.85.189-1.173.546-.324.36-.514.86-.514 1.392v1.25a.562.562 0 1 1-1.126 0v-1.25c0-.794.284-1.566.804-2.144Zm8.966-8.75c.08-.3.388-.48.688-.4a3.55 3.55 0 0 1 1.928 1.308 3.73 3.73 0 0 1 .74 2.236 3.73 3.73 0 0 1-.74 2.236 3.55 3.55 0 0 1-1.928 1.308.563.563 0 0 1-.288-1.088 2.42 2.42 0 0 0 1.316-.895c.333-.444.515-.993.515-1.56 0-.568-.182-1.118-.515-1.562a2.424 2.424 0 0 0-1.316-.895.562.562 0 0 1-.4-.688Zm-4.706.707a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.876ZM3.187 6.5a3.563 3.563 0 1 1 7.126 0 3.563 3.563 0 0 1-7.126 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
