export const SupportedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="17" fill="none" viewBox="0 0 17 17" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.2 8.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM8.7 3a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.68 8.854a.5.5 0 0 0 .707 0L8.7 6.54l2.314 2.314a.5.5 0 1 0 .707-.708L9.054 5.48a.5.5 0 0 0-.707 0L5.68 8.146a.5.5 0 0 0 0 .708Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.7 5.333a.5.5 0 0 1 .5.5v5.334a.5.5 0 0 1-1 0V5.833a.5.5 0 0 1 .5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
