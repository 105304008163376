export const FAQIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 7.792c-.805 0-1.458.653-1.458 1.458a.625.625 0 0 1-1.25 0 2.708 2.708 0 1 1 3.333 2.636v.35a.625.625 0 1 1-1.25 0v-.903c0-.345.28-.625.625-.625a1.458 1.458 0 1 0 0-2.916Zm-.625 6.041c0-.345.28-.625.625-.625h.008a.625.625 0 0 1 0 1.25H10a.625.625 0 0 1-.625-.625Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 4.25h-5A3.75 3.75 0 0 0 3.75 8v5a3.75 3.75 0 0 0 3.75 3.75h5A3.75 3.75 0 0 0 16.25 13V8a3.75 3.75 0 0 0-3.75-3.75ZM7.5 3a5 5 0 0 0-5 5v5a5 5 0 0 0 5 5h5a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5h-5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
