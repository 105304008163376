export const AttachmentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.353 3.047c.43.43.672 1.013.672 1.62l.007 9.465a3.741 3.741 0 0 1-6.395 2.644 3.753 3.753 0 0 1-1.1-2.65L5.531 4.66a.625.625 0 0 0-1.25 0l.006 9.466a5.003 5.003 0 0 0 5 5 4.99 4.99 0 0 0 4.995-4.995l-.007-9.465A3.544 3.544 0 0 0 8.231 2.16a3.536 3.536 0 0 0-1.036 2.503l.012 9.47a2.085 2.085 0 0 0 3.556 1.474c.39-.39.61-.92.609-1.472l-.011-8.74a.625.625 0 1 0-1.25.002l.011 8.739a.83.83 0 0 1-1.42.586.835.835 0 0 1-.245-.59l-.012-9.47a2.286 2.286 0 0 1 3.908-1.615Z"
        fill="currentColor"
      />
    </svg>
  );
};
