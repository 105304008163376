export const AddPartitionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.167 5.5 7.5 3H3.333a1.667 1.667 0 0 0-1.666 1.667v11.666A1.666 1.666 0 0 0 3.333 18h13.334a1.666 1.666 0 0 0 1.666-1.667V7.167A1.667 1.667 0 0 0 16.667 5.5h-7.5ZM6.83 4.25H3.333a.417.417 0 0 0-.416.417v11.666a.417.417 0 0 0 .416.417h13.334a.417.417 0 0 0 .416-.417V7.167a.417.417 0 0 0-.416-.417h-7.5a1.25 1.25 0 0 1-1.04-.557L6.83 4.25Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 9.042c.345 0 .625.28.625.625v5a.625.625 0 1 1-1.25 0v-5c0-.346.28-.625.625-.625Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.875 12.167c0-.346.28-.625.625-.625h5a.625.625 0 1 1 0 1.25h-5a.625.625 0 0 1-.625-.625Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
