// The following are the figma reference:
// https://www.figma.com/file/6JPwPZKn2XF9XUyVPbAIPj/%F0%9F%92%99Icons-2022?type=design&node-id=883-25917&mode=dev
export const CheckFillIconV2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm3.636 6.398a.75.75 0 1 0-1.272-.796l-3.197 5.11-1.584-1.954a.75.75 0 1 0-1.165.945l2.07 2.552a.95.95 0 0 0 1.543-.095l3.605-5.762Z"
        fill="currentColor"
      />
    </svg>
  );
};
