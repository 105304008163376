export const ChevronDownDarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="33" height="32" fill="none" viewBox="0 0 33 32" {...props}>
      <g filter="url(#ChevronDownDark_svg__a)">
        <g clipPath="url(#ChevronDownDark_svg__b)">
          <rect width="24" height="24" x="28" y="27" fill="#fff" rx="12" transform="rotate(-180 28 27)" />
          <path
            fill="currentColor"
            d="M16 3C9.376 3 4 8.376 4 15s5.376 12 12 12 12-5.376 12-12S22.624 3 16 3Zm4.548 11.652-3.696 3.696a1.21 1.21 0 0 1-1.704 0l-3.696-3.696a1.21 1.21 0 0 1 0-1.704 1.195 1.195 0 0 1 1.692 0L16 15.804l2.856-2.856a1.195 1.195 0 0 1 1.692 0 1.21 1.21 0 0 1 0 1.704Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="ChevronDownDark_svg__b">
          <rect width="24" height="24" x="28" y="27" fill="#fff" rx="12" transform="rotate(-180 28 27)" />
        </clipPath>
        <filter
          id="ChevronDownDark_svg__a"
          width="33"
          height="32"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0.807843 0 0 0 0 0.807843 0 0 0 0 0.839216 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1219_46412" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.806094 0 0 0 0 0.806094 0 0 0 0 0.8375 0 0 0 0.42 0" />
          <feBlend in2="effect1_dropShadow_1219_46412" result="effect2_dropShadow_1219_46412" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_1219_46412" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
