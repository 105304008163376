export const BadgeBroadcasterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#ADABFF"
        d="m15.834 15.582.535 1.003c.341.64.098 1.436-.54 1.776l-1.813.966a1.31 1.31 0 0 1-1.774-.541l-.536-1.003 4.13-2.201h-.002Zm.381-1.248-5.757 3.069.969 1.818a2.233 2.233 0 0 0 3.024.921l1.814-.966a2.236 2.236 0 0 0 .92-3.024l-.968-1.818h-.002ZM14.793 8.07l1.13-1.13a1.6 1.6 0 0 1 2.262 0 1.6 1.6 0 0 1 0 2.26l-1.13 1.132-2.262-2.262Z"
      />
      <path fill="#fff" d="m21.849 12.866-10.701 5.482-4.371-4.37 5.482-10.702 9.59 9.59Z" />
      <path
        fill="#C1B1FF"
        d="m6.79 13.988 4.35 4.35-1.687 1.687a2.39 2.39 0 0 1-3.376 0l-.975-.975a2.39 2.39 0 0 1 0-3.377l1.687-1.687.002.002ZM5.055 5.173l1.027 1.878L7.96 8.08 6.082 9.105l-1.027 1.878-1.027-1.878L2.15 8.079l1.877-1.028 1.027-1.878Z"
      />
    </svg>
  );
};
