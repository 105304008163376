export const ForumIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.083 2.255a.75.75 0 0 1 .663.828L16.17 8.25H20a.75.75 0 0 1 0 1.5h-3.995l-.5 4.5H20a.75.75 0 0 1 0 1.5h-4.662l-.592 5.333a.75.75 0 0 1-1.491-.166l.574-5.167H9.338l-.593 5.333a.75.75 0 0 1-1.49-.166l.574-5.167H4a.75.75 0 0 1 0-1.5h3.995l.5-4.5H4a.75.75 0 0 1 0-1.5h4.662l.593-5.333a.75.75 0 0 1 1.49.166l-.574 5.167h4.491l.593-5.333a.75.75 0 0 1 .828-.662ZM10.005 9.75l-.5 4.5h4.49l.5-4.5h-4.49Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
