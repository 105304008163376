export const ExitIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.891 5.891a.625.625 0 0 1 .884 0l4.167 4.167a.625.625 0 0 1 0 .884l-4.167 4.167a.625.625 0 1 1-.884-.884l3.725-3.725-3.725-3.725a.625.625 0 0 1 0-.884Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.875 10.5c0-.345.28-.625.625-.625h10a.625.625 0 1 1 0 1.25h-10a.625.625 0 0 1-.625-.625Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.167 3.625a1.042 1.042 0 0 0-1.042 1.042v11.666a1.042 1.042 0 0 0 1.042 1.042H7.5a.625.625 0 1 1 0 1.25H4.167a2.292 2.292 0 0 1-2.292-2.292V4.667a2.292 2.292 0 0 1 2.292-2.292H7.5a.625.625 0 1 1 0 1.25H4.167Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
