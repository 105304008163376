import { useId } from "react";

export const AskContributionGradientIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();

  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill={`url(#${id1})`}
        fillRule="evenodd"
        d="M12.122 15.417h4.545c.23 0 .417-.187.417-.417V3.333a.417.417 0 0 0-.417-.416H3.334a.417.417 0 0 0-.417.416V15c0 .23.187.417.417.417h4.545L10 16.83l2.122-1.414Zm.378 1.25h4.167c.92 0 1.667-.747 1.667-1.667V3.333c0-.92-.747-1.666-1.667-1.666H3.334c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667H7.5l2.27 1.512c.14.094.322.094.462 0l2.268-1.512Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id2})`}
        fillRule="evenodd"
        d="M7.916 11.458c-.426 0-.814.137-1.082.352-.266.212-.376.466-.376.69v.208h7.083V12.5c0-.224-.11-.478-.375-.69l.78-.976c.517.413.845 1.008.845 1.666v.833c0 .346-.28.625-.625.625H5.833a.625.625 0 0 1-.625-.625V12.5c0-.659.328-1.253.845-1.666.513-.411 1.185-.626 1.863-.626h4.167c.679 0 1.35.215 1.864.626l-.781.976c-.268-.215-.656-.352-1.083-.352H7.916Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id3})`}
        fillRule="evenodd"
        d="M10 7.917a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm2.5-1.25a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id={id1} x1="2.005" x2="19.137" y1="9.958" y2="14.849" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id2} x1="5.403" x2="12.38" y1="12.083" y2="17.148" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id3} x1="7.601" x2="12.745" y1="6.667" y2="8.128" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
