import { useId } from "react";

export const EditPostGradientIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.288 2a2.211 2.211 0 0 0-1.564.648l-8.17 8.17a.5.5 0 0 0-.129.222l-.907 3.328a.5.5 0 0 0 .614.614l3.328-.907a.5.5 0 0 0 .222-.13l8.17-8.17A2.212 2.212 0 0 0 12.288 2Zm-.463 1.092a1.211 1.211 0 0 1 1.32 1.977L5.07 13.145l-2.356.642.642-2.356 8.076-8.076c.113-.113.247-.202.394-.263Z"
        fill={`url(#${id1})`}
      />
      <defs>
        <linearGradient id={id1} x1="1.5" y1="15" x2="14.5" y2="15" gradientUnits="userSpaceOnUse">
          <stop stopColor="#514FBD" />
          <stop offset="1" stopColor="#58A5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
