export const MakeAdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 1.667 3.333 4.3v7.799a8.768 8.768 0 0 0 6.082 6.07l.585.164.585-.163a8.77 8.77 0 0 0 6.082-6.071v-7.8L10 1.668Zm.501 1.967L11.875 6.1l2.668.603c.434.099.605.65.31.997l-1.82 2.127.276 2.838c.044.463-.404.803-.811.616L10 12.131l-2.497 1.15c-.407.187-.856-.154-.811-.616l.275-2.838L5.148 7.7c-.296-.347-.125-.898.31-.997L8.125 6.1 9.5 3.634a.568.568 0 0 1 1.002 0Zm.124 10.622-.458-.822a.19.19 0 0 0-.334 0l-.458.822-.889.2c-.145.034-.202.217-.103.333l.606.709-.092.946c-.015.154.135.268.27.205l.833-.383.833.383c.135.063.285-.05.27-.205l-.092-.946.607-.71c.098-.115.041-.299-.104-.331l-.889-.202Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
