export const FileExcelIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="46" height="46" fill="none" viewBox="0 0 46 46" {...props}>
      <rect width="45" height="45" x=".5" y=".5" fill="#117840" rx="5" />
      <path
        fill="#fff"
        d="m19.8 14.421 3.212 5.954 3.21-5.954h4.02l-4.957 8.461 5.086 8.602h-4.055l-3.304-6.07-3.305 6.07H15.63l5.098-8.602-4.97-8.46h4.044Z"
      />
    </svg>
  );
};
