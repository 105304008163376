export const BadgeVIPIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="m20.023 11.165-2.929 9.283H6.903l-2.926-9.284a.268.268 0 0 1 .297-.335l2.629.36a2.578 2.578 0 0 0 2.25-.803l2.268-2.452a.787.787 0 0 1 1.157 0l2.27 2.452c.573.62 1.414.92 2.248.803l2.632-.36a.269.269 0 0 1 .297.335h-.002Z"
      />
      <path
        fill="#C1B1FF"
        d="M3.228 10.686a.98.98 0 1 0 0-1.959.98.98 0 0 0 0 1.959Zm17.544 0a.98.98 0 1 0 0-1.959.98.98 0 0 0 0 1.959ZM12 7.112a1.479 1.479 0 1 0 0-2.959 1.479 1.479 0 0 0 0 2.959Zm0 5.351.741 1.355 1.353.741-1.353.74L12 16.652l-.741-1.354-1.353-.74 1.353-.741.741-1.354Z"
      />
    </svg>
  );
};
