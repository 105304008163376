export const UserCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="6.017" stroke="#fff" strokeWidth="1.3" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.004 14.666h-.008a6.643 6.643 0 0 1-4.588-1.834c.46-1.466 2.948-2.204 4.592-2.204 1.644 0 4.133.738 4.592 2.204a6.643 6.643 0 0 1-4.588 1.834Zm2.718-7.307c0 1.487-1.218 2.692-2.722 2.692S5.278 8.846 5.278 7.36c0-1.49 1.218-2.693 2.722-2.693s2.722 1.204 2.722 2.692Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
