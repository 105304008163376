export const RetractIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        d="M5 15h10c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834H5a.836.836 0 0 1-.833-.834c0-.458.375-.833.833-.833Zm6.667-12.5H8.333a.836.836 0 0 0-.833.834V7.5H6.175c-.742 0-1.108.9-.583 1.425l3.825 3.825a.83.83 0 0 0 1.175 0l3.825-3.825c.525-.525.15-1.425-.592-1.425H12.5V3.334a.836.836 0 0 0-.833-.834Z"
      />
    </svg>
  );
};
