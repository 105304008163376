export const ResolveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10Zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </svg>
  );
};
