export const EditorAddImageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 20 19" {...props}>
      <path
        fill="currentColor"
        d="M15.14 17.19H2.472V4.524h8.143v-1.81H2.473c-.996 0-1.81.815-1.81 1.81V17.19c0 .996.814 1.81 1.81 1.81h12.666c.995 0 1.81-.814 1.81-1.81V9.049h-1.81v8.143Zm-7.05-2.868-1.773-2.135-2.488 3.194h9.952L10.58 11.12l-2.488 3.202Zm8.86-11.608V0h-1.81v2.714h-2.714c.009.01 0 1.81 0 1.81h2.714v2.705c.01.01 1.81 0 1.81 0V4.524h2.714v-1.81H16.95Z"
      />
    </svg>
  );
};
