const defaultTheme = require("tailwindcss/defaultTheme");
const zIndex = {
  ...defaultTheme.zIndex,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  51: 51,
  60: 60,
  "discussion-map": 200,
  1000: 1000,
  1200: 1200,
  1299: 1299,
  1300: 1300,
  1301: 1301,
  1302: 1302,
  1303: 1303,
  1500: 1500,
  1501: 1501,
  1600: 1600,
  tooltip: 2147483647,
};

module.exports = {
  zIndex,
};
