export const PublishIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        d="M8.333 13.334h3.333a.836.836 0 0 0 .834-.834V8.334h1.325c.742 0 1.117-.9.591-1.425l-3.825-3.825a.83.83 0 0 0-1.175 0L5.591 6.909c-.525.525-.158 1.425.584 1.425H7.5V12.5c0 .459.375.834.833.834ZM5 15h10c.458 0 .833.375.833.834a.836.836 0 0 1-.833.833H5a.836.836 0 0 1-.833-.833c0-.459.375-.834.833-.834Z"
      />
    </svg>
  );
};
