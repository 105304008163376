export const BadgeSupportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M14.13 7.016h-4.005a4.13 4.13 0 0 0-4.13 4.129v4.005a4.13 4.13 0 0 0 4.13 4.13h4.005a4.13 4.13 0 0 0 4.13-4.13v-4.005a4.13 4.13 0 0 0-4.13-4.13Z"
      />
      <path
        fill="#C1B1FF"
        d="M5.729 9.046v6.427h-.417a.102.102 0 0 1-.029-.005c-1.374-.24-2.424-1.575-2.424-3.189 0-1.701 1.166-3.096 2.649-3.222a2.17 2.17 0 0 1 .22-.01Zm12.542 0v6.461c1.587 0 2.87-1.444 2.87-3.228 0-1.784-1.284-3.233-2.87-3.233Z"
      />
      <path
        fill="#ADABFF"
        d="M18.717 10.889v5.075h-2.973a.735.735 0 0 1-.578.28.737.737 0 1 1 .576-1.194h2.06v-4.162c0-3.287-2.169-5.251-5.802-5.251-3.633 0-5.8 1.964-5.8 5.252v4.584h-.917v-4.585c0-.655.077-1.267.225-1.831C6.216 6.341 8.571 4.721 12 4.721c4.143 0 6.717 2.364 6.717 6.168Z"
      />
      <path
        fill="#ADABFF"
        d="M13.524 14.746c0 .84-.68 1.52-1.52 1.52s-1.53-.68-1.53-1.52h3.05Zm-3.32-1.972a.25.25 0 0 1-.25-.25.349.349 0 0 0-.357-.341.349.349 0 0 0-.357.34.25.25 0 0 1-.501 0 .85.85 0 0 1 .857-.84.85.85 0 0 1 .856.84.25.25 0 0 1-.25.251h.002Zm4.805 0a.25.25 0 0 1-.25-.25.35.35 0 0 0-.357-.341.35.35 0 0 0-.357.34.25.25 0 0 1-.502 0 .85.85 0 0 1 .857-.84.85.85 0 0 1 .857.84.25.25 0 0 1-.251.251h.003Z"
      />
      <path
        fill="#fff"
        d="m18.841 4.006.668 1.22 1.22.667-1.22.668-.668 1.22-.667-1.22-1.22-.668 1.22-.667.668-1.22Z"
      />
    </svg>
  );
};
