export const DiscussionPointIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        d="M2.5 3.833a.5.5 0 0 0 0 1h5.667a.5.5 0 1 0 0-1H2.5ZM2 7a.5.5 0 0 1 .5-.5h3.667a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.333.5h8c.737 0 1.334.597 1.334 1.333v8c0 .737-.597 1.334-1.334 1.334h-8A1.333 1.333 0 0 1 0 9.833v-8C0 1.097.597.5 1.333.5Zm8.334 1.333v8c0 .184-.15.334-.334.334h-8A.333.333 0 0 1 1 9.833v-8c0-.184.15-.333.333-.333h8c.184 0 .334.15.334.333Z"
        fill="currentColor"
      />
      <path d="M12.5 4.5a.5.5 0 0 0-1 0V12H4a.5.5 0 0 0 0 1h8a.5.5 0 0 0 .5-.5v-8Z" fill="currentColor" />
    </svg>
  );
};
