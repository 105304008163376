export const SuggestionAddIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 3.5A.25.25 0 0 1 3 3.25h1.777v-1.5H3A1.75 1.75 0 0 0 1.25 3.5v1.75h1.5V3.5Zm5.581-.25h3.555v-1.5H8.33v1.5Zm7.109 0h1.777a.25.25 0 0 1 .25.25v1.75h1.5V3.5a1.75 1.75 0 0 0-1.75-1.75H15.44v1.5Zm-12.69 9v-3.5h-1.5v3.5h1.5Zm14.717-3.5v3.5h1.5v-3.5h-1.5ZM2.75 17.5v-1.75h-1.5v1.75c0 .966.784 1.75 1.75 1.75h1.777v-1.5H3a.25.25 0 0 1-.25-.25Zm14.717-1.75v1.75a.25.25 0 0 1-.25.25H15.44v1.5h1.777a1.75 1.75 0 0 0 1.75-1.75v-1.75h-1.5Zm-5.581 2H8.33v1.5h3.555v-1.5Zm.947-11.833a.75.75 0 0 0-1.5 0v1.75h-1.75a.75.75 0 1 0 0 1.5h1.75v1.75a.75.75 0 0 0 1.5 0v-1.75h1.75a.75.75 0 0 0 0-1.5h-1.75v-1.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
