import { useId } from "react";

export const NotifyOthersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill={`url(#${id1})`}
        fillRule="evenodd"
        d="M8.125 15a1.875 1.875 0 0 0 3.75 0h1.25a3.125 3.125 0 1 1-6.25 0h1.25Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id2})`}
        fillRule="evenodd"
        d="M14.833 12.417a1.25 1.25 0 0 1-.25-.75V7.5a4.583 4.583 0 1 0-9.167 0v4.167c0 .27-.088.534-.25.75l-1 1.333h11.667l-1-1.333Zm1-4.917v4.167l1.5 2A.833.833 0 0 1 16.666 15H3.333a.833.833 0 0 1-.667-1.333l1.5-2V7.5a5.833 5.833 0 1 1 11.667 0Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id={id1} x1="7.002" x2="12.255" y1="16.563" y2="19.547" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id2} x1="2.803" x2="17.936" y1="8.334" y2="13.17" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
