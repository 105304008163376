export const InviteUserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="21" fill="none" viewBox="0 0 21 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.74 12.71c.51-.678 1.247-1.116 2.072-1.116h4.375c.826 0 1.563.438 2.072 1.117.507.675.772 1.56.772 2.456v1.458a.656.656 0 0 1-1.312 0v-1.458c0-.651-.196-1.25-.51-1.669-.311-.415-.686-.592-1.021-.592H4.811c-.335 0-.71.177-1.021.592-.314.419-.51 1.018-.51 1.669v1.458a.656.656 0 0 1-1.312 0v-1.458c0-.896.265-1.781.772-2.457ZM7 5.031A1.969 1.969 0 1 0 7 8.97a1.969 1.969 0 0 0 0-3.94ZM3.719 7a3.281 3.281 0 1 1 6.562 0A3.281 3.281 0 0 1 3.72 7Zm11.812 0c.363 0 .656.294.656.656v5.688a.656.656 0 1 1-1.312 0V7.656c0-.362.294-.656.656-.656Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.25 10.281a.656.656 0 0 1-.656.656h-5.688a.656.656 0 1 1 0-1.312h5.688c.362 0 .656.294.656.656Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
