export const BadgeVisionariesIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#ADABFF"
        d="M20.465 3.494A12.014 12.014 0 0 0 14.445.25a12.057 12.057 0 0 0-4.926.008 11.992 11.992 0 0 0-5.946 3.2c1.582.895 5.748 3.607 7.614 8.212a12.857 12.857 0 0 1 .645 2.092c.18.84.28 1.73.28 2.67 0 0 .05-.725.338-1.874v-.003c.107-.434.249-.928.434-1.466.154-.446.337-.921.554-1.419 1.131-2.57 3.212-5.71 7.027-8.177Z"
      />
      <path
        fill="#C1B1FF"
        d="M17.862 1.528A11.846 11.846 0 0 0 14.445.25c-1.074 2.723-1.668 5.682-1.995 8.538a51.945 51.945 0 0 0-.252 2.882c-.04.639-.069 1.266-.09 1.877.027.514.04 1.037.04 1.571a26.07 26.07 0 0 1 .416-3.445s.002-.003 0-.003c.088-.459.194-.933.32-1.418.737-2.854 2.176-6.067 4.978-8.724ZM12.02 12.519a47.102 47.102 0 0 0-.057-1.204 51.785 51.785 0 0 0-.131-1.738c-.501-5.359-1.697-7.875-2.313-9.319a11.88 11.88 0 0 0-3.25 1.197c1.31.91 4.236 3.474 5.393 8.813a21.202 21.202 0 0 1 .357 2.251Z"
      />
      <path
        fill="#fff"
        d="M14.445.25a12.057 12.057 0 0 0-4.926.008c.617 1.444 1.812 3.96 2.313 9.32a51.662 51.662 0 0 1 .188 2.941c.037.339.064.68.088 1.027a64.948 64.948 0 0 1 .207-3.445c.039-.434.084-.872.135-1.313.327-2.856.921-5.815 1.995-8.537Z"
      />
      <path
        fill="#ADABFF"
        d="M20.465 20.506a12.014 12.014 0 0 1-6.02 3.244 12.057 12.057 0 0 1-4.926-.008 11.992 11.992 0 0 1-5.946-3.2c1.582-.895 5.748-3.607 7.614-8.212a12.857 12.857 0 0 0 .645-2.092c.18-.84.28-1.73.28-2.67 0 0 .05.726.338 1.875v.003c.107.433.249.927.434 1.465.154.446.337.921.554 1.419 1.131 2.57 3.212 5.71 7.027 8.176Z"
      />
      <path
        fill="#C1B1FF"
        d="M17.862 22.471a11.846 11.846 0 0 1-3.417 1.279c-1.074-2.723-1.668-5.683-1.995-8.539a51.954 51.954 0 0 1-.252-2.881 64.948 64.948 0 0 1-.09-1.877c.027-.514.04-1.037.04-1.571a26.07 26.07 0 0 0 .416 3.448c.088.459.194.933.32 1.418.737 2.854 2.176 6.067 4.978 8.723Zm-5.842-10.99a47.102 47.102 0 0 1-.057 1.204 51.52 51.52 0 0 1-.131 1.737c-.501 5.36-1.697 7.875-2.313 9.32a11.88 11.88 0 0 1-3.25-1.197c1.31-.91 4.236-3.474 5.393-8.813a21.202 21.202 0 0 0 .357-2.251Z"
      />
      <path
        fill="#fff"
        d="M14.445 23.75a12.057 12.057 0 0 1-4.926-.008c.617-1.445 1.812-3.96 2.313-9.32a51.662 51.662 0 0 0 .188-2.941c.037-.339.064-.68.088-1.027a64.948 64.948 0 0 0 .207 3.445c.039.434.084.872.135 1.313.327 2.855.921 5.815 1.995 8.538Z"
      />
      <path
        fill="#fff"
        d="m2.337 12 4.036-4.036a7.961 7.961 0 0 1 11.253 0L21.662 12l-4.036 4.036a7.961 7.961 0 0 1-11.253 0L2.337 12Z"
      />
      <path fill="#C1B1FF" d="M11.81 17.128a5.128 5.128 0 1 0 0-10.256 5.128 5.128 0 0 0 0 10.256Z" />
      <path fill="#2A0FCE" d="M11.81 15.896a3.896 3.896 0 1 0 0-7.792 3.896 3.896 0 0 0 0 7.792Z" />
      <path
        fill="#fff"
        d="M9.803 15.618a.938.938 0 1 0 0-1.875.938.938 0 0 0 0 1.875Zm4.63-4.613a1.439 1.439 0 1 0 0-2.877 1.439 1.439 0 0 0 0 2.877Z"
      />
    </svg>
  );
};
