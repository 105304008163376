export const DraftAndMyPostIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <mask
        id="DraftAndMyPostIcon_svg__a"
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#DraftAndMyPostIcon_svg__a)">
        <path
          fill="currentColor"
          d="M18 18.058c.417 0 .77-.146 1.063-.438a1.45 1.45 0 0 0 .437-1.062c0-.417-.146-.771-.438-1.063A1.447 1.447 0 0 0 18 15.058c-.417 0-.77.146-1.063.437a1.446 1.446 0 0 0-.437 1.063c0 .416.146.77.438 1.062.291.292.645.438 1.062.438Zm0 3c.5 0 .967-.117 1.4-.35a3.014 3.014 0 0 0 1.075-.975 4.455 4.455 0 0 0-1.2-.513A5.044 5.044 0 0 0 18 19.058c-.433 0-.858.054-1.275.162-.417.108-.817.28-1.2.513.283.416.642.741 1.075.975.433.233.9.35 1.4.35ZM5.308 20.5c-.499 0-.925-.177-1.278-.53a1.741 1.741 0 0 1-.53-1.278V5.308c0-.499.177-.925.53-1.278.353-.353.779-.53 1.278-.53h13.384c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v6.325a6.399 6.399 0 0 0-1.5-.442V5.307a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H5.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096h5.857a5.202 5.202 0 0 0 .433 1.5h-6.29ZM5 19V5v6.19-.075V19Zm2.25-2.365h4.017c.044-.26.109-.514.194-.761.086-.247.18-.493.281-.74H7.25v1.5Zm0-3.885h6.34c.431-.365.89-.671 1.38-.918a5.823 5.823 0 0 1 1.568-.526v-.056H7.25v1.5Zm0-3.885h9.5v-1.5h-9.5v1.5ZM18 22.558c-1.249 0-2.31-.438-3.187-1.314-.875-.875-1.313-1.938-1.313-3.186 0-1.249.438-2.311 1.313-3.187.876-.876 1.938-1.313 3.187-1.313 1.249 0 2.31.438 3.186 1.313.876.876 1.314 1.938 1.314 3.187 0 1.248-.438 2.31-1.314 3.186-.875.876-1.937 1.314-3.186 1.314Z"
        />
      </g>
    </svg>
  );
};
