export const InlineShareIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.752 14.652a3.25 3.25 0 0 0 4.596 4.596l2.829-2.829a.75.75 0 0 1 1.06 1.061l-2.828 2.828a4.75 4.75 0 1 1-6.717-6.717l2.828-2.829a.75.75 0 1 1 1.06 1.061l-2.828 2.829Zm9.9-9.9a3.25 3.25 0 0 1 4.596 4.596l-2.829 2.829a.75.75 0 0 0 1.061 1.06l2.828-2.828a4.75 4.75 0 1 0-6.717-6.717L10.762 6.52a.75.75 0 1 0 1.061 1.06l2.829-2.828Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M8.288 15.359a.75.75 0 0 1 0-1.061l6.01-6.01a.75.75 0 1 1 1.06 1.06l-6.01 6.01a.75.75 0 0 1-1.06 0Z"
      />
    </svg>
  );
};
