export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.25 7A.75.75 0 0 1 3 6.25h18a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 7z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 3.75A1.25 1.25 0 0 0 8.75 5v1.25h6.5V5A1.25 1.25 0 0 0 14 3.75h-4zm6.75 2.5V5A2.75 2.75 0 0 0 14 2.25h-4A2.75 2.75 0 0 0 7.25 5v1.25H6a.75.75 0 0 0-.75.75v12A2.75 2.75 0 0 0 8 21.75h8A2.75 2.75 0 0 0 18.75 19V7a.75.75 0 0 0-.75-.75h-1.25zm-10 1.5V19A1.25 1.25 0 0 0 8 20.25h8A1.25 1.25 0 0 0 17.25 19V7.75H6.75z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.25 18a.75.75 0 0 1-.75-.75v-6.5a.75.75 0 0 1 1.5 0v6.5a.75.75 0 0 1-.75.75zM12 18a.75.75 0 0 1-.75-.75v-6.5a.75.75 0 0 1 1.5 0v6.5A.75.75 0 0 1 12 18zm2.75 0a.75.75 0 0 1-.75-.75v-6.5a.75.75 0 0 1 1.5 0v6.5a.75.75 0 0 1-.75.75z"
      />
    </svg>
  );
};
