export const API_ENDPOINT_USER_SEARCH_FOR_SPACE_MEMBER = "/userSearchForSpaceMember";
export const API_ENDPOINT_SEARCH_TREE_SEARCH = "/treeSearch";
export const API_ENDPOINT_SEARCH_NODE_SEARCH = "/nodeSearch";
export const API_ENDPOINT_SEARCH_SECTION_SEARCH = "/sectionSearch";
export const API_ENDPOINT_SEARCH_ALL = "/searchAllML";
export const API_ENDPOINT_SEARCH_PLACEHOLDER = "/getGlobalSearchPlaceholder";
export const API_ENDPOINT_SEARCH_AUTOCOMPLETE = "/getGlobalSearchAutocomplete";
export const API_ENDPOINT_GET_INTERNAL_NODE_FOR_LINK = "/getInternalNodeForLink";
export const API_ENDPOINT_CLEAR_GLOBAL_HISTORY = "/deleteGlobalSearchHistory";
export const API_ENDPOINT_SEARCH_SQ_SUGGESTION = "/screeningQuestionAutocomplete";
export const API_ENDPOINT_MY_CONNECTION_SEARCH = "/myConnectionsSearch";

export const API_ENDPOINT_SEARCH_USER_TO_INVITE = "/userSearchSpaceInvitation";
export const API_ENDPOINT_SEARCH_SHARE_SPACE = "/userSearchSpaceSharing";
export const API_ENDPOINT_MENTIONABLE_SEARCH = "/userSearchNotifying";
