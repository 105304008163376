export const CheckThinIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9319 2.90944C13.166 3.0559 13.2371 3.36441 13.0906 3.59851L7.35029 12.7736C7.1198 13.142 6.59525 13.1742 6.3215 12.8367L2.94507 8.67393C2.77112 8.45947 2.80396 8.14459 3.01842 7.97064C3.23288 7.79669 3.54776 7.82952 3.72171 8.04399L6.77479 11.8081L12.2428 3.06812C12.3893 2.83402 12.6978 2.76298 12.9319 2.90944Z"
        fill="currentColor"
      />
    </svg>
  );
};
