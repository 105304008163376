export const GlobeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 14.625a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Zm0 1.125a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.5 4.5V3.375L7.875 3l-2.625.618L3 7.875l3 3.375v.375c0 .299.087.577.238.81.448.697 1.262 1.362 1.262 2.19l2.25.75L12 14.25v-1.125c0-.433-.184-.824-.478-1.098-.454-.423-1.022-.906-1.022-1.527A1.5 1.5 0 0 0 9 9H5.25V7.5h1.5a.75.75 0 0 0 .75-.75V6H9a1.5 1.5 0 0 0 1.5-1.5Z"
      />
    </svg>
  );
};
