export const NodeDiscussionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="14" fill="none" viewBox="0 0 17 14" {...props}>
      <path
        fill="currentColor"
        d="M16.833 1.923c0-.51-.226-1-.63-1.36A2.284 2.284 0 0 0 14.685 0H2.65c-.57 0-1.117.203-1.52.563-.404.36-.63.85-.63 1.36v7.115c0 .51.226 1 .63 1.36.403.36.95.563 1.52.563h.644v2.654c0 .072.021.143.063.204.042.06.102.11.173.142l.194.039a.464.464 0 0 0 .258-.077l4.19-2.962h6.512c.57 0 1.117-.202 1.52-.563.403-.36.63-.85.63-1.36V1.923Z"
      />
    </svg>
  );
};
