export const NodeSupportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="15" fill="none" viewBox="0 0 17 15" {...props}>
      <path
        fill="currentColor"
        d="M16.833 2.24c0-.51-.226-1-.63-1.36a2.284 2.284 0 0 0-1.519-.564H2.65c-.57 0-1.117.203-1.52.564-.404.36-.63.85-.63 1.36v7.115c0 .51.226.999.63 1.36.403.36.95.563 1.52.563h.644v2.654c0 .072.021.142.063.203.042.061.102.11.173.143l.194.038a.464.464 0 0 0 .258-.077l4.19-2.961h6.512c.57 0 1.117-.203 1.52-.563.403-.361.63-.85.63-1.36V2.239Z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth=".5"
        d="M7.581 8.316a.417.417 0 0 1-.294-.122L5.62 6.528a.417.417 0 0 1 .589-.59l1.356 1.357 3.456-3.84a.417.417 0 0 1 .62.557L7.89 8.178a.418.418 0 0 1-.299.138h-.01Z"
      />
    </svg>
  );
};
