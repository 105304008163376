export const ArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="10" fill="none" viewBox="0 0 14 10" {...props}>
      <path
        fill="currentColor"
        d="M14.001 5a.5.5 0 0 1-.5.5H1.708l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L1.708 4.5h11.793a.5.5 0 0 1 .5.5Z"
      />
    </svg>
  );
};
