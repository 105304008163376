export const UndeterminedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.192 8.048c0-2.3-1.514-4.252-3.609-4.909l.69-2.202C13.3 1.886 15.5 4.708 15.5 8.048c0 4.118-3.345 7.452-7.465 7.452a7.452 7.452 0 0 1-5.225-2.13 7.451 7.451 0 0 1-1.867-2.988l2.191-.724a5.14 5.14 0 0 0 1.29 2.063l-.808.825.807-.825a5.144 5.144 0 0 0 3.612 1.471 5.151 5.151 0 0 0 5.157-5.144Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M8.192.5a.536.536 0 0 0-.537.537v1.43a.536.536 0 1 0 1.074 0v-1.43A.536.536 0 0 0 8.192.5Zm-2.246.877a.538.538 0 0 0-.7-.293.537.537 0 0 0-.29.7l.547 1.325a.537.537 0 0 0 .991-.411l-.548-1.321ZM3.518 2.756a.54.54 0 0 0-.759 0 .533.533 0 0 0 0 .759l1.013 1.012a.537.537 0 0 0 .758-.759L3.518 2.757Zm-.823 3.741a.537.537 0 0 0 .411-.991l-1.324-.548a.538.538 0 0 0-.411.995l1.324.544Zm.309 1.698a.536.536 0 0 0-.536-.537H1.037a.536.536 0 1 0 0 1.074h1.43c.298 0 .537-.24.537-.537Z"
      />
    </svg>
  );
};
