export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="95" height="86" fill="none" viewBox="0 0 95 86" {...props}>
      <path
        fill="currentColor"
        d="M9.974 86h75.052c7.675 0 12.459-8.445 8.622-15.17L56.122 5.044c-3.838-6.725-13.406-6.725-17.243 0L1.352 70.83C-2.485 77.555 2.3 86 9.974 86ZM47.5 50.604c-2.74 0-4.983-2.276-4.983-5.057V35.434c0-2.781 2.242-5.056 4.983-5.056 2.741 0 4.983 2.275 4.983 5.056v10.113c0 2.781-2.242 5.057-4.983 5.057Zm4.983 20.226h-9.966V60.717h9.966V70.83Z"
      />
    </svg>
  );
};
