import { useId } from "react";

export const AddBubbleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();

  return (
    <svg width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill={`url(#${id1})`}
        fillRule="evenodd"
        d="m3.32 17.173.654-2.92-.22-.41a7.083 7.083 0 0 1 8.644-10.01 3.98 3.98 0 0 1 .968-.959 8.333 8.333 0 0 0-10.714 11.56l-.84 3.755c-.068.299.2.565.499.497l3.728-.853a8.333 8.333 0 0 0 12.18-8.718 3.957 3.957 0 0 1-1.168.704 7.083 7.083 0 0 1-10.418 6.915l-.414-.224-2.9.663Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id2})`}
        fillRule="evenodd"
        d="M15.625 3c.345 0 .625.28.625.625v5a.625.625 0 1 1-1.25 0v-5c0-.345.28-.625.625-.625Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id3})`}
        fillRule="evenodd"
        d="M18.75 6.125c0 .345-.28.625-.625.625h-5a.625.625 0 1 1 0-1.25h5c.345 0 .625.28.625.625Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id={id1} x1="10" x2="10" y1="2.167" y2="18.833" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id2} x1="16.25" x2="15" y1="6.125" y2="6.125" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id3} x1="15.625" x2="15.625" y1="6.75" y2="5.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
