export const AnswersOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.286 16.143v2.587l4.519-2.587H20V4H4v12.143h4.286ZM7.07 22a.5.5 0 0 1-.784-.411v-3.446H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12.143a2 2 0 0 1-2 2h-6.572L7.07 22Z"
        clipRule="evenodd"
      />
      <circle cx="12.233" cy="10.3" r="1.3" fill="currentColor" />
      <circle cx="7.9" cy="10.3" r="1.3" fill="currentColor" />
      <circle cx="16.567" cy="10.3" r="1.3" fill="currentColor" />
    </svg>
  );
};
