export const ReplyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M11.7 15h2a8.999 8.999 0 0 1 7.558 4.11c.128.198.442.125.442-.11 0-5.523-4.477-10-10-10V5.04a.5.5 0 0 0-.813-.39l-8.7 6.96a.5.5 0 0 0 0 .78l8.7 6.96a.5.5 0 0 0 .813-.39V15Z"
      />
    </svg>
  );
};
