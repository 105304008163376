export const JoinedSpaceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="87" fill="none" viewBox="0 0 100 87" {...props}>
      <path
        fill="currentColor"
        d="m48.375 86-4.623-4.623c-6.342-6.343-6.288-16.662.108-22.897l4.515-4.408c-2.097-.215-3.655-.322-5.375-.322-14.352 0-43 7.202-43 21.5V86h48.375ZM43 43c11.878 0 21.5-9.621 21.5-21.5S54.877 0 43 0C31.12 0 21.5 9.621 21.5 21.5S31.12 43 43 43Zm27.841 41.818c-2.096 2.096-5.536 2.096-7.632 0L52.082 73.584c-2.042-2.096-2.042-5.428 0-7.47l.054-.055c2.096-2.096 5.483-2.096 7.525 0l7.364 7.364 23.81-23.972a5.353 5.353 0 0 1 7.58 0l.053.053c2.043 2.097 2.043 5.43 0 7.472L70.841 84.818Z"
      />
    </svg>
  );
};
