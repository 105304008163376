export const LinkedinIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#0A66C2"
        d="M18.337 18.337H15.67V14.16c0-.996-.018-2.278-1.387-2.278-1.39 0-1.602 1.085-1.602 2.206v4.249h-2.667v-8.59h2.56v1.175h.036a2.806 2.806 0 0 1 2.526-1.388c2.704 0 3.202 1.778 3.202 4.091l-.001 4.712ZM7.005 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096Zm1.333 9.763h-2.67v-8.59h2.67v8.59ZM19.667 3.001H4.327A1.314 1.314 0 0 0 3 4.3v15.4A1.315 1.315 0 0 0 4.328 21h15.338A1.317 1.317 0 0 0 21 19.7V4.298A1.316 1.316 0 0 0 19.666 3"
      />
    </svg>
  );
};
