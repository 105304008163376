export const ArrowUpOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="16" fill="none" viewBox="0 0 12 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 13A5 5 0 1 0 6 3a5 5 0 0 0 0 10Zm0 1A6 6 0 1 0 6 2a6 6 0 0 0 0 12Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M3.646 7.146a.5.5 0 1 0 .708.708l-.708-.708Zm4 .708a.5.5 0 1 0 .708-.708l-.708.708Zm-1.292-2L6 6.207l.354-.353Zm-2 2L6 6.207 5.293 5.5 3.646 7.146l.708.708ZM6 6.207l1.646 1.647.708-.708L6.707 5.5 6 6.207Zm0 0 .707-.707a1 1 0 0 0-1.414 0L6 6.207Z"
      />
      <path fill="currentColor" d="M6.5 6.5a.5.5 0 0 0-1 0h1Zm-1 4a.5.5 0 0 0 1 0h-1Zm0-4v4h1v-4h-1Z" />
    </svg>
  );
};
