export const ReactionWorthNotingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M19.56 5.752H7.985A2.018 2.018 0 0 0 5.968 7.77v14.77c0 1.115.903 2.018 2.018 2.018H19.56a2.018 2.018 0 0 0 2.019-2.018V7.77a2.018 2.018 0 0 0-2.019-2.018Z"
        fill="#6D28FF"
      />
      <path
        d="M18.672 5.588H7.707a2.018 2.018 0 0 0-2.019 2.019v14.035c0 1.114.904 2.018 2.019 2.018h10.966a2.018 2.018 0 0 0 2.019-2.018V7.607a2.018 2.018 0 0 0-2.018-2.019Z"
        fill="#FFD600"
      />
      <path
        d="M17.564 10.92H8.966a.77.77 0 1 0 0 1.54h8.598a.77.77 0 0 0 0-1.54Zm0 2.567H8.966a.77.77 0 1 0 0 1.54h8.598a.77.77 0 0 0 0-1.54Zm0 2.566H8.966a.77.77 0 0 0 0 1.54h8.598a.77.77 0 1 0 0-1.54ZM9.584 4.398a.898.898 0 0 0-1.797 0v2.404a.898.898 0 0 0 1.797 0V4.398Zm4.83 0a.898.898 0 1 0-1.797 0v2.404a.898.898 0 0 0 1.797 0V4.398Zm4.176 0a.898.898 0 0 0-1.796 0v2.404a.898.898 0 1 0 1.797 0V4.398Z"
        fill="#6D28FF"
      />
    </svg>
  );
};
