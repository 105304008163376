export const SemiStarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
      <path
        fill="currentColor"
        d="M6.721 12.069c.107.24.448.242.556.002l1.408-3.098a.61.61 0 0 1 .304-.304l3.082-1.391a.305.305 0 0 0 0-.556L8.989 5.331a.61.61 0 0 1-.304-.304L7.277 1.93a.305.305 0 0 0-.556.002L5.348 5.023a.61.61 0 0 1-.308.309l-3.11 1.39a.305.305 0 0 0 0 .556l3.11 1.39a.61.61 0 0 1 .307.31l1.374 3.091Z"
      />
    </svg>
  );
};
