export const WorldIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M8 15.5c-1.028 0-2-.198-2.917-.593a7.588 7.588 0 0 1-2.385-1.605 7.59 7.59 0 0 1-1.605-2.385A7.283 7.283 0 0 1 .5 8c0-1.042.198-2.018.593-2.928a7.632 7.632 0 0 1 1.605-2.375 7.595 7.595 0 0 1 2.385-1.604A7.283 7.283 0 0 1 8 .5c1.042 0 2.018.198 2.928.593a7.64 7.64 0 0 1 2.374 1.604 7.64 7.64 0 0 1 1.605 2.375c.395.91.593 1.886.593 2.928 0 1.028-.198 2-.593 2.917a7.597 7.597 0 0 1-1.604 2.385 7.632 7.632 0 0 1-2.376 1.605A7.264 7.264 0 0 1 8 15.5Zm0-.833c.486-.625.889-1.24 1.208-1.844.32-.604.577-1.281.771-2.031H6.021c.222.791.486 1.49.792 2.093A9.463 9.463 0 0 0 8 14.667Zm-1.063-.125c-.388-.459-.743-1.025-1.062-1.699a9.58 9.58 0 0 1-.708-2.051H1.958A6.792 6.792 0 0 0 4 13.333a6.034 6.034 0 0 0 2.938 1.209Zm2.125 0A6.034 6.034 0 0 0 12 13.333a6.792 6.792 0 0 0 2.042-2.541h-3.209a14.134 14.134 0 0 1-.791 2.072c-.32.674-.646 1.233-.98 1.678ZM1.625 9.958h3.354c-.055-.347-.097-.68-.125-1a11.056 11.056 0 0 1 0-1.916c.028-.32.07-.653.125-1H1.625c-.097.291-.17.607-.218.947a7.106 7.106 0 0 0 0 2.021c.048.34.12.657.218.948Zm4.188 0h4.375c.055-.347.097-.677.124-.99a10.949 10.949 0 0 0 0-1.938 14.28 14.28 0 0 0-.124-.988H5.811c-.055.347-.097.677-.125.989a10.956 10.956 0 0 0 0 1.937c.028.313.07.643.125.99Zm5.208 0h3.354c.097-.291.17-.607.22-.948a7.174 7.174 0 0 0 0-2.02 5.447 5.447 0 0 0-.22-.948h-3.354c.055.347.097.68.125 1a11.048 11.048 0 0 1 0 1.916c-.028.32-.07.653-.125 1Zm-.188-4.75h3.209c-.486-1.055-1.157-1.902-2.011-2.541-.854-.64-1.844-1.049-2.969-1.23.39.514.74 1.101 1.053 1.761.312.66.552 1.33.718 2.01Zm-4.812 0h3.958a11.985 11.985 0 0 0-.812-2.115A9.013 9.013 0 0 0 8 1.333a9.013 9.013 0 0 0-1.167 1.76 11.97 11.97 0 0 0-.812 2.115Zm-4.063 0h3.209c.166-.68.406-1.35.719-2.01a9.98 9.98 0 0 1 1.051-1.76c-1.138.18-2.131.593-2.979 1.239-.847.646-1.514 1.49-2 2.531Z"
      />
    </svg>
  );
};
