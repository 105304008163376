export const UnaddressedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 3.5H3.333A.333.333 0 0 0 3 3.833v9.334c0 .184.15.333.333.333h9.334c.184 0 .333-.15.333-.333V3.833a.333.333 0 0 0-.333-.333Zm-9.334-1C2.597 2.5 2 3.097 2 3.833v9.334c0 .736.597 1.333 1.333 1.333h9.334c.736 0 1.333-.597 1.333-1.333V3.833c0-.736-.597-1.333-1.333-1.333H3.333ZM8 5.167a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5Zm0 6.666A.667.667 0 1 0 8 10.5a.667.667 0 0 0 0 1.333Z"
        fill="currentColor"
      />
    </svg>
  );
};
