export const FlagOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M4 21v-6m0 .071s1-.928 4-.928S13 16 16 16s4-.929 4-.929V3.93s-1 .928-4 .928S11 3 8 3s-4 .929-4 .929V15.07z"
      />
    </svg>
  );
};
