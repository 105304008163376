export const CircleDashedAddIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.219 2.54c-.264-.264-.243-.7.064-.91A8.288 8.288 0 0 1 7.477.304c.367-.067.69.226.69.6 0 .32-.24.585-.554.648a7.044 7.044 0 0 0-2.545 1.054.673.673 0 0 1-.85-.067Zm9.545-.005c.268-.263.249-.703-.061-.916a8.287 8.287 0 0 0-3.18-1.314c-.367-.067-.69.226-.69.6 0 .32.24.585.554.648a7.025 7.025 0 0 1 2.532 1.046.673.673 0 0 0 .845-.064Zm3.43 7.488c.068-.367-.225-.69-.598-.69-.32 0-.586.24-.649.554a7.045 7.045 0 0 1-1.054 2.544.673.673 0 0 0 .067.85c.264.264.7.243.91-.064a8.285 8.285 0 0 0 1.325-3.194Zm-3.413 4.437a.673.673 0 0 0-.85-.067 7.042 7.042 0 0 1-2.544 1.054.673.673 0 0 0-.554.649c0 .373.323.666.69.599a8.29 8.29 0 0 0 3.194-1.324c.307-.212.328-.647.064-.911Zm1.174-10.758c.264-.26.695-.238.906.067a8.287 8.287 0 0 1 1.334 3.208c.067.367-.226.69-.6.69a.673.673 0 0 1-.647-.554 7.045 7.045 0 0 0-1.063-2.557.673.673 0 0 1 .07-.854ZM1.405 7.667c.319 0 .585-.24.647-.554a7.044 7.044 0 0 1 1.055-2.544.673.673 0 0 0-.067-.85c-.264-.264-.7-.243-.91.064A8.287 8.287 0 0 0 .804 6.977c-.067.367.226.69.6.69Zm.647 2.22a.673.673 0 0 0-.648-.554c-.373 0-.666.323-.599.69a8.285 8.285 0 0 0 1.235 3.061c.207.314.646.34.914.077a.674.674 0 0 0 .078-.843 7.04 7.04 0 0 1-.98-2.43Zm2.049 4.484a.673.673 0 0 1 .855-.055 7.045 7.045 0 0 0 2.657 1.131.673.673 0 0 1 .554.649c0 .373-.323.666-.69.599a8.29 8.29 0 0 1-3.325-1.416c-.3-.216-.316-.648-.051-.908Zm5.316-9.413a.625.625 0 0 0-1.25 0v2.709H5.458a.625.625 0 0 0 0 1.25h2.709v3.125a.625.625 0 0 0 1.25 0V8.917h3.125a.625.625 0 0 0 0-1.25H9.417V4.958Z"
        fill="currentColor"
      />
    </svg>
  );
};
