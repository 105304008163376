export const ArchiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.5 4.5v4h17v-4h-17ZM3 3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.25 19.25V9h1.5v10.25c0 .138.112.25.25.25h14a.25.25 0 0 0 .25-.25V9h1.5v10.25A1.75 1.75 0 0 1 19 21H5a1.75 1.75 0 0 1-1.75-1.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 17.75a.75.75 0 0 1-.75-.75v-5a.75.75 0 0 1 1.5 0v5a.75.75 0 0 1-.75.75Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.53 17.53a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06L12 15.94l2.47-2.47a.75.75 0 1 1 1.06 1.06l-3 3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
