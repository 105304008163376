export const BadgeFacilitatorTwoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#C1B1FF"
        d="M12 5.676A2.278 2.278 0 0 1 9.724 3.4 2.279 2.279 0 0 1 12 1.126a2.279 2.279 0 0 1 2.275 2.276A2.279 2.279 0 0 1 12 5.675Zm0-3.969a1.695 1.695 0 1 0 1.694 1.694c0-.935-.76-1.694-1.694-1.694Z"
      />
      <path
        fill="#ADABFF"
        d="M19.523 13.263a7.523 7.523 0 1 1-8.85-7.405V4.056h2.655v1.801a7.525 7.525 0 0 1 6.195 7.406Z"
      />
      <path fill="#fff" d="M12 19.407a6.144 6.144 0 1 0 0-12.288 6.144 6.144 0 0 0 0 12.288Z" />
      <path
        fill="#C1B1FF"
        d="m13.407 13.847 2.001 2.824-2.824-2.001-.582 3.412-.706-4.116-4.116-.703 3.413-.583-2.001-2.825 2.826 2.001.584-3.412.701 4.115 4.117.704-3.413.584Z"
      />
      <path fill="#2A0FCE" d="m15.41 9.855-2.828 3.99-3.99 2.826 2.826-3.99 3.992-2.826Z" />
    </svg>
  );
};
