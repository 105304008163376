export const BadgeBrillianceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#fff"
        d="M19.449 9.606a7.447 7.447 0 0 1-4.223 6.716v.733H8.773v-.733A7.45 7.45 0 1 1 19.45 9.607v-.001Z"
      />
      <path
        fill="#ADABFF"
        d="M15.501 17.055v2.997a.788.788 0 0 1-.789.789h-1.711a1 1 0 1 1-2.002 0H9.288a.788.788 0 0 1-.789-.789v-2.997h7.002Z"
      />
      <path
        fill="#C1B1FF"
        d="M14.335 7.926c-.853 0-1.546.692-1.546 1.545v.302h-1.578V9.47a1.546 1.546 0 1 0-1.546 1.545h.436v6.039h1.11V10.88h1.578v6.174h1.11v-6.039h.437a1.546 1.546 0 0 0 0-3.09Zm-4.67 1.982a.437.437 0 1 1 .436-.437v.437h-.436Zm4.67 0H13.9V9.47a.436.436 0 1 1 .437.436ZM4.663 5.022 5.69 6.9l1.878 1.026L5.69 8.953 4.663 10.83 3.636 8.953 1.758 7.926 3.636 6.9l1.027-1.878Z"
      />
    </svg>
  );
};
