export const MinusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="7.5" fill="#fff" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Zm7.5-6a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M3.5 8a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 3.5 8Z" />
    </svg>
  );
};
