export const TickSmall = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" fill="none" viewBox="0 0 16 17" {...props}>
      <g clipPath="url(#Tick_svg__a)">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.096 4.323a.667.667 0 0 1 .08.94l-5.085 6.039a1 1 0 0 1-1.512.02L3.17 8.61a.667.667 0 0 1 .996-.886l2.155 2.424 4.837-5.744a.667.667 0 0 1 .94-.08Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="Tick_svg__a">
          <path fill="#fff" d="M0 .5h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
