export const BadgeExpertIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" fill="none" viewBox="0 0 24 24" {...props}>
      <path fill="#2A0FCE" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" />
      <path
        fill="#ADABFF"
        d="M11.514 5.573 1.82 10.662a.74.74 0 0 0-.024 1.297l9.694 5.563c.225.129.502.13.729.004l9.978-5.562a.74.74 0 0 0-.024-1.306l-9.978-5.09a.738.738 0 0 0-.681.005Z"
      />
      <path fill="#fff" d="M6.316 10.929H17.67v5.784a.837.837 0 0 1-.837.837h-9.68a.837.837 0 0 1-.837-.837v-5.784Z" />
      <path
        fill="#ADABFF"
        d="M12.02 18.513c3.12 0 5.65-.718 5.65-1.604 0-.885-2.53-1.603-5.65-1.603-3.121 0-5.651.718-5.651 1.604 0 .885 2.53 1.603 5.65 1.603Z"
      />
      <path
        fill="#fff"
        d="m20.352 15.017.49 3.496h-1.675l.495-3.527a.836.836 0 0 1 .08-1.528V9.681a.263.263 0 0 1 .527 0v3.753a.838.838 0 0 1 .083 1.583Z"
      />
      <path
        fill="#2A0FCE"
        d="m12.02 6.72.588 1.075 1.073.588-1.073.589-.588 1.074-.588-1.074-1.075-.588 1.075-.589.588-1.075Z"
      />
    </svg>
  );
};
