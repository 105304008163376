export const SeekFeedbackIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 10.833a.5.5 0 0 1 .5.5V14a.5.5 0 1 1-1 0v-2.667a.5.5 0 0 1 .5-.5Zm2.313-.52a.5.5 0 0 1 .707 0l1.333 1.333a.5.5 0 1 1-.707.708l-1.333-1.334a.5.5 0 0 1 0-.707Zm-4.626 0a.5.5 0 0 1 0 .707l-1.333 1.334a.5.5 0 1 1-.708-.707l1.334-1.334a.5.5 0 0 1 .707 0ZM10.834 8a.5.5 0 0 1 .5-.5H14a.5.5 0 0 1 0 1h-2.666a.5.5 0 0 1-.5-.5ZM1.5 8a.5.5 0 0 1 .5-.5h2.667a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5Zm10.853-4.354a.5.5 0 0 1 0 .708L11.02 5.687a.5.5 0 1 1-.707-.707l1.333-1.334a.5.5 0 0 1 .707 0Zm-8.707 0a.5.5 0 0 1 .708 0L5.687 4.98a.5.5 0 0 1-.707.707L3.646 4.354a.5.5 0 0 1 0-.708ZM8 1.5a.5.5 0 0 1 .5.5v2.667a.5.5 0 0 1-1 0V2a.5.5 0 0 1 .5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
