export const PublicIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="8.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        fill="currentColor"
        d="M14 6V4.5L10.5 4 7 4.823 4 10.5 8 15v.5a2 2 0 0 0 2 2v2l3 1 3-1.5v-1.5a2 2 0 0 0-2-2V14a2 2 0 0 0-2-2H7v-2h2a1 1 0 0 0 1-1V8h2a2 2 0 0 0 2-2Z"
      />
    </svg>
  );
};
