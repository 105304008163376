import { useId } from "react";

export const SeekContributorsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();
  const id4 = useId();
  const id5 = useId();

  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill={`url(#${id1})`}
        fillRule="evenodd"
        d="M3 9.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id2})`}
        fillRule="evenodd"
        d="M13 4.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clipRule="evenodd"
      />
      <path
        fill={`url(#${id3})`}
        d="m4.886 6.358 5.669-2.834c.09.422.286.804.56 1.118l-5.67 2.834a2.492 2.492 0 0 0-.56-1.118Z"
      />
      <path
        fill={`url(#${id4})`}
        d="M5.445 8.524c-.09.422-.286.804-.56 1.118l5.67 2.834c.09-.422.286-.804.56-1.118l-5.67-2.834Z"
      />
      <path
        fill={`url(#${id5})`}
        fillRule="evenodd"
        d="M13 14.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id={id1} x1=".804" x2="16.235" y1="8" y2="12.383" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id2} x1=".804" x2="16.235" y1="8" y2="12.383" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id3} x1=".804" x2="16.235" y1="8" y2="12.383" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id4} x1=".804" x2="16.235" y1="8" y2="12.383" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
        <linearGradient id={id5} x1=".804" x2="16.235" y1="8" y2="12.383" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BFFF" />
          <stop offset="1" stopColor="#DB00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
