export const BroadcastIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="13" fill="none" viewBox="0 0 12 13" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.682 10.75h1.592L5.568 8H3.976l-1.294 2.75Zm.818-3.5-1.664 3.537a.5.5 0 0 0 .452.713h2.145a.5.5 0 0 0 .452-.287l1.53-3.25a.5.5 0 0 0-.453-.713H3.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.012 4.489c-.3.128-.673.261-1.012.261H3a.75.75 0 0 0-.75.75v1c0 .414.336.75.75.75h3c.339 0 .711.133 1.012.261.33.14.695.33 1.057.534.577.325 1.18.706 1.681 1.036V2.92a29.76 29.76 0 0 1-1.68 1.036 11.07 11.07 0 0 1-1.058.534ZM6 4c.697 0 2.606-1.213 3.701-1.95a.513.513 0 0 1 .799.422v7.056a.513.513 0 0 1-.799.421C8.606 9.213 6.697 8 6 8H3a1.5 1.5 0 0 1-1.5-1.5v-1A1.5 1.5 0 0 1 3 4h3Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M9.75 1.875a.375.375 0 0 1 .75 0v8.25a.375.375 0 0 1-.75 0v-8.25Z" />
    </svg>
  );
};
