export const EditDraftMenuIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 4H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-8h-1.5v8a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5h8V4Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.63 4.75a1.62 1.62 0 0 0-1.146.475L9.928 11.78l-.86 3.15 3.151-.86 6.557-6.555A1.62 1.62 0 0 0 17.63 4.75Zm-1.194-1.263a3.12 3.12 0 0 1 3.4 5.09l-6.697 6.696a.75.75 0 0 1-.333.194l-4.609 1.257a.75.75 0 0 1-.92-.921l1.256-4.61a.75.75 0 0 1 .194-.332l6.697-6.697a3.1 3.1 0 0 1 1.012-.676Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
