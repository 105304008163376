export const UserTickIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.61 12.105c.485-.646 1.188-1.063 1.973-1.063H8.75c.786 0 1.488.417 1.973 1.063.482.643.735 1.486.735 2.34v1.388a.625.625 0 1 1-1.25 0v-1.389c0-.62-.186-1.19-.485-1.589-.296-.395-.654-.563-.973-.563H4.583c-.319 0-.676.168-.973.563-.299.4-.485.97-.485 1.59v1.388a.625.625 0 1 1-1.25 0v-1.389c0-.853.253-1.696.735-2.339Zm4.057-7.313a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75ZM3.542 6.667a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0Zm14.365.358c.262.225.292.62.067.882l-3.255 3.797a1.042 1.042 0 0 1-1.581 0l-1.113-1.297a.625.625 0 1 1 .95-.814l.954 1.113 3.097-3.613a.625.625 0 0 1 .88-.068Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
