export const QuestionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0 1a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-8.167c-.644 0-1.167.523-1.167 1.167a.5.5 0 1 1-1 0A2.167 2.167 0 1 1 6.5 7.609v.28a.5.5 0 0 1-1 0v-.722a.5.5 0 0 1 .5-.5 1.167 1.167 0 0 0 0-2.334Zm0 4.334a.5.5 0 1 0 0 1h.007a.5.5 0 0 0 0-1H6Z"
        fill="currentColor"
      />
    </svg>
  );
};
