export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.432 3.75a1.818 1.818 0 0 0-1.285.532L5.033 16.397l-.964 3.534 3.534-.964L19.718 6.853a1.82 1.82 0 0 0 0-2.57 1.818 1.818 0 0 0-1.286-.533Zm-1.27-1.247a3.317 3.317 0 0 1 3.616 5.41L8.523 20.17a.75.75 0 0 1-.333.193l-4.993 1.362a.75.75 0 0 1-.92-.921l1.361-4.993a.75.75 0 0 1 .193-.333L16.087 3.222a3.318 3.318 0 0 1 1.076-.72Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
