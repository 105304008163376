export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" fill="none" viewBox="0 0 12 12" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12Zm.669-9.58a.608.608 0 0 0-.492-.222.692.692 0 0 0-.5.222.813.813 0 0 0-.241.547.77.77 0 0 0 .176.538c.13.142.3.214.51.214a.677.677 0 0 0 .51-.214.805.805 0 0 0 .213-.538.734.734 0 0 0-.176-.547ZM6.52 4.831c-.124-.198-.347-.296-.668-.296-.21 0-.399.049-.566.148-.16.099-.303.226-.426.38a2.199 2.199 0 0 0-.288.473 2.078 2.078 0 0 0-.148.445l.5.148c.081-.278.158-.47.232-.574.075-.106.146-.158.214-.158.037 0 .07.015.102.046.03.025.046.09.046.195 0 .148-.015.337-.046.566-.031.222-.068.463-.112.723-.037.26-.07.513-.102.76-.03.248-.046.46-.046.64 0 .328.062.584.186.77.13.185.364.278.704.278.21 0 .396-.05.557-.148a1.54 1.54 0 0 0 .426-.371c.118-.155.21-.316.278-.483.075-.166.124-.315.149-.445l-.501-.148c-.08.278-.158.47-.232.575-.074.105-.148.158-.222.158-.031 0-.062-.016-.093-.047-.031-.03-.046-.096-.046-.195a4.2 4.2 0 0 1 .046-.5l.102-.76c.037-.279.071-.545.102-.798.03-.254.046-.454.046-.603 0-.327-.065-.587-.194-.779Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
