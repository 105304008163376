export const RootNodePreviewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M2.91663 1.16663H4.95829V2.04163H2.91663C2.43338 2.04163 2.04163 2.43338 2.04163 2.91663V4.95829H1.16663V2.91663C1.16663 1.95013 1.95013 1.16663 2.91663 1.16663Z"
        fill="currentColor"
      />
      <path
        d="M1.16663 11.0833V9.04163H2.04163V11.0833C2.04163 11.5665 2.43338 11.9583 2.91663 11.9583H4.95829V12.8333H2.91663C1.95013 12.8333 1.16663 12.0498 1.16663 11.0833Z"
        fill="currentColor"
      />
      <path
        d="M9.04163 2.04163V1.16663H11.0833C12.0498 1.16663 12.8333 1.95013 12.8333 2.91663V4.95829H11.9583V2.91663C11.9583 2.43338 11.5665 2.04163 11.0833 2.04163H9.04163Z"
        fill="currentColor"
      />
      <path
        d="M11.9583 9.04163H12.8333V11.0833C12.8333 12.0498 12.0498 12.8333 11.0833 12.8333H9.04163V11.9583H11.0833C11.5665 11.9583 11.9583 11.5665 11.9583 11.0833V9.04163Z"
        fill="currentColor"
      />
      <circle cx="7.00004" cy="7.00004" r="2.91667" fill="currentColor" />
    </svg>
  );
};
