export const BackIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 13 21" {...props}>
      <path
        fill="currentColor"
        d="M11.97.936a1.783 1.783 0 0 0-2.524 0L1.23 9.151a1.782 1.782 0 0 0 0 2.523l8.215 8.215a1.782 1.782 0 0 0 2.523 0 1.782 1.782 0 0 0 0-2.523l-6.944-6.962L11.97 3.46c.68-.698.68-1.844 0-2.524Z"
      />
    </svg>
  );
};
