export const FilePPTIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="46" height="46" fill="none" viewBox="0 0 46 46" {...props}>
      <rect width="45" height="45" x=".5" y=".5" fill="#C5411F" rx="5" />
      <path
        fill="#fff"
        d="M24.16 25.402h-4.348V22.66h4.347c.672 0 1.22-.11 1.641-.329a2.1 2.1 0 0 0 .926-.937 3.02 3.02 0 0 0 .293-1.348c0-.507-.098-.98-.293-1.418a2.367 2.367 0 0 0-.926-1.054c-.422-.266-.969-.399-1.64-.399h-3.13v14.309h-3.515V14.42h6.644c1.336 0 2.48.243 3.434.727.961.476 1.695 1.137 2.203 1.98.508.844.762 1.809.762 2.895 0 1.102-.254 2.055-.762 2.86-.508.804-1.242 1.425-2.203 1.863-.953.437-2.098.656-3.434.656Z"
      />
    </svg>
  );
};
