export const CrossOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.293 16.697a1 1 0 0 0 1.414 0l3.288-3.288 3.288 3.289a1 1 0 0 0 1.414-1.415l-3.288-3.288 3.288-3.288a1 1 0 0 0-1.414-1.414l-3.288 3.288-3.288-3.288a1 1 0 0 0-1.414 1.414l3.288 3.288-3.288 3.288a1 1 0 0 0 0 1.414Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
