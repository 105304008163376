export const MyActivityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="17" fill="none" viewBox="0 0 22 17" {...props}>
      <path
        fill="currentColor"
        d="M20 5c-1.45 0-2.26 1.44-1.93 2.51l-3.55 3.56c-.3-.09-.74-.09-1.04 0l-2.55-2.55C11.27 7.45 10.46 6 9 6 7.55 6 6.73 7.44 7.07 8.52l-4.56 4.55C1.44 12.74 0 13.55 0 15c0 1.1.9 2 2 2 1.45 0 2.26-1.44 1.93-2.51l4.55-4.56c.3.09.74.09 1.04 0l2.55 2.55C11.73 13.55 12.54 15 14 15c1.45 0 2.27-1.44 1.93-2.52l3.56-3.55C20.56 9.26 22 8.45 22 7c0-1.1-.9-2-2-2Z"
      />
      <path
        fill="currentColor"
        d="m14 6 .94-2.07L17 3l-2.06-.93L14 0l-.92 2.07L11 3l2.08.93L14 6ZM2.5 8 3 6l2-.5L3 5l-.5-2L2 5l-2 .5L2 6l.5 2Z"
      />
    </svg>
  );
};
