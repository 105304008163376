export const RejectedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13.5a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm-6-5a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm7.646 2.354a.5.5 0 0 0 .708-.708L8.707 8.5l1.647-1.646a.5.5 0 0 0-.708-.708L8 7.793 6.354 6.146a.5.5 0 1 0-.708.708L7.293 8.5l-1.647 1.646a.5.5 0 0 0 .708.708L8 9.207l1.646 1.647Z"
        fill="currentColor"
      />
    </svg>
  );
};
