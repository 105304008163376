export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="7.5" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.872 5.188a.75.75 0 0 1 .065 1.059L7.25 11.55a.75.75 0 0 1-1.118.008L4.07 9.285a.75.75 0 1 1 1.11-1.008l1.5 1.652 4.133-4.676a.75.75 0 0 1 1.059-.065Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
