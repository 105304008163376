export const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="8" height="14" fill="none" viewBox="0 0 8 14" {...props}>
      <path
        fill="currentColor"
        d="M.355.366C.582.132.89 0 1.212 0c.321 0 .63.132.856.366l5.577 5.75a1.275 1.275 0 0 1 0 1.767l-5.577 5.75a1.184 1.184 0 0 1-1.713 0 1.275 1.275 0 0 1 0-1.766L5.07 6.994.355 2.133c-.461-.489-.461-1.29 0-1.767Z"
      />
    </svg>
  );
};
