export const ManagedByIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.667 3.94 8 1.833l5.333 2.107v6.239a7.017 7.017 0 0 1-4.865 4.857l-.468.13-.468-.13a7.008 7.008 0 0 1-4.865-4.857v-6.24ZM9.25 6.344l-.916-1.643a.378.378 0 0 0-.668 0L6.75 6.344l-1.778.403c-.29.065-.404.433-.207.664l1.213 1.418-.184 1.892c-.03.309.27.536.541.41L8 10.366l1.665.767c.271.125.57-.102.54-.41l-.183-1.893 1.213-1.418c.197-.23.083-.599-.207-.664L9.25 6.344Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
