export const PinIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M6 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v3a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M7.53 12.53a.75.75 0 0 0 .22-.53V7.25h8.5V12c0 .199.079.39.22.53l1.78 1.78V16a.25.25 0 0 1-.25.25H6a.25.25 0 0 1-.25-.25v-1.69l1.78-1.78z"
      />
      <path stroke="currentColor" d="M11.75 17.5h.5v3.333l-.25.334-.25-.334V17.5z" />
    </svg>
  );
};
