import React from "react";

import clsx from "clsx";

export const LoadingIndicatorIcon = ({ className, ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      {...rest}
      className={clsx("animate-spin", className)}
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M16.25 6.042a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0ZM12.5 3.958a1.458 1.458 0 1 1-2.917 0 1.458 1.458 0 0 1 2.917 0ZM8.333 4.688a1.354 1.354 0 1 1-2.708 0 1.354 1.354 0 0 1 2.708 0ZM5.417 7.917a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM5.417 11.98a1.146 1.146 0 1 1-2.292 0 1.146 1.146 0 0 1 2.292 0ZM7.917 15.208a1.042 1.042 0 1 1-2.084 0 1.042 1.042 0 0 1 2.084 0ZM11.875 15.938a.937.937 0 1 1-1.875 0 .937.937 0 0 1 1.875 0ZM15 14.167a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0Z"
      />
    </svg>
  );
};
