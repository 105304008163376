export const VisibilityFilledIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23 12s-4-8-11-8-11 8-11 8 4 8 11 8 11-8 11-8Zm-20.006-.436c-.102.159-.193.305-.272.436a18.174 18.174 0 0 0 2.248 2.974C6.717 16.84 9.1 18.5 12 18.5c2.9 0 5.282-1.66 7.03-3.526A18.18 18.18 0 0 0 21.279 12a18.18 18.18 0 0 0-2.248-2.974C17.283 7.16 14.9 5.5 12 5.5c-2.9 0-5.282 1.66-7.03 3.526a18.174 18.174 0 0 0-1.976 2.538Zm-.653-.236v-.001Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM8.25 12a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
