export const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10Zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12Zm0-9.333a.5.5 0 0 1 .5.5v3.666a.5.5 0 1 1-1 0V5.167a.5.5 0 0 1 .5-.5Zm0 5.5a.5.5 0 1 0 0 1h.007a.5.5 0 0 0 0-1H8Z"
        fill="currentColor"
      />
    </svg>
  );
};
