export const CarouselRightArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="6" height="8" fill="none" viewBox="0 0 6 8" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.271.26a.864.864 0 0 0 0 1.257L2.86 4 .271 6.483a.864.864 0 0 0 0 1.257.955.955 0 0 0 1.31 0l3.08-2.954c.785-.786.786-.786 0-1.572L1.58.26a.955.955 0 0 0-1.31 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
