import React from "react";

export const CarouselArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" fill="#fff" viewBox="0 0 48 48" {...props}>
      <circle cx="24" cy="24" r="22.5" stroke="currentColor" strokeWidth="3" />
      <path
        fill="currentColor"
        d="M27.25 12.82a1.667 1.667 0 0 1 2.357 2.357l-8.25 8.25 8.25 8.25a1.667 1.667 0 1 1-2.357 2.356l-9.428-9.428a1.667 1.667 0 0 1 0-2.357l9.428-9.428Z"
      />
    </svg>
  );
};
