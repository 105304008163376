export const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="21" fill="currentColor" viewBox="0 0 18 21" {...props}>
      <path d="M2 3a1 1 0 0 1 1-1h8.91a1 1 0 1 0 0-2H3a3 3 0 0 0-3 3v10.727a1 1 0 1 0 2 0V3Z" />
      <path
        fillRule="evenodd"
        d="M3.727 5.727a2 2 0 0 1 2-2h9.637a2 2 0 0 1 2 2v12.364a2 2 0 0 1-2 2H5.727a2 2 0 0 1-2-2V5.727Zm2 0h9.637v12.364H5.727V5.727Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
