export const UserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2.286 0a1.714 1.714 0 1 1-3.428 0 1.714 1.714 0 0 1 3.428 0ZM7.29 14.082c1.566-.627 3.425-.94 4.71-.94 1.285 0 3.144.313 4.71.94.784.313 1.567.734 2.172 1.292.613.566 1.118 1.35 1.118 2.34 0 .632-.512 2.286-1.143 2.286H5.143C4.512 20 4 18.346 4 17.714c0-.99.505-1.773 1.118-2.34.605-.558 1.388-.979 2.172-1.292Zm.743 1.857c1.325-.53 2.93-.796 3.967-.796 1.036 0 2.642.266 3.967.796.662.264 1.2.574 1.559.905.344.318.466.598.474.846l-.003.018a1.967 1.967 0 0 1-.065.292H6.068a2.963 2.963 0 0 1-.065-.291L6 17.69c.008-.248.13-.528.474-.846.359-.331.897-.64 1.559-.905Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
