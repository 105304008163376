import clsx, { type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

import { fontSize } from "@/src/components/sembly-ui/core/token";

// Need to manually define the class group for font-size if not it will be conflicted with text-<color> class group
// https://github.com/dcastil/tailwind-merge/blob/v2.3.0/docs/limitations.md#dont-use-classes-that-look-like-tailwind-classes-but-apply-different-styles
const fontSizeClassGroup = Object.keys(fontSize).map(data => `text-${data}`);

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": fontSizeClassGroup,
      // Extend from src/index.css
      h: ["safe-h-screen", "safe-mobile-dialog-content-height", "safe-mobile-left-drawer-content-height"],
      "min-h": ["min-safe-h-screen"],
      "max-h": ["safe-mobile-bottom-drawer-max-height", "safe-max-height-screen-with-offset"],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
