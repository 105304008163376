export const FileImageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="9.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        fill="currentColor"
        d="M7.802 9.734V14H6.85V9.734h.952Zm1.228 0h.79l1.026 3.05 1.022-3.05h.791L11.165 14h-.642L9.03 9.734Zm-.458 0h.806l.15 3.162V14h-.956V9.734Zm3.739 0h.808V14h-.958v-1.104l.15-3.162Zm4.954 2.013v1.72a1.51 1.51 0 0 1-.323.258c-.142.091-.325.17-.547.237a2.886 2.886 0 0 1-.797.097 2.06 2.06 0 0 1-.762-.135 1.622 1.622 0 0 1-.589-.401 1.848 1.848 0 0 1-.38-.65 2.7 2.7 0 0 1-.133-.88v-.252c0-.33.044-.623.132-.879a1.83 1.83 0 0 1 .372-.647c.16-.178.35-.313.572-.404a1.89 1.89 0 0 1 .732-.138c.38 0 .692.061.935.184.242.122.425.29.55.504.127.215.205.462.235.741h-.92a1.026 1.026 0 0 0-.117-.369.57.57 0 0 0-.244-.237.864.864 0 0 0-.41-.085.785.785 0 0 0-.366.085.72.72 0 0 0-.272.25 1.27 1.27 0 0 0-.17.415c-.04.164-.059.356-.059.574v.258c0 .219.02.411.059.577.04.166.1.305.178.416a.76.76 0 0 0 .296.25.987.987 0 0 0 .422.084c.125 0 .23-.01.317-.029a.672.672 0 0 0 .334-.17v-.709h-.76v-.665h1.715Z"
      />
    </svg>
  );
};
